import moment from "moment";
import 'moment/locale/ro';

const neatDate = (date: Date): string => {
  return moment(date).format("DD MMMM YYYY HH:mm");
}

const neatDateWithoutTime = (date: Date): string => moment(date).format("DD MMMM YYYY");

const neatDateWithWeekday = (date: Date): string => moment(date).format("dddd, DD MMMM YYYY HH:mm");

const neatDateWithWeekdayWithoutTime = (date: Date): string => moment(date).format("DD MMMM YYYY");

const shortDate = (date: Date): string => moment(date).format("DD.MM.YYYY");

const shortDateWithTime = (date: Date): string => moment(date).format("DD.MM.YYYY HH:mm");

export { neatDate, neatDateWithWeekday, neatDateWithWeekdayWithoutTime, neatDateWithoutTime, shortDate, shortDateWithTime };

