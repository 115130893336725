import { User } from "api/models/user";
import { GetCategoriesQuery } from "api/requests/getCategoriesQuery";
import { useCategoriesService } from "api/services/categoriesService";
import { useCountriesService } from "api/services/countriesService";
import { useUserService } from "api/services/usersService";
import { useAuth } from "api/useAuth";
import ProtectedRoute from "components/routes/ProtectedRoute";
import Spinner from "components/spinner";
import UserAccount from "components/user-account/UserAccount";
import UserBiddedLotsLayout from "components/user-account/UserBiddedLotsLayout";
import UserInvoicesLayout from "components/user-account/UserInvoicesLayout";
import UserProfile from "components/user-account/UserProfile";
import AdminLayout from "layouts/AdminLayout";
import DefaultLayout from "layouts/DefaultLayout";
import MainLayout from "layouts/MainLayout";
import NoOuterLayout from "layouts/NoOuterLayout";
import UserAccountLayout from "layouts/UserAccountLayout";
import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch, useAppSelector } from "store";
import { setCategories, setCountries } from "store/features/staticDataSlice";
import { setUser } from "store/features/userSlice";
import AccountUnblockedConfirmationPage from "views/AccountUnblockedConfirmationPage";
import AuctionDetailsPage from "views/AuctionDetailsPage";
import AuctionsDashboardPage from "views/AuctionsDashboardPage";
import ConsentFormPage from "views/ConsentFormPage";
import ContactPage from "views/ContactPage";
import Homepage from "views/Homepage";
import LotDetailsPage from "views/LotDetailsPage";
import LotsDashboardPage from "views/LotsDashboardPage";
import OnboardingPage from "views/OnboardingPage";
import PendingUsersPage from "views/PendingUsersPage";
import PrivacyPolicyPage from "views/PrivacyPolicyPage";
import TermsAndConditionsPage from "views/TermsAndConditionsPage";
import VerifyEmailPage from "views/VerifyEmailPage";
import AuthCallback from "views/auth/AuthCallback";
import Error400 from "views/errors/Error400";
import Error403 from "views/errors/Error403";
import Error404 from "views/errors/Error404";
import ErrorOffline from "views/errors/ErrorOffline";

const App = () => {
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth();
  const { getMyUser, createMyUser } = useUserService();
  const { getCategories } = useCategoriesService();
  const { getCountries } = useCountriesService();
  const [isSettingUpAccount, setIsSettingUpAccount] = useState(true);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const staticData = useAppSelector((state) => state.staticData);
  const dispatch = useAppDispatch();

  const fetchCategories = async () => {
    setIsLoadingCategories(true);

    const categoriesQuery: GetCategoriesQuery = {
      pageIndex: 0,
      pageSize: 50,
      sortDescending: false
    };
    const categories = (await getCategories(categoriesQuery)).data;

    dispatch(setCategories(categories.items));
    setIsLoadingCategories(false);
  };

  const fetchCountries = async () => {
    setIsLoadingCountries(true);

    const countries = (await getCountries()).data;
    dispatch(setCountries(countries));

    setIsLoadingCountries(false);
  };

  useEffect(() => {
    const upsertAccount = async () => {
      let myUser = (await getMyUser()).data;

      if (myUser === null) {
        await createMyUser();
        myUser = (await getMyUser()).data;
      }

      dispatch(setUser(myUser));
      setIsSettingUpAccount(false);
    };

    if (!isLoadingAuth && !isAuthenticated) {
      setIsSettingUpAccount(false);
    }

    if (!isLoadingAuth && isAuthenticated) {
      upsertAccount();
    }

    if (!staticData.categories && !isLoadingCategories) {
      fetchCategories();
    }
    if (!staticData.countries && !isLoadingCountries) {
      fetchCountries();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoadingAuth]);

  if (isSettingUpAccount || isLoadingAuth || isLoadingCategories || isLoadingCountries) {
    return <Spinner isAppLoader={true} />;
  }

  return (
    <>
      <Routes>
        <Route path="/*" element={<MainLayout />} >
          <Route index path="" element={<Homepage />} />
          <Route path="auctions">
            <Route index path="" element={<AuctionsDashboardPage />} />
            <Route path=":auctionSlug?" element={<AuctionDetailsPage />} />
          </Route>
          <Route path="lots" >
            <Route path=":categorySlug?" element={<LotsDashboardPage />} />
            <Route index path="" element={<LotsDashboardPage />} />
            <Route path=":lotSlug/details" element={<LotDetailsPage />} />
          </Route>
          <Route path="contact" element={<ContactPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="general-conditions" element={<TermsAndConditionsPage />} />
          <Route path="consent-form" element={<ConsentFormPage />} />
          <Route path="my-account/*" element={
            <ProtectedRoute
              authenticatedOnly={true}>
              <UserAccountLayout />
            </ProtectedRoute>
          }>
            <Route index path="" element={<UserAccount />} />
            <Route path="profile" element={<UserProfile />} />
            <Route path="bids/:status?" element={<UserBiddedLotsLayout />} />
            <Route path="invoices/:status?" element={<UserInvoicesLayout />} />
          </Route>
          <Route path="*" element={<Navigate to="" replace />} />
        </Route>
        <Route path="account-unblocked/*" element={<NoOuterLayout />}>
          <Route path="" element={<AccountUnblockedConfirmationPage />} />
        </Route>
        <Route path="verify-email/*" element={<DefaultLayout />} >
          <Route path="" element={<VerifyEmailPage />} />
          <Route path="*" element={<Navigate to="" replace />} />
        </Route>
        <Route path="onboarding/*" element={<DefaultLayout />} >
          <Route path=""
            element={
              <ProtectedRoute
                condition={(user: User): boolean => {
                  return user &&
                    (user.status === "Confirmed" || user.status === "ValidationInProgress");
                }}>
                <OnboardingPage />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="" replace />} />
        </Route>
        <Route path="admin/*" element={
          <ProtectedRoute
            adminOnly={true}>
            <AdminLayout />
          </ProtectedRoute>
        }>
          <Route path="pending-users" element={<PendingUsersPage />} />
          <Route path="*" element={<Navigate to="pending-users" replace />} />
        </Route>
        <Route path="auth/*" element={<DefaultLayout />}>
          <Route path="callback" element={<AuthCallback />} />
          <Route path="*" element={<Navigate to="" replace />} />
        </Route>
        <Route path="error/*" element={<NoOuterLayout />}>
          <Route path="offline" element={<ErrorOffline />} />
          <Route path="not-found" element={<Error404 />} />
          <Route path="unauthorized" element={<Error403 />} />
          <Route path="bad-request" element={<Error400 />} />
          <Route path="*" element={<Navigate to="offline" replace />} />
        </Route>
      </Routes>

      <ToastContainer />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        buttonClasses="text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900"
      >
        Utilizăm cookies pentru a-ți oferi cea mai bună experiență în timpul licitațiilor. Continuând, ești de acord cu &nbsp;
        <Link
          to='privacy-policy'
          className="font-semibold underline">
          Politica privind cookies
        </Link>
      </CookieConsent >
    </>
  );
};

export default App;
