import { BiddedLot } from "api/models/biddedLot";
import { Page } from "api/models/page";
import { UserLotStatus } from "api/models/userLotStatus";
import { GetUserBiddedLotsQuery } from "api/requests/getUserBiddedLotsQuery";
import { useUserService } from "api/services/usersService";
import BiddedLotCard from "components/card/BiddedLotCard";
import Spinner from "components/spinner";
import { Pagination } from "flowbite-react";
import { useEffect, useState } from "react";

const BiddedLotsListView = ({
  status
}: {
  status?: UserLotStatus
}) => {

  const [isLoadingBids, setIsLoadingBids] = useState(true);
  const [biddedLots, setBiddedLots] = useState<Page<BiddedLot>>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const { getBiddedLots } = useUserService();

  const getBiddedLotsQuery: GetUserBiddedLotsQuery = {
    pageIndex: 0,
    pageSize: 10,
    status: status,
    sortBy: 'EndsAtUtc',
    sortDescending: true
  };

  const fetchBids = async () => {
    setIsLoadingBids(true);

    const biddedLotsData = (await getBiddedLots(getBiddedLotsQuery)).data;
    setBiddedLots(biddedLotsData);

    setCurrentPage(getBiddedLotsQuery.pageIndex);
    setTotalPages(Math.trunc((biddedLotsData.totalCount + getBiddedLotsQuery.pageSize - 1) / getBiddedLotsQuery.pageSize));

    setIsLoadingBids(false);
  };

  useEffect(() => {
    fetchBids();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onPageChange = async (page: number) => {
    getBiddedLotsQuery.pageIndex = page - 1;
    await fetchBids();
  }

  if (isLoadingBids) {
    return <Spinner />;
  }

  return (
    <div>
      {biddedLots?.totalCount > 0
        ? <>
          <div className="flex flex-col space-y-2 mb-2">
            {biddedLots?.items.map((bid, index) => (
              <BiddedLotCard key={index} biddedLot={bid} />
            ))}
          </div>

          {biddedLots && totalPages > 1 && <Pagination
            currentPage={currentPage + 1}
            totalPages={totalPages}
            onPageChange={onPageChange}
            showIcons
            nextLabel="Înainte"
            previousLabel="Înapoi"
            className="flex justify-center md:float-right" />}
        </>
        : <h3 className="text-center align-middle text-base md:text-lg lg:text-xl">
          Nu există loturi în această categorie
        </h3>
      }
    </div>
  );
};

export default BiddedLotsListView;
