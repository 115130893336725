import { UserType } from "api/models/user";
import { HelperText, Label } from "flowbite-react";
import { useState } from "react";
import 'react-international-phone/style.css';
import LegalEntityOnboardingForm from "./legal-entity/LegalEntityOnboardingForm";
import PrivateIndividualOnboardingForm from "./private-individual/PrivateIndividualOnboardingForm";

export type OnboardForm = {
  type: UserType;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  // Personal account fields
  personalIdentificationNumber?: string;
  personalCountryCode?: string;
  personalAcceptConsentForm?: boolean;
  // Company fields
  companyName?: string;
  companyCountryCode?: string;
  companyAddress?: string;
  companyPostalOrZipCode?: string;
  companyVatNumber?: string;
  companyRegistrationNumber?: string;
  acceptPrivacyPolicy: boolean;
  acceptGeneralConditions: boolean;
};

export type OnboardingFormErrors = Partial<Record<keyof OnboardForm, string>>;

export default function OnboardingForm({
  onSubmit,
}: Readonly<{
  onSubmit: (form: OnboardForm) => void;
}>) {
  const [selectedAccountType, setSelectedAccountType] = useState<UserType>('PrivateIndividual');

  const submitForm = (fields: OnboardForm) => {
    onSubmit(fields);
  };

  return (
    <div className="w-full md:w-[75%]">
      <span className="font-bold text-navy-700 text-lg md:text-3xl ">Bun venit!</span>
      <p className="text-sm md:text-base pt-1 md:pt-2 text-gray-600">
        Pentru a putea începe să licitezi, avem nevoie de câteva informații
        din partea ta.
      </p>

      <div className="mt-3 md:mt-6 space-y-6">
        <div className="mb-4">
          <Label value="Tip cont *" />
          <HelperText className="italic text-sm text-gray-400">Vei licita ca persoana fizică sau juridică?</HelperText>
          <div className="flex gap-4 mt-2">
            <div className="flex items-center">
              <input
                type="radio"
                id="personal"
                name="accountType"
                value="PrivateIndividual"
                checked={selectedAccountType === 'PrivateIndividual'}
                onChange={(e) => setSelectedAccountType(e.target.value as UserType)}
                className="w-4 h-4 text-brand-600 bg-gray-100 border-gray-300 focus:ring-brand-500 cursor-pointer"
              />
              <label htmlFor="personal" className="ml-2 text-sm font-medium text-gray-900 cursor-pointer">
                Cont Personal
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="company"
                name="accountType"
                value="LegalEntity"
                checked={selectedAccountType === 'LegalEntity'}
                onChange={(e) => setSelectedAccountType(e.target.value as UserType)}
                className="w-4 h-4 text-brand-600 bg-gray-100 border-gray-300 focus:ring-brand-500 cursor-pointer"
              />
              <label htmlFor="company" className="ml-2 text-sm font-medium text-gray-900 cursor-pointer">
                Cont Companie
              </label>
            </div>
          </div>
        </div>

        {selectedAccountType === 'LegalEntity'
          ? <LegalEntityOnboardingForm onSubmit={submitForm} />
          : <PrivateIndividualOnboardingForm onSubmit={submitForm} />}
        </div>
    </div>
  );
}
