import logo from "assets/img/logo.png";
import { Button } from "flowbite-react";
import { HiOutlineCheckCircle, HiOutlineXCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";

const MessageBoxWithBrandCard = (props: {
  iconType: 'error' | 'success' | 'none';
  title?: string;
  message: string;
  hideButton?: boolean;
  buttonLinkTo?: string;
  buttonText?: string;
}) => {
  const { iconType, title, message, hideButton, buttonLinkTo, buttonText } = props;
  return (
    <div className="flex h-full justify-center items-center">
      <div className="flex flex-col items-center gap-4 text-center h-1/2 w-3/4 lg:w-1/4 bg-lightPrimary rounded-xl border">
        <div className="my-5 lg:my-6">
          <Link to="/">
            <img src={logo} className="h-8" alt="Licitas Logo" />
          </Link>
        </div>
        <div className="grow justify-center">
          <div className="flex flex-col items-center justify-center">
            {iconType !== 'none' &&
              <div className="mb-5 text-white rounded-full w-12 h-12 flex items-center justify-center">
                {iconType === 'success'
                  ? <HiOutlineCheckCircle color="green" size="2x" />
                  : <HiOutlineXCircle color="red" size="2x" />}
              </div>}
            <div className="text-center mx-2">
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900">{title ?? 'Uh-oh'}</h1>
              <p className="lg:text-lg mt-3 text-gray-500 px-2 whitespace-pre-line">
                {message}
              </p>
              {!hideButton &&
                <Link to={buttonLinkTo ?? '/'}>
                  <Button color="brand" className="text-base md:text-lg lg:text-xl font-bold mt-4 mx-auto">{buttonText ?? 'Către prima pagină'}</Button>
                </Link>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageBoxWithBrandCard;