import { LogLevel } from "@microsoft/signalr";
import signalR from "use-signalr-hub";
import { LotActivatedNotification } from "./messages/lotActivatedNotification";
import { LotBidEndDateNotification } from "./messages/lotBidEndDateNotification";
import { LotBidNotification } from "./messages/lotBidNotification";
import { LotClosedNotification } from "./messages/lotClosedNotification";

const useLotsHub = (
  lotIds: number[],
  onbidRegistered?: (notification: LotBidNotification) => void,
  onEndDateChanged?: (notification: LotBidEndDateNotification) => void,
  onActivated?: (notification: LotActivatedNotification) => void,
  onClosed?: (notification: LotClosedNotification) => void
) => {
  const idsAsString = lotIds.join(",");

  signalR.useHub(`${process.env.REACT_APP_API_BASE_URL}/push/lots?ids=${idsAsString}`, {
    automaticReconnect: true,
    logging: LogLevel.None,
    onConnected: (hub) => {
      hub.on("BidRegistered", onbidRegistered);
      hub.on("EndDateChanged", onEndDateChanged);
      hub.on("Activated", onActivated);
      hub.on("Closed", onClosed);
    }
  })
}

export default useLotsHub;
