import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Category } from "api/models/category";
import { Country } from "api/models/country";

type StaticData = {
  categories?: Category[],
  countries?: Country[]
};

export const currentStaticDataSlice = createSlice({
  name: 'static-data',
  initialState: {} as StaticData,
  reducers: {
    setCategories: (state, action: PayloadAction<Category[]>) => {
      return { ...state, categories: action.payload };
    },
    setCountries: (state, action: PayloadAction<Country[]>) => {
      return { ...state, countries: action.payload };
    }
  }
});

export const { setCategories, setCountries } = currentStaticDataSlice.actions;
export const currentStaticDataReducer = currentStaticDataSlice.reducer;