import { Country } from "api/models/country";
import { Label, Select } from "flowbite-react";
import { useState } from "react";


const CountrySelector = (props: {
  label: string,
  countries: Country[],
  defaultCountry?: string;
  onCountryChange: (selectedCountryCode: string) => void
}) => {
  const { label, countries, defaultCountry, onCountryChange } = props;
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry || "");

  const handleCountryChange = (e: any) => {
    const country = e.target.value;
    setSelectedCountry(country);
    onCountryChange(country);
  };

  return (
    <div>
      <div className="mb-2 block">
        <Label htmlFor="countries" value={label} />
      </div>
      <Select id="countries" required value={selectedCountry} onChange={handleCountryChange}>
        {
          countries && countries.map((country) => (
            <option key={country.code} value={country.code}>{country.name}</option>
          ))}
      </Select>
    </div>
  );
}

export default CountrySelector;