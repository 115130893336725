import { User } from 'api/models/user';
import { Button, Modal } from 'flowbite-react';
import { neatDate } from "formatters/date";
import { isRomania } from 'utils/countryHelper';

function PendingUserModal({
  show,
  user,
  onApproveUser = () => { },
  onRejectUser = () => { },
  onCloseModal = () => { }
}: Readonly<{
  show: boolean,
  user: User,
  onApproveUser: (user: User) => void
  onRejectUser: (user: User) => void,
  onCloseModal: () => void
}>) {

  return (
    <Modal dismissible show={show} onClose={onCloseModal}>
      <Modal.Header>{user.lastName} {user.firstName} {user.type === 'LegalEntity' && `(${user.company?.name})`}</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <div className="text-base leading-relaxed text-gray-600 dark:text-gray-400 grid grid-cols-1 md:grid-cols-2 gap-2">
            <div><p className="font-bold inline md:block">Nume: </p>{user.lastName}</div>
            <div><p className="font-bold inline md:block">Prenume: </p>{user.firstName}</div>
            <div><p className="font-bold inline md:block">Adresă email: </p>{user.email}</div>
            <div><p className="font-bold inline md:block">Număr telefon: </p>{user.phoneNumber}</div>
          </div>
          {user.company && (
            <>
              <hr className="h-px my-8 bg-brand-200 border-0 dark:bg-brand-700"></hr>
              <div className="text-base leading-relaxed text-gray-600 dark:text-gray-400 grid grid-cols-1 md:grid-cols-2 gap-2">
                <div><p className="font-bold inline md:block">Nume companie: </p>{user.company.name}</div>
                <div><p className="font-bold inline md:block">Țară companie: </p>{user.company.countryCode}</div>
                <div><p className="font-bold inline md:block">Adresă companie: </p>{user.company.address}</div>
                <div><p className="font-bold inline md:block">Cod poștal: </p>{user.company.postalOrZipCode}</div>
                <div><p className="font-bold inline md:block">CIF companie: </p>{user.company.vatNumber}</div>
                {isRomania(user.company.countryCode) &&
                  <div><p className="font-bold inline md:block">Nr. înregistrare ONRC: </p>{user.company.registrationNumber}</div>}
              </div>
            </>
          )}
          {user.privateIndividual && (
            <>
              <hr className="h-px my-8 bg-brand-200 border-0 dark:bg-brand-700"></hr>
              <div className="text-base leading-relaxed text-gray-600 dark:text-gray-400 grid grid-cols-1 md:grid-cols-2 gap-2">
                <div><p className="font-bold inline md:block">Țară: </p>{user.privateIndividual.countryCode}</div>
                <div><p className="font-bold inline md:block">Adresă: </p>{user.privateIndividual.address}</div>
                <div><p className="font-bold inline md:block">Cod poștal: </p>{user.privateIndividual.postalOrZipCode}</div>
              </div>
            </>
          )}
          <hr className="h-px my-8 bg-brand-200 border-0 dark:bg-brand-700"></hr>
          <div className="text-base leading-relaxed text-gray-600 dark:text-gray-400 grid grid-cols-1 md:grid-cols-2 gap-2">
            <div><p className="font-bold">Data înregistrării pe platformă: </p>{neatDate(user.createdAtUtc)}</div>
            <div><p className="font-bold">Data trimiterii informațiilor: </p>{neatDate(user.lastOnboardingSubmittedAtUtc)}</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-end items-end space-x-6">
        <Button color="brand" onClick={() => { onApproveUser(user); onCloseModal() }}>Aprobă</Button>
        <Button color="red" onClick={() => { onRejectUser(user); onCloseModal() }}>Refuză</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PendingUserModal;
