/* eslint-disable react/style-prop-object */
import { BiddedLotsOverview } from 'api/models/biddedLotsOverview';
import { UserLotStatus } from 'api/models/userLotStatus';
import { useUserService } from 'api/services/usersService';
import Spinner from 'components/spinner';
import { Badge, Dropdown, FlowbiteColors, Tabs, TabsRef } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ViewBreakpoints } from 'static-data/constants';
import BiddedLotsListView from './BiddedLotsListView';

type BiddedLotsStatusMap = Partial<Record<UserLotStatus, string>>;

export default function UserBiddedLotsLayout() {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const { status: statusParam } = useParams();
  const navigate = useNavigate();
  const tabsRef = useRef<TabsRef>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [biddedLotsOverview, setBiddedLotsOverview] = useState<BiddedLotsOverview>(null);
  const { getBiddedLotsOverview } = useUserService();

  const biddedLotsStatusMap: BiddedLotsStatusMap =
  {
    "Active": "Active",
    "Won": "Câștigate",
    "Lost": "Pierdute",
    "Reserved": "Rezervate",
    "WaitingPayment": "Așteaptă plata",
    "Paid": "Plătite"
  };
  const biddedLotsStatuses = Object.keys(biddedLotsStatusMap) as UserLotStatus[];

  const [selectedStatus, setSelectedStatus] = useState<UserLotStatus>(statusParam as UserLotStatus);

  const fetchBidsOverview = async () => {
    setIsLoadingData(true);

    setBiddedLotsOverview((await getBiddedLotsOverview()).data);

    setIsLoadingData(false)
  };

  useEffect(() => {

    if (biddedLotsOverview === null) {
      fetchBidsOverview();
    }

    let status = statusParam;
    if (status === undefined) {
      status = "Active";
    }

    const matchedStatusIndex = biddedLotsStatuses.findIndex(s => s.toLowerCase() === status?.toLowerCase());
    if (matchedStatusIndex === -1) {
      navigate(`/error/not-found`);
      return;
    };

    setSelectedStatus(biddedLotsStatuses[matchedStatusIndex]);
    tabsRef.current?.setActiveTab(matchedStatusIndex);

    const handleResize = (() => window.innerWidth < ViewBreakpoints.xl ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biddedLotsOverview]);

  const onSelectBidsOverview = (selectedStatus: UserLotStatus) => {
    setSelectedStatus(selectedStatus);
    navigate(`/my-account/bids/${selectedStatus}`)
  }

  const onTabOverviewChanged = (tabIndex: number) => {
    const selectedStatus = biddedLotsStatuses[tabIndex];
    setSelectedStatus(selectedStatus);
    navigate(`/my-account/bids/${selectedStatus}`);
  }

  const getBidsCountByStatus = (status: UserLotStatus): number => {
    switch (status) {
      case "Active":
        return biddedLotsOverview.totalActiveLots;
      case 'Won':
        return biddedLotsOverview.totalWonLots;
      case 'Reserved':
        return biddedLotsOverview.totalReservedLots;
      case 'Lost':
        return biddedLotsOverview.totalLostLots
      case 'WaitingPayment':
        return biddedLotsOverview.totalWaitingPaymentLots;
      case 'Paid':
        return biddedLotsOverview.totalPaidLots;
    }
  }

  const getSelectionTitle = (status: UserLotStatus, badgeColor: keyof FlowbiteColors) =>
    <p className="flex justify-between items-center text-sm space-x-1 lg:space-x-2">
      <span>{biddedLotsStatusMap[status]}</span>
      <Badge color={badgeColor} size="xs" className="shrink-0 rounded-full">{getBidsCountByStatus(status)}</Badge>
    </p>

  const tabsLayout = () =>
    <Tabs aria-label="Licitări" style="underline" ref={tabsRef} onActiveTabChange={(tab) => onTabOverviewChanged(tab)}>
      {biddedLotsStatuses.map((status, index) =>
        <Tabs.Item key={index} active title={getSelectionTitle(status, "brand")}>
        </Tabs.Item>
      )}
    </Tabs>;

  const dropdownLayout = () =>
    <Dropdown
      theme={{ floating: { target: "w-full" } }}
      color="brand"
      label={getSelectionTitle(selectedStatus, "light")}>
      {
        biddedLotsStatuses.map((status, index) => (
          <div key={index}>
            <Dropdown.Item
              onClick={() => onSelectBidsOverview(status)}>
              {getSelectionTitle(status, "brand")}</Dropdown.Item>
            {index < biddedLotsStatuses.length - 1 && <Dropdown.Divider />}
          </div>
        ))}
    </Dropdown>;

  if (isLoadingData) {
    return <Spinner />
  }

  return (
    <div className="w-full">
      {isSmallScreen ? dropdownLayout() : tabsLayout()}
      <div className="py-4">
        <BiddedLotsListView status={selectedStatus} />
      </div>
    </div>
  );
}
