const PrivacyPolicyPage = () => {
  return <>
    <div className="flex flex-col mx-auto w-full">
      <h1 className="text-4xl font-bold mb-4">Politica de Confidențialitate</h1>
      <p className="text-lg">Această Politică de confidențialitate a fost actualizată ultima dată la 20 ianuarie 2025.</p>

      <h2 className="text-lg font-bold mt-8 mb-4">1. Introducere</h2>
      <p className="mb-2">Licitas Auctions SRL respectă dreptul dumneavoastră la confidențialitate și se angajează să protejeze datele dumneavoastră personale conform Regulamentului (UE) 2016/679 privind protecția datelor cu caracter personal (GDPR) și legislației aplicabile din România.</p>
      <p className="mb-2">Această politică de confidențialitate explică modul în care colectăm, utilizăm și protejăm datele personale pe platforma noastră online. Citiți cu atenție acest document pentru a înțelege practicile noastre privind datele cu caracter personal.</p>
      <p className="mb-2">Scopul acestei Politici este să vă informeze despre modul în care prelucrăm datele cu caracter personal, în conformitate cu legislația aplicabilă, inclusiv Regulamentul (UE) 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și libera circulație a acestor date („GDPR”).</p>
      <p>Vă rugăm să citiți cu atenție acest document, care descrie clar:</p>
      <ul className="list-disc ml-8 mb-2">
        <li>ce tipuri de date cu caracter personal colectăm;</li>
        <li>scopurile și temeiurile legale ale prelucrării datelor;</li>
        <li>perioada pentru care stocăm aceste date;</li>
        <li>drepturile dumneavoastră în ceea ce privește datele cu caracter personal;</li>
        <li>cui și în ce condiții putem dezvălui aceste date;</li>
        <li>utilizarea tehnologiilor, inclusiv cookie-uri și alte instrumente de analiză a traficului.</li>
      </ul>
      <p className="mb-2">Această politică se aplică tuturor categoriilor de persoane fizice care interacționează cu noi sau utilizează Platforma, inclusiv utilizatorilor, vizitatorilor, partenerilor de afaceri, colaboratorilor, potențialilor angajați și reprezentanților lor.</p>

      <h2 className="text-lg font-bold mt-8 mb-4">2. Operatorul de date</h2>
      <p className="mb-2"><b>Licitas Auctions SRL</b>, cu sediul social în Traian Vuia 15, Suceava, Suceava, Romania, 720021, este operatorul de date pentru platforma online de licitații www.licitas.ro („Platforma”). În acest document, termenii „Licitas Auctions”, „Societatea” sau „noi” se referă la entitatea juridică mai sus menționată.</p>
      <p>Sediu: Traian Vuia 15, Suceava, Suceava, Romania, 720021</p>
      <p>CUI: RO50995017</p>
      <p>Nr. Înmatriculare: J20/2404/2024</p>
      <p>E-mail: contact@licitas.ro</p>
      <p>Telefon: 0746011105</p>

      <h2 className="text-lg font-bold mt-8 mb-4">3. Datele personale pe care le colectăm</h2>
      <p><b>Pentru persoane juridice:</b></p>
      <ul className="list-disc ml-8 mb-2">
        <li>Nume firmă;</li>
        <li>Cod unic de identificare (CUI);</li>
        <li>Adresa firmei;</li>
        <li>Număr de telefon;</li>
        <li>Adresă de e-mail.</li>
      </ul>
      <p><b>Pentru persoane fizice:</b></p>
      <ul className="list-disc ml-8 mb-2">
        <li>Nume și prenume;</li>
        <li>Cod numeric personal (CNP);</li>
        <li>Număr de telefon;</li>
        <li>Adresă de e-mail;</li>
        <li>Adresă completă.</li>
      </ul>
      <p className="mb-2">Aceste date sunt necesare pentru înregistrarea pe platformă, utilizarea serviciilor noastre și pentru a respecta obligațiile legale.</p>

      <h2 className="text-lg font-bold mt-8 mb-4">4. Scopurile prelucrării datelor</h2>
      <p>Datele personale sunt colectate și utilizate pentru următoarele scopuri:</p>
      <ul className="list-disc ml-8 mb-2">
        <li>Crearea și gestionarea contului de utilizator pe platforma noastră;</li>
        <li>Facilitarea procesului de licitație online;</li>
        <li>Comunicarea cu utilizatorii, inclusiv notificări legate de activitatea pe platformă;</li>
        <li>Respectarea obligațiilor legale (de exemplu, obligații contabile și fiscale);</li>
        <li>Analiza traficului pe platformă pentru îmbunătățirea serviciilor oferite (prin Google Analytics).</li>
      </ul>

      <h2 className="text-lg font-bold mt-8 mb-4">5. Temeiurile legale ale prelucrării</h2>
      <p>Prelucrăm datele personale în baza următoarelor temeiuri:</p>
      <ul className="list-disc ml-8 mb-2">
        <li><b>Executarea unui contract</b>: Datele sunt necesare pentru înregistrarea utilizatorilor și desfășurarea licitațiilor online.</li>
        <li><b>Respectarea obligațiilor legale</b>: De exemplu, pentru emiterea facturilor și păstrarea registrelor contabile.</li>
        <li><b>Consimțământ</b>: În cazul utilizării Google Analytics și pentru comunicările de marketing (dacă este cazul).</li>
        <li><b>Interes legitim</b>: Pentru securitatea platformei și îmbunătățirea serviciilor.</li>
      </ul>
      <h2 className="text-lg font-bold mt-8 mb-4">6. Cookie-uri</h2>
      <p className="text-lg mb-2">Identificarea Cookie-urilor Utilizate de Auth0 și Alte Servicii</p>
      <p className="mb-2">Mai jos este analiza cookie-urilor găsite în lista ta, cu descrierea fiecărui tip și rolul său în cadrul platformei Auth0 sau al altor servicii utilizate.</p>

      <p className="mb-2"><b>6.1 Cookie-uri Esențiale pentru Autentificare și Securitate (Auth0)</b></p>
      <p className="mb-2">Aceste cookie-uri sunt necesare pentru gestionarea autentificării și securității utilizatorilor.</p>
      <table className="w-full divide-y divide-gray-200 mb-2">
        <thead className="bg-white">
          <tr>
            <th className="pl-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Cookie</th>
            <th className="pr-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Descriere</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-gray-200">
          <tr>
            <td className="pl-2 py-2 whitespace-nowrap">auth0</td>
            <td className="pr-2 py-2">Stochează sesiunea utilizatorului și token-urile de autentificare pentru menținerea autentificării active.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">auth0_compat</td>
            <td className="pr-2 py-2">Versiune compatibilă a cookie-ului auth0, utilizată pentru browsere mai vechi sau pentru a menține compatibilitatea între sesiuni.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">auth0.is.authenticated</td>
            <td className="pr-2 py-2">Indicator care arată dacă utilizatorul este autentificat pe platforma Auth0.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">did</td>
            <td className="pr-2 py-2">Identificator unic al dispozitivului utilizat pentru autentificare și pentru a preveni accesul fraudulos.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">did_compat</td>
            <td className="pr-2 py-2">Versiune compatibilă a did, utilizată pentru dispozitive sau browsere mai vechi.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">_legacy_auth0</td>
            <td className="pr-2 py-2">Variantă veche a cookie-ului auth0, utilizată pentru compatibilitate cu aplicații mai vechi.</td>
          </tr>
        </tbody>
      </table>
      <p className="mb-2"><b>6.2 Cookie-uri pentru Securitate și Prevenirea Fraudelor</b></p>
      <p className="mb-2">Acestea ajută la protejarea conturilor utilizatorilor și a sesiunilor.</p>
      <table className="w-full divide-y divide-gray-200 mb-2">
        <thead className="bg-white">
          <tr>
            <th className="pl-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Cookie</th>
            <th className="pr-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Descriere</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-gray-200">
          <tr>
            <td className="pl-2 py-2 whitespace-nowrap">auth0-state</td>
            <td className="pr-2 py-2">Protecție împotriva atacurilor de tip CSRF în timpul autentificării.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">auth0.ss</td>
            <td className="pr-2 py-2">Protecție împotriva atacurilor de tip session hijacking.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">auth0.sameSite</td>
            <td className="pr-2 py-2">Se asigură că cookie-urile sunt folosite doar în domeniul Auth0 pentru a preveni furtul de sesiuni.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">_cfuvid</td>
            <td className="pr-2 py-2">Cloudflare Visitor ID - Utilizat pentru protecția boturilor și prevenirea accesului fraudulos.</td>
          </tr>
        </tbody>
      </table>
      <p className="mb-2"><b>6.3 Cookie-uri pentru Analiză și Tracking (Google Analytics, Facebook, Hotjar, AdRoll, etc.)</b></p>
      <p className="mb-2">Aceste cookie-uri sunt utilizate pentru monitorizarea traficului și îmbunătățirea experienței utilizatorilor.</p>
      <table className="w-full divide-y divide-gray-200 mb-2">
        <thead className="bg-white">
          <tr>
            <th className="pl-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Cookie</th>
            <th className="pr-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Descriere</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-gray-200">
          <tr>
            <td className="pl-2 py-2 whitespace-nowrap">_ga</td>
            <td className="pr-2 py-2">Google Analytics – identificator unic pentru urmărirea utilizatorilor pe site.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">_ga_QKMSDV5369</td>
            <td className="pr-2 py-2">Variante specifice Google Analytics pentru tracking avansat.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">_fbp</td>
            <td className="pr-2 py-2">Facebook Pixel – folosit pentru remarketing și tracking al utilizatorilor.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">_gcl_au</td>
            <td className="pr-2 py-2">Google Ads – folosit pentru urmărirea conversiilor.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">_hp2_id.1279799279</td>
            <td className="pr-2 py-2">Heap Analytics – monitorizează comportamentul utilizatorilor pe platformă.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">_hp2_ses_props.1279799279</td>
            <td className="pr-2 py-2">Cookie Heap pentru sesiunea curentă a utilizatorului.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">__adroll_fpc</td>
            <td className="pr-2 py-2">AdRoll – utilizat pentru retargeting și campanii publicitare.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">_mkto_trk</td>
            <td className="pr-2 py-2">Marketo – folosit pentru tracking-ul campaniilor de marketing.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">optimizelyEndUserId</td>
            <td className="pr-2 py-2">Optimizely – folosit pentru testare A/B și personalizarea experienței utilizatorilor.</td>
          </tr>
        </tbody>
      </table>
      <p className="mb-2"><b>6.4 Cookie-uri pentru Managementul Consimțământului</b></p>
      <p className="mb-2">Aceste cookie-uri sunt folosite pentru a înregistra opțiunile utilizatorilor privind cookie-urile și conformitatea cu reglementările GDPR.</p>
      <table className="w-full divide-y divide-gray-200 mb-2">
        <thead className="bg-white">
          <tr>
            <th className="pl-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Cookie</th>
            <th className="pr-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Descriere</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-gray-200">
          <tr>
            <td className="pl-2 py-2 whitespace-nowrap">OptanonConsent</td>
            <td className="pr-2 py-2">Gestionarea consimțământului utilizatorilor pentru cookie-uri prin OneTrust.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">OptanonAlertBoxClosed</td>
            <td className="pr-2 py-2">Stochează preferințele utilizatorului privind afișarea notificărilor cookie.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">CookieConsent</td>
            <td className="pr-2 py-2">Înregistrează dacă utilizatorul a acceptat utilizarea cookie-urilor pe platformă.</td>
          </tr>
        </tbody>
      </table>
      <p className="mb-2"><b>6.5 Cookie-uri de Tracking pentru Reclame și Remarketing</b></p>
      <p className="mb-2">Acestea sunt utilizate pentru campanii publicitare și urmărirea conversiilor.</p>
      <table className="w-full divide-y divide-gray-200 mb-2">
        <thead className="bg-white">
          <tr>
            <th className="pl-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Cookie</th>
            <th className="pr-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Descriere</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-gray-200">
          <tr>
            <td className="pl-2 py-2 whitespace-nowrap">__ar_v4</td>
            <td className="pr-2 py-2">AdRoll – urmărire publicitară.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">_rdt_uuid</td>
            <td className="pr-2 py-2">Reddit Pixel – folosit pentru campanii publicitare.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">vs_sid, vs_vid</td>
            <td className="pr-2 py-2">Cookie-uri pentru personalizarea experienței pe platforme care folosesc VWO (Visual Website Optimizer).</td>
          </tr>
          <tr>
            <td className="px-2 py-2">vs_intent, vs_conv_ai, vs_lift_ai</td>
            <td className="pr-2 py-2">Indică intenția utilizatorului și interacțiunea cu platforma (VWO).</td>
          </tr>
        </tbody>
      </table>
      <p className="mb-2"><b>6.6 Cookie-uri pentru Okta (Single Sign-On - SSO)</b></p>
      <p className="mb-2">Auth0 folosește Okta pentru gestionarea autentificării unificate (SSO), iar aceste cookie-uri sunt folosite pentru tracking și analiza surselor de trafic.</p>
      <table className="w-full divide-y divide-gray-200 mb-2">
        <thead className="bg-white">
          <tr>
            <th className="pl-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Cookie</th>
            <th className="pr-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Descriere</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-gray-200">
          <tr>
            <td className="pl-2 py-2 whitespace-nowrap">_okta_attribution</td>
            <td className="pr-2 py-2">Identifică sursa traficului utilizatorului pe Auth0.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">_okta_session_attribution</td>
            <td className="pr-2 py-2">Variante ale _okta_attribution pentru sesiuni.</td>
          </tr>
          <tr>
            <td className="px-2 py-2 whitespace-nowrap">_okta_original_attribution</td>
            <td className="pr-2 py-2">Stochează informații despre prima sursă a utilizatorului.</td>
          </tr>
        </tbody>
      </table>
      <p className="mb-2"><b>6.7 Cookie-uri pentru Hotjar (Analiză UX)</b></p>
      <p className="mb-2">Aceste cookie-uri sunt utilizate pentru analiza comportamentului utilizatorilor și îmbunătățirea experienței pe platformă.</p>
      <table className="w-full divide-y divide-gray-200 mb-2">
        <thead className="bg-white">
          <tr>
            <th className="pl-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Cookie</th>
            <th className="pr-2 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Descriere</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-gray-200">
          <tr>
            <td className="pl-2 py-2 whitespace-nowrap">_hjSessionUser_301495</td>
            <td className="pr-2 py-2">Hotjar – identifică utilizatorii care revin pe site pentru a analiza comportamentul acestora.</td>
          </tr>
        </tbody>
      </table>
      <p className="text-lg mb-2">Concluzie</p>
      <ul className="list-disc ml-8 mb-2">
        <li><b>Auth0 utilizează cookie-uri esențiale pentru autentificare și securitate</b>, precum auth0, did, auth0.is.authenticated, auth0.ss, auth0.sameSite, auth0-state.</li>
        <li><b>Sunt folosite cookie-uri pentru tracking și analiză</b>, precum _ga, _fbp, _gcl_au, optimizelyEndUserId, __adroll_fpc, Heap Analytics, Hotjar.</li>
        <li><b>Cookie-urile pentru consimțământ</b>, precum OptanonConsent și CookieConsent, sunt utilizate pentru respectarea GDPR.</li>
        <li><b>Okta și VWO adaugă cookie-uri pentru autentificare unificată și optimizarea experienței utilizatorilor.</b></li>
      </ul>
      <p className="mb-2">Aceste cookie-uri ajută la securizarea autentificării, îmbunătățirea experienței utilizatorilor și gestionarea campaniilor de marketing.</p>

      <h2 className="text-lg font-bold mt-8 mb-4">7. Stocarea datelor</h2>
      <p>Datele personale sunt stocate pe servere securizate și sunt păstrate:</p>
      <ul className="list-disc ml-8 mb-2">
        <li>Atât timp cât aveți un cont activ pe platformă;</li>
        <li>Conform obligațiilor legale (de exemplu, păstrarea documentelor fiscale timp de 10 ani).</li>
      </ul>
      <p className="mb-2">După încetarea relației contractuale sau la solicitarea dumneavoastră, datele vor fi șterse, cu excepția cazurilor în care legislația prevede altfel.</p>

      <h2 className="text-lg font-bold mt-8 mb-4">8. Transferul datelor</h2>
      <p className="mb-2">Datele personale nu sunt transferate în afara Spațiului Economic European (SEE). În cazul utilizării Google Analytics, datele pot fi procesate de Google, conform politicilor lor de confidențialitate.</p>

      <h2 className="text-lg font-bold mt-8 mb-4">9. Securitatea datelor</h2>
      <p className="mb-2">Luăm măsuri tehnice și organizatorice adecvate pentru a proteja datele dumneavoastră împotriva pierderii, accesului neautorizat sau utilizării abuzive. Accesul la date este limitat la personalul autorizat.</p>

      <h2 className="text-lg font-bold mt-8 mb-4">10. Drepturile dumneavoastră</h2>
      <p>Conform GDPR, aveți următoarele drepturi:</p>
      <ul className="list-disc ml-8 mb-2">
        <li><b>Dreptul de acces</b>: Puteți solicita acces la datele personale pe care le deținem despre dumneavoastră.</li>
        <li><b>Dreptul la rectificare</b>: Puteți solicita corectarea datelor incorecte sau incomplete.</li>
        <li><b>Dreptul la ștergere</b>: Puteți solicita ștergerea datelor personale în anumite condiții.</li>
        <li><b>Dreptul la restricționarea prelucrării</b>: Puteți solicita limitarea prelucrării datelor.</li>
        <li><b>Dreptul la portabilitate</b>: Puteți solicita transferul datelor către un alt operator.</li>
        <li><b>Dreptul de opoziție</b>: Vă puteți opune prelucrării datelor, inclusiv pentru scopuri de marketing.</li>
        <li><b>Dreptul de a depune o plângere</b>: Aveți dreptul să depuneți o plângere la Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP).</li>
      </ul>
      <p className="mb-2">Pentru exercitarea drepturilor, ne puteți contacta la adresa contact@licitas.ro.</p>

      <h2 className="text-lg font-bold mt-8 mb-4">11. Modificări ale politicii de confidențialitate</h2>
      <p className="mb-2">Această politică poate fi actualizată periodic. Orice modificare va fi publicată pe această pagină, iar utilizatorii vor fi notificați dacă schimbările sunt semnificative.</p>

      <h2 className="text-lg font-bold mt-8 mb-4">12. Contact</h2>
      <p>Pentru orice întrebări legate de această politică sau de prelucrarea datelor, ne puteți contacta:</p>
      <ul className="list-disc ml-8 mb-2">
        <li>Prin e-mail: contact@licitas.ro</li>
        <li>Telefonic: 0746011105</li>
        <li>Prin poștă: Traian Vuia 15, Suceava, Suceava, Romania, 720021</li>
      </ul>
    </div>
  </>;
};

export default PrivacyPolicyPage;
