import { shortDateWithTime } from "formatters/date";
import moment from "moment";
import Countdown from "react-countdown";

function LotEndDate({
  endDate,
  showLabel,
  dateClass,
  labelClass,
  className
}: Readonly<{
  endDate: Date,
  showLabel?: boolean
  dateClass?: string,
  labelClass?: string,
  className?: string
}>) {
  const remainingTime = moment.duration(moment(endDate).diff(new Date()));
  const label = remainingTime.asHours() < 24 ? "Se încheie în" : "Se încheie la";

  const getDatePattern = (): JSX.Element => {
    if (remainingTime.asHours() > 24) {
      return <p className={dateClass}>{shortDateWithTime(endDate)}</p>;
    }
    else {
      let renderer: (hours: number, minutes: number, seconds: number) => string;
      if (remainingTime.asMinutes() <= 1) {
        renderer = (hours, minutes, seconds) => `${seconds}s`;
      }
      else if (remainingTime.asMinutes() < 60) {
        renderer = (hours, minutes, seconds) => `${minutes}m ${seconds}s`;
      }
      else {
        renderer = (hours, minutes, seconds) => `${hours}h ${minutes}m ${seconds}s`;
      }
      return <Countdown date={endDate} renderer={({ hours, minutes, seconds }) => <span className={dateClass}>{renderer(hours, minutes, seconds)}</span>}></Countdown>
    }
  }

  return (
    <div className={className}>{showLabel && <span className={labelClass}>{label} {' '}</span>}{getDatePattern()}</div>
  )
}
export default LotEndDate;
