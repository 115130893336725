import { Pagination, Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from 'flowbite-react';
import { neatDate } from "formatters/date";
import { useState } from 'react';
import PendingUserModal from './PendingUserModal';
import { User } from 'api/models/user';

function PendingUsersTable({
  users,
  currentPage = 1,
  totalPages = 100,
  onPageChange = () => { },
  onApproveUser = () => { },
  onRejectUser = () => { }
}: Readonly<{
  users: User[],
  currentPage: number,
  totalPages: number,
  onPageChange: (page: number) => void,
  onApproveUser: (user: User) => void
  onRejectUser: (user: User) => void
}>) {
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);

  const onUserSelect = (user: User) => {
    setSelectedUser(user);
    setOpenModal(true);
  };

  return (
    <div className="overflow-x-auto">
      <Table hoverable striped>
        <TableHead>
          <TableHeadCell>Prenume</TableHeadCell>
          <TableHeadCell>Nume</TableHeadCell>
          <TableHeadCell>Firmă</TableHeadCell>
          <TableHeadCell>Țară</TableHeadCell>
          <TableHeadCell>Telefon</TableHeadCell>
          <TableHeadCell>Data aplicării</TableHeadCell>
        </TableHead>
        <TableBody className="divide-y">
          {users.map((user, index) => {
            return (
              <TableRow key={user.id} onClick={() => onUserSelect(user)}
                className="cursor-pointer bg-white dark:border-gray-700 dark:bg-gray-800">
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.company?.name}</TableCell>
                <TableCell>{user.type === 'LegalEntity' ? user.company?.countryCode : user.privateIndividual?.countryCode}</TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
                <TableCell>{neatDate(user.lastOnboardingSubmittedAtUtc)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        showIcons
        nextLabel="Înainte"
        previousLabel="Înapoi"
        className="float-right" />

      {openModal && selectedUser &&
        <PendingUserModal
        show={openModal}
        user={selectedUser}
        onApproveUser={onApproveUser}
        onRejectUser={onRejectUser}
        onCloseModal={() => setOpenModal(false)}
      />}
    </div>
  );
}

export default PendingUsersTable;
