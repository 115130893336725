import { User } from "api/models/user";
import { useAuth } from "api/useAuth";
import useAdminAuth from "auth/useAdminAuth";
import Spinner from "components/spinner";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "store";

export type ProtectedRouteProps = {
  children: JSX.Element | any[];
  condition?: (user: User) => boolean;
  authenticatedOnly?: boolean;
  adminOnly?: boolean;
  redirectPath?: string;
};

const ProtectedRoute = ({
  children,
  condition = () => true,
  adminOnly = false,
  authenticatedOnly = true,
  redirectPath = "/"
}: Readonly<ProtectedRouteProps>) => {
  const { isAuthenticated, login } = useAuth();
  const { isAdmin } = useAdminAuth();
  const storedUser = useAppSelector((state) => state.currentUser);

  if (authenticatedOnly && !isAuthenticated) {
    login();

    return <Spinner />
  };

  if ((adminOnly && !isAdmin()) ||
    !condition(storedUser)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ?? <Outlet />;
};

export default ProtectedRoute;
