import { AuctionSummary } from "api/models/auctionSummary";
import { CountryCityLocation } from "api/models/countryCityLocation";
import imageFallback from "assets/img/image_fallback.svg";
import LotEndDate from "components/lot/LotEndDate";
import { Button } from "flowbite-react";
import { shortDateWithTime } from "formatters/date";
import _ from "lodash";
import { useEffect, useState } from "react";
import { HiOutlineClock } from "react-icons/hi";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { RiGlobalLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ViewBreakpoints } from "static-data/constants";
import { useSlug } from "utils/slugHelper";

const AuctionSummaryCard = (props: {
  auction: AuctionSummary;
}) => {
  const { auction } = props;
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { slugify } = useSlug();

  useEffect(() => {
    const handleResize = (() => window.innerWidth < ViewBreakpoints.md ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const groupLocationsByCountry = (auction: AuctionSummary) =>
    _.groupBy(auction.countryCityLocations, (countryCityLocation: CountryCityLocation) => countryCityLocation.countryName);


  const getAuctionLocations = (auction: AuctionSummary): string => {
    const groupedLocations = groupLocationsByCountry(auction);
    let text = '';
    const noOfCountries = Object.keys(groupedLocations).length;
    if (noOfCountries === 1) {
      const noOfCities = Object.values(groupedLocations)[0].length;
      if (noOfCities === 1) {
        text = `${Object.keys(groupedLocations)[0]}, ${Object.values(groupedLocations)[0].at(0).city}`;
      }
      else {
        text = `${Object.keys(groupedLocations)[0]}, ${noOfCities} locații`
      }
    }
    else if (noOfCountries > 1) {
      text = `${auction.countryCityLocations.length} locații în ${Object.keys(groupedLocations).length} țări`
    }
    return text;
  }

  const auctionAdditionalInfo = (auction: AuctionSummary): JSX.Element => {
    return (
      <>
        {(() => {
          switch (auction.status) {
            case "Active":
              return <article className="flex items-center text-sm font-bold text-red-800">
                <HiOutlineClock className="pr-1 text-lg" />
                <LotEndDate endDate={auction.endDateUtc} />
              </article>
            case "Inactive":
              return <article className="flex items-center text-sm">
                <HiOutlineCalendarDays className="pr-1 text-lg" />
                <p>Începe la: <span className="font-semibold">{shortDateWithTime(auction.startDateUtc)}</span></p>
              </article>
            default:
              return <p className="text-sm text-gray-400 italic">Închis</p>;
          }
        })()}
        {auction.status === 'Active' &&
          <div className="flex justify-between items-center lg:gap-2">
            <Button color="brand"
              className="text-base md:text-lg lg:text-xl font-bold rounded-none rounded-br-lg md:rounded-lg"
            >
              {auction.totalLots} loturi
            </Button>
          </div>}
      </>
    );
  };

  return (
    <>
      <Link to={`/auctions/${slugify(auction.name, auction.id)}`}>
        <div className={`flex flex-row border border-gray-200 bg-white shadow-md max-w-full h-20 md:h-36 lg:h-48 ${isSmallScreen ? 'rounded-t-lg' : ' rounded-lg'}`}>
          <img
            src={auction.image?.url || imageFallback}
            alt={auction.image?.description || '...'}
            className={`shrink-0 object-cover h-auto w-24 md:w-48 lg:w-72 ${isSmallScreen ? 'rounded-tl-lg' : 'rounded-s-lg'}`} />
          <div className="flex h-full flex-col grow min-w-0 justify-start lg:justify-between gap-1 md:gap-2 md:p-4">
            <div className="mt-2 md:gap-2 ml-2 md:ml-0">
              {auction.countryCityLocations.length > 0 &&
                <p className="flex items-center underline underline-offset-2 decoration-gray-600 italic text-xs md:text-sm lg:text-base tracking-tight text-gray-600">
                  <RiGlobalLine className="pr-1 text-lg" />
                  {getAuctionLocations(auction)}
                </p>}
              <h3 className="mt-2 text-sm md:text-lg lg:text-2xl font-bold tracking-tight text-gray-900 truncate">
                {auction.name}
              </h3>
            </div>

            {!isSmallScreen && <div className="flex justify-between items-center">
              {auctionAdditionalInfo(auction)}
            </div>}
          </div>
        </div>
        {isSmallScreen &&
          <div className="w-full h-10 flex justify-between items-center pl-3 bg-gray-200 rounded-bl-lg rounded-br-lg">
            {auctionAdditionalInfo(auction)}
          </div>
        }
      </Link>
    </>
  );
};

export default AuctionSummaryCard;
