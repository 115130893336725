import useLotsHub from "api/hubs/lotsHub";
import { LotActivatedNotification } from "api/hubs/messages/lotActivatedNotification";
import { LotBidEndDateNotification } from "api/hubs/messages/lotBidEndDateNotification";
import { LotBidNotification } from "api/hubs/messages/lotBidNotification";
import { LotClosedNotification } from "api/hubs/messages/lotClosedNotification";
import { LotSummary } from "api/models/lotSummary";
import { useAuth } from "api/useAuth";
import LotSummaryCard from "components/card/LotSummaryCard";
import { useState } from "react";
import { useAppSelector } from "store";

const LotsListView = ({ lotSummaries }: { lotSummaries: LotSummary[] }) => {
  const user = useAppSelector((state) => state.currentUser);
  const { isAuthenticated } = useAuth();
  const [lots, setLots] = useState(lotSummaries);

  function onBidRegistered(notification: LotBidNotification) {
    setLots(prevData => {
      return prevData.map(prevLot => {
        if (prevLot.id !== notification.lotId) {
          return prevLot;
        }

        return {
          ...prevLot,
          priceDetails: {
            ...prevLot.priceDetails,
            currentPrice: notification.amount
          },
          latestBid: {
            ...prevLot.latestBid,
            isCurrentUserBid: isAuthenticated && notification.userId === user.id
          },
          bidsCount: prevLot.bidsCount + 1
        };
      });
    });
  }

  function onEndDateChanged(notification: LotBidEndDateNotification) {
    setLots(prevData => {
      return prevData.map(prevLot => {
        if (prevLot.id !== notification.lotId) {
          return prevLot;
        }

        return {
          ...prevLot,
          endsAtUtc: notification.endsAtUtc
        };
      });
    });
  }

  function onActivated(notification: LotActivatedNotification) {
    setLots(prevData => {
      return prevData.map(prevLot => {
        if (prevLot.id !== notification.lotId) {
          return prevLot;
        }

        return {
          ...prevLot,
          status: "Active",
          endsAtUtc: notification.endsAtUtc
        };
      });
    });
  }

  function onClosed(notification: LotClosedNotification) {
    setLots(prevData => {
      return prevData.map(prevLot => {
        if (prevLot.id !== notification.lotId) {
          return prevLot;
        }

        return {
          ...prevLot,
          status: notification.status
        };
      });
    });
  }

  function onAcceptedTermsAndConditions(auctionId: number) {
    setLots(prevData => {
      return prevData.map(prevLot => {
        if (prevLot.auction.id !== auctionId) {
          return prevLot;
        }

        return {
          ...prevLot,
          auction: {
            ...prevLot.auction,
            currentUserAuctionTermsSignature: {
              signedAtUtc: new Date(),
              auctionId: auctionId,
              userId: user.id
            }
          }
        };
      });
    });
  }

  useLotsHub(lots.map(lot => lot.id), onBidRegistered, onEndDateChanged, onActivated, onClosed);

  return <>
    {
      lots.map((lot) => (
        <LotSummaryCard key={lot.id} lot={lot} onAcceptedTermsAndConditions={() => onAcceptedTermsAndConditions(lot.auction.id)} />
      ))
    }
  </>;
};

export default LotsListView;
