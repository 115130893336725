import _ from "lodash";

const useSlug = () => {

  const slugify = (text: string, hash: string | number): string => {
    let trimmedText = text;
    if (text.length > 50) {
      trimmedText = text.substring(0, 50);
      if (trimmedText.includes(" ")) {
        trimmedText = trimmedText.substring(0, Math.min(trimmedText.length, trimmedText.lastIndexOf(" ")))
      }
    }
    return _.kebabCase(`${trimmedText}-${hash}`);
  };

  const getSlugHash = (slug?: string): number | undefined => {
    return (slug && parseInt(slug.split('-').pop())) || undefined;
  };

  return { slugify, getSlugHash };
}

export { useSlug };
