import { useAuth } from "api/useAuth";
import axios from "axios";
import { Button } from "flowbite-react";
import { useToast } from "hooks/useToast";

export default function PasswordChange() {
  const { user } = useAuth();
  const { successToast } = useToast();
  const auth0ChangePasswordUrl = `https://${process.env.REACT_APP_AUTH_DOMAIN}/dbconnections/change_password`;
  const canChangePassword = user.sub.startsWith('auth0|');

  const requestPasswordChange = async () => {
    const passwordChangeRequestBody = {
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      connection: process.env.REACT_APP_AUTH_CONNECTION_NAME,
      email: user.email
    };
    await axios.post(auth0ChangePasswordUrl, passwordChangeRequestBody);

    successToast("Ți-am trimis un e-mail cu pașii necesari pentru schimbarea parolei!")
  };

  return (
    <div className="w-full">

      <p className="mx-2 text-gray-700">
        {canChangePassword
          ? 'Dacă dorești schimbarea parolei, apasă butonul următor și vei primi un e-mail cu pașii necesari procesului.'
          : 'Te-ai autentificat folosind o platformă socială (Google, Facebook, etc.). Dacă dorești să îți schimbi parola, trebuie să apelezi la funcționlitatea oferită de platforma aleasă.'
        }
        <br /> În cazul în care întâmpini probleme, nu ezista să ne scrii un e-mail la adresa <a className="decoration-600 dark:decoration-500 inline font-medium text-brand-600 underline decoration-solid underline-offset-2 hover:no-underline dark:text-brand-500" href="mailto:contact@licitas.ro">contact@licitas.ro.</a>
      </p>

      {canChangePassword &&
        <div className="mt-4 w-full md:w-[75%] flex justify-start items-start">
          <Button
            className="w-[75%] md:w-[50%]"
            color="brand"
            size="lg"
            onClick={() => requestPasswordChange()}>
            Solicită schimbarea parolei
          </Button>
        </div>}
    </div>
  );
}
