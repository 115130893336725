import axios from 'axios';

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/`,
  paramsSerializer: {
    indexes: true
  }
});

const authorized = (token: string) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return http;
}

export { authorized, http as default };

