import { User } from "api/models/user";
import { useAuth } from "api/useAuth";
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

const InvalidUserModal = (
  {
    show,
    isAuthenticated,
    user,
    onCloseModal = () => { }
  }: {
    show: boolean,
    isAuthenticated: boolean,
    user?: User
    onCloseModal: () => void
  }) => {
  const { login } = useAuth();
  const navigate = useNavigate();

  let modalText = '';
  let actionButton: JSX.Element;
  let showDismissButton = true;

  if (!isAuthenticated || !user) {
    modalText = "Pentru a putea licita este nevoie să te autentifici!";
    actionButton = <Button color="brand" onClick={() => login()}>Intră în cont</Button>;
  }
  else {
    switch (user.status) {
      case "Created": {
        modalText = "Pentru a putea licita este nevoie să îți confirmi contul. Verifică-ți email-ul!";
        actionButton = <Button color="brand" onClick={() => onCloseModal()}>Am înțeles</Button>;
        showDismissButton = false;
        break;
      }
      case "Confirmed": {
        modalText = "Pentru a putea licita este nevoie să ne trimiți cateva informații despre compania ta pentru a le valida.";
        actionButton = <Button color="brand" onClick={() => navigate("/onboarding")}>Completează formularul</Button>;
        break;
      }
      case "ValidationInProgress": {
        modalText = "Datele tale sunt analizate, revenim în cel mai scurt timp!";
        actionButton = <Button color="brand" onClick={() => onCloseModal()}>Am înțeles</Button>;
        showDismissButton = false;
        break;
      }
      case "Rejected": {
        modalText = "Ne pare rău, nu poți licita. Datele tale nu au trecut de analiză.";
        actionButton = <Button color="brand"><a href="mailto:contact@licitas.ro">Află de ce</a></Button>;
        showDismissButton = false;
        break;
      }
      case "Blocked": {
        modalText = "Ne pare rău, nu poți licita. Contul tău a fost blocat.";
        actionButton = <Button color="brand"><a href="mailto:contact@licitas.ro">Află de ce</a></Button>;
        showDismissButton = false;
        break;
      }
    }
  }

  return <Modal dismissible size="md" popup show={show} onClose={onCloseModal}>
    <Modal.Header />
    <Modal.Body>
      <div className="text-center">
        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400" />
        <h3 className="mb-5 text-lg font-normal text-gray-500 ">
          {modalText}
        </h3>
        <div className="flex justify-center gap-4">
          {actionButton}
          {showDismissButton &&
            <Button color="gray" onClick={() => onCloseModal()}>
              Mai târziu
            </Button>
          }
        </div>
      </div>
    </Modal.Body>
  </Modal>

}

export default InvalidUserModal;