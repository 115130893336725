const TermsAndConditionsPage = () => {
  return <>
    <div className="flex flex-col mx-auto w-full">
      <h1 className="text-4xl font-bold mb-4">Termeni și condiții generale ale LICITAS AUCTIONS SRL pentru Cumpărători</h1>
      <p className="mb-2">Termenii și condițiile generale se aplică relației juridice dintre Cumpărător/Utilizator, pe de o parte, și LICITAS AUCTIONS SRL., care operează sub numele "LICITAS AUCTIONS", cu sediul social în Suceava, Romania, Strada Traian Vuia nr, 15, 720021, pe de altă parte, atunci când utilizează Platforma web, participă la o licitație și plasează o ofertă pentru un lot.</p>
      <p className="mb-2">Acești Termeni și condiții generale se aplică, de asemenea, relației juridice dintre Cumpărător și Vânzător în cazul în care se încheie un contract de cumpărare prin intermediul Platformei web.</p>
      <p className="mb-2">LICITAS AUCTIONS SRL și/sau societățile sale afiliate (mentionate in Anexa 1), denumite în continuare "LICITAS AUCTIONS", sunt furnizori internaționali de servicii de top în domeniul utilajelor uzate, care oferă soluții personalizate pentru unitățile de producție, producătorii de utilaje și partenerii lor de vânzări, bănci și administratori judiciari, lichidatori si executori judecatoresti. Portofoliul de servicii include în special rapoarte de expertiză, achiziții, comercializarea comenzilor, licitații online, depozitare, dezmembrare, relocare, precum și combinații individuale dintre aceste servicii.</p>
      <p className="mb-2">LICITAS AUCTIONS oferă un spațiu de tranzacționare pe internet ("online"), care constă în esență dintr-o bază de date, o Platforma web și diverse sisteme de programare a datelor (denumite în continuare: "Platforma LICITAS AUCTIONS") prin intermediul cărora sunt comercializate mașini, dispozitive și alte bunuri excedentare, imobile, automobile și stocuri (denumite în continuare: "obiecte").</p>
      <p className="mb-2">În plus, LICITAS AUCTIONS oferă clienților săi sprijin în comercializarea Obiectelor prin vânzări tradiționale și alte servicii de brokeraj și sprijin în afara Platformei LICITAS AUCTIONS ("offline").</p>
      <p className="mb-2">Aceasta Platforma web este operat de “LICITAS AUCTIONS”. Termenii și condițiile specifice de licitație vor fi implementate separat pentru licitațiile respective cu societățile afiliate.</p>
      <h2 className="text-lg font-bold mt-8 mb-4">Definiții - condiții de utilizare a Platformei LICITAS AUCTIONS</h2>
      <p className="mb-2">Cu excepția cazului în care se prevede altfel, Termenii de utilizare înseamnă:</p>
      <p className="mb-2"><b>Cont</b>: Combinația dintre un nume de utilizator și o parolă care permite unui Utilizator, să acceseze și să participe la Licitație prin conectarea la Platforma;</p>
      <p className="mb-2"><b>Preluare</b>: Preluarea efectivă a unui Lot către Cumpărător sau agentul acestuia într-o perioadă de timp stabilită de LICITAS AUCTIONS, prin care Cumpărătorul ridică lotul achiziționat de la locația specificată de LICITAS AUCTIONS. În cazul în care livrarea are loc prin expedierea Lotului de către Vânzător, livrarea efectivă este momentul în care Cumpărătorul primește obiectul achiziționat;</p>
      <p className="mb-2"><b>Termeni și condiții generale</b>: Acești termeni și condiții;</p>
      <p className="mb-2"><b>Ofertă automată (auto-bid)</b>: O Ofertă în care Utilizatorul indică prețul maxim pe care este dispus să îl plătească pentru un Lot. După o supraofertă din partea unui terț, sistemul de licitație a Platformei web plasează automat oferta minimă (pas minim de ofertare) în numele utilizatorului, atât timp cât nu a fost atins prețul maxim indicat de utilizator. Această acțiune automată poate fi dezactivată de către Utilizator doar prin plasarea unei oferte statice mai mare decat oferta automată;</p>
      <p className="mb-2"><b>Ofertă</b>: Orice sumă licitată de Utilizator în cadrul unei Licitații pentru un Lot, la care se adaugă, printre altele, Comision Platforma LICITAS AUCTIONS, TVA (daca se aplica), costuri suplimentare (daca este cazul), fiind o ofertă irevocabilă a Utilizatorului de a cumpăra un Lot pentru suma licitată;</p>
      <p className="mb-2"><b>Mandatar</b>: Un lichidator numit de o instanță, care a obținut gestionarea și dispunerea activelor aparținând unei averi de faliment;</p>
      <p className="mb-2"><b>Transfer de proprietate</b>: Transferul de proprietate al bunurilor și, prin urmare, transferul riscului unui Lot are loc în momentul Achiziției;</p>
      <p className="mb-2"><b>Achizitie</b>:</p>
      <p className="mb-2"><b>Utilizator</b>: Cel care, prin Înregistrare, și-a creat Contul pentru utilizarea Platformei, pentru a putea participa, printre altele, la Licitație;</p>
      <p className="mb-2"><b>Acordul pentru Utilizator</b>: Acordul care urmează să fie încheiat între LICITAS AUCTIONS și Utilizator la crearea Contului și care conține Termenii și Condițiile pentru Utilizator;</p>
      <p className="mb-2"><b>Bunuri</b>: unul sau mai multe bunuri în sensul articolului Codului civil românesc;</p>
      <p className="mb-2"><b>Lot</b>: unul sau mai multe Bunuri care vor fi scoase la licitație individual sau în comun, sub un singur număr de lot la o Licitație;</p>
      <p className="mb-2"><b>Zi de vizionare</b>: O perioadă de timp stabilită de LICITAS AUCTIONS în timpul căreia Utilizatorul are posibilitatea de a vedea și examina Bunurile oferite la Licitație înainte de încheierea Contractului de vânzare;</p>
      <p className="mb-2"><b>Contract de vânzare</b>: Acordul dintre Vânzător sau Client/comitant, pe de o parte, și Cumpărător, pe de altă parte, privind vânzarea și cumpărarea unuia sau mai multor Loturi;</p>
      <p className="mb-2"><b>Prețul de cumpărare</b>: Valoarea celei mai mari Oferte alocate plus comision Platforma pentru această Ofertă, costuri suplimentare (daca este specificat), și TVA-ul datorat;</p>
      <p className="mb-2"><b>Cumpărător</b>: Utilizatorul a cărui Ofertă a fost acceptată și căruia i-a fost alocat un Lot prin Alocare;</p>
      <p className="mb-2"><b>Comitent</b>: Persoana fizică sau juridică care a însărcinat LICITAS AUCTIONS să intermedieze vânzarea unuia sau mai multor Bunuri, în nume propriu și pe cont propriu (în calitate de Vânzător) sau în numele și pe contul unei terțe părți, de exemplu, Vânzătorul sau executorul judecătoresc;</p>
      <p className="mb-2"><b>Licitație publică</b>: O licitație condusă de o casă de licitații și supravegheată de un notar sau de un executor judecătoresc, la care cumpărătorul poate participa personal la licitație sau are posibilitatea de a face acest lucru în cadrul sensul Codului civil românesc, la care <i>Utilizatorul</i> cu <i>Oferta</i> pe baza căreia se încheie <i>Contractul de cumpărare</i> prin <i>Alocare</i> se angajează să cumpere <i>Loturile</i>;</p>
      <p className="mb-2"><b>Comisioane</b>: Costuri (de licitație) percepute de Vanzator reprezentand un procent specificat în Termenii și Condițiile Speciale față de Ofertă, la care se adaugă TVA;</p>
      <p className="mb-2"><b>Date cu caracter personal</b>: Datele furnizate de Utilizator inclusiv numele/CNP/adresa/datele de reședință și adresa de e-mail - în cazul persoanelor fizice și nume firma/CUI/adresa/cod de înregistrare fiscală - în cazul persoanelor juridice;</p>
      <p className="mb-2"><b>Înregistrare</b>: Înregistrarea Utilizatorului pe Platforma pentru a crea un Cont;</p>
      <p className="mb-2"><b>Termeni și condiții specifice de licitație</b>: Termeni si conditii care apar În plus față de Termenii și Condițiile generale, aplicabile participării la o Licitație, așa cum sunt menționate pe Platforma web pentru Licitația respectivă;</p>
      <p className="mb-2"><b>Ofertă statică</b>: Într-o licitație statică, Utilizatorul face o Ofertă sub forma unei sume fixe pentru un Lot;</p>
      <p className="mb-2"><b>Alocarea</b>: Declarația explicită făcută de LICITAS AUCTIONS (în numele Vânzătorului) către Utilizator că Lotul sau o combinație de Loturi pentru care Utilizatorul a depus o Ofertă a fost alocat Utilizatorului, astfel stabilind Contractul de vânzare. Această declarație constă în trimiterea unei notificări de către LICITAS AUCTIONS în sensul că Utilizatorul trebuie să plătească Prețul de achiziție și reprezinta o acceptare în numele Vânzătorului în sensul din Codul civil românesc;</p>
      <p className="mb-2"><b>LICITAS AUCTIONS</b>: LICITAS AUCTIONS SRL, care desfășoară activități comerciale sub denumirile "LICITAS AUCTIONS", înregistrată în Registrul Comerțului al Camerei de Comerț sub numărul J2024047118003, cu Codul Unic de Înregistrare RO50995017 și codul EUID ROONRC.J2024047118003 cu sediul social în județul Suceava, oraș Suceava, strada Traian Vuia numărul 15, 720021, și/sau filiala sau filialele sale sau societățile surori;</p>
      <p className="mb-2"><b>Licitație</b>: Licitația online prin intermediul Platformei web pentru vânzarea unui lot, inclusiv o licitație publică;</p>
      <p className="mb-2"><b>Vânzător</b>: Cel care oferă spre vânzare unul sau mai multe Loturi la Licitație;</p>
      <p className="mb-2"><b>Platforma web</b>: Platforma web www.licitas.ro; Platforma de licitație online, aplicația LicitasAuctions și sistemele care stau la baza acesteia.</p>
      <p className="mb-2"><b>Acceptarea Termenilor și Condițiilor</b>: Prin accesarea sau utilizarea Platformei și a serviciilor oferite de Licitas.ro, confirmați că ați citit, înțeles și că sunteți de acord să respectați Termenii și Condițiile Generale prezentate aici. Dacă nu sunteți de acord cu acești termeni, vă rugăm să nu utilizați Platforma și serviciile noastre. Compania își rezervă dreptul de a modifica sau actualiza Termenii și Condițiile la un anumit interval de timp, fără notificări prealabile. Utilizarea continuă a Platformei după astfel de modificări constituie acceptul dumneavoastră al Termenilor și Condițiilor actualizați.</p>
      <h2 className="text-lg font-bold mt-8 mb-4">A. Termeni și condiții generale pentru toate contractele</h2>
      <h2 className="font-bold mb-2">Articolul 1. Generalități</h2>
      <p className="mb-2">Articolul 1.1. LICITAS AUCTIONS este un intermediar, care operează o Platformă online pentru a vinde Loturi, fie prin licitație, fie în mod privat, în numele, pe seama și pe riscul Beneficiarului. LICITAS AUCTIONS încheie un Acord de mediere cu Beneficiarul, prin care Beneficiarul însărcinează LICITAS AUCTIONS (i) să medieze în realizarea unuia sau mai multor Acorduri de vânzare între Vânzător și Cumpărător(i) prin intermediul unei Licitații, (ii) să medieze în cazul plângerilor Utilizatorului (Utilizatorilor)/Cumpărătorului (Cumpărătorilor) și (iii) să desfășoare alte activități.</p>
      <p className="mb-2">Articolul 1.2. LICITAS AUCTIONS nu devine în niciun moment proprietarul unui Lot care urmează să fie vândut și nu este parte la Contractul de vânzare, drept urmare, răspunderea LICITAS AUCTIONS este limitată la cazurile apărute, așa cum sunt descrise la articolul 21.</p>
      <p className="mb-2">Articolul 1.3. În plus față de Termenii și condițiile generale, LICITAS AUCTIONS poate declara aplicabile Termeni și condiții specifice de licitație. Aceștia sunt acceptați de către Utilizator atunci când plasează o Ofertă. În caz de contradicție reciprocă, dispozițiile din Termenii și condițiile specifice ale licitației prevalează asupra dispozițiilor din Termenii și condițiile generale.</p>
      <h2 className="font-bold mb-2">Articolul 2. Domeniul de aplicare</h2>
      <p className="mb-2">Articolul 2.1. Aceste condiții generale de afaceri Licitas Auctions SRL se aplică tuturor tipurilor de livrări și servicii pe care Licitas Auctions SRL le oferă clienților săi online (denumite în continuare: „Servicii online”) și offline (denumite în continuare: „Servicii offline”) sau pe care le primește de la clienții săi; Serviciile online și Serviciile offline sunt denumite împreună „Servicii Licitas Auctions SRL”. Dacă există un contract sau mai multe contracte între Licitas Auctions SRL și Client, aceste condiții generale de afaceri Licitas Auctions SRL vor face parte din respectivul/respectivele contract(e) și se vor aplica, cu excepția cazurilor în care s-au încheiat acorduri contrare. Pentru licitațiile offline și pentru servicii speciale (consultanță, evaluări, inspecții, livrări) se vor aplica condiții generale de afaceri separate, care pot face referire la aceste condiții generale de afaceri Licitas Auctions SRL.</p>
      <p className="mb-2">Articolul 2.2. Orice termeni și condiții ale Clientului sau dispoziții ale Clientului care modifică contractul sunt respinse prin prezenta; acestea vor fi efective doar dacă Licitas Auctions SRL acceptă includerea lor în scris.</p>
      <p className="mb-2">Articolul 2.3. Aceste condiții generale Licitas Auctions SRL se aplică exclusiv antreprenorilor în sensul din Codul Civil Romanesc, persoanelor juridice de drept public sau fondurilor speciale de drept public; acestea sunt denumite în continuare „Clienți”. Alte persoane pot utiliza serviciile oferite de Licitas Auctions SRL doar cu acordul expres al Licitas Auctions SRL.</p>
      <h2 className="font-bold mb-2">Articolul 3. Confidențialitatea</h2>
      <p className="mb-2">LICITAS AUCTIONS va utiliza datele dvs. (personale) în conformitate cu prevederile Declarației de confidențialitate (Politica de confidentialitate) de pe Platforma web. Utilizatorul trebuie să consulte întotdeauna ultimele modificări ale Declarației de confidențialitate atunci când vizitează Platforma web. LICITAS AUCTIONS înregistrează Datele personale obținute de către aceasta într-o evidență administrativă păstrată în acest scop. Prin Înregistrarea pe Platforma, Utilizatorul își dă acordul pentru utilizarea și stocarea Datelor Personale înregistrate.</p>
      <h2 className="font-bold mb-2">Articolul 4. Acordul de utilizare</h2>
      <p className="mb-2">Articolul 4.1. Acordulde utilizare se încheie prin înregistrarea pe Platforma web, după care LICITAS AUCTIONS confirmă înregistrarea prin e-mail către utilizator la adresa de e-mail furnizată de acesta . Acordul de utilizare permite Utilizatorului, printre altele, să urmărească evoluția Licitațiilor prin intermediul Platformei web și să plaseze o Ofertă la o Licitație. Acordul de utilizare este netransferabil de către Utilizator.</p>
      <p className="mb-2">Articolul 4.2. Acordul de utilizare se încheie prin înregistrarea pe Platforma web, după care LICITAS AUCTIONS confirmă înregistrarea prin e-mail către utilizator la adresa de e-mail furnizată de acesta la . Contractul de utilizare permite Utilizatorului, printre altele, să urmărească evoluția Licitațiilor prin intermediul Platformei web și să plaseze o Ofertă la o Licitație. Acordul de utilizare este netransferabil de către Utilizator.</p>
      <p className="mb-2">Articolul 4.3. Atunci când utilizează Platforma web, utilizatorul trebuie să se comporte în mod corespunzător și să nu provoace daune Platformei web, lui LICITAS AUCTIONS, vânzătorului sau altor utilizatori ai acestuia. Utilizatorul este răspunzător pentru toate acțiunile care au loc pe Platforma web după ce accesul a fost obținut (de către Utilizator) prin intermediul parolei și/sau al numelui de utilizator al Utilizatorului.</p>
      <p className="mb-2">Articolul 4.4. Numele de utilizator și parola utilizatorului sunt strict personale și nu pot fi transferate de către utilizator unor terțe părți. Utilizatorul/Cumpărătorul este răspunzător pentru toate acțiunile care au loc la o licitație și care rezultă din utilizarea parolei și/sau a numelui de utilizator, inclusiv în cazul utilizării abuzive de către o terță parte. În cazul în care Utilizatorul se teme că o altă persoană îi cunoaște parola, Utilizatorul trebuie să informeze imediat LICITAS AUCTIONS despre acest lucru, iar Utilizatorul va permite LICITAS AUCTIONS să îi blocheze contul. LICITAS AUCTIONS nu este responsabil pentru nicio daună care rezultă din utilizarea abuzivă și/sau din folosirea necorespunzătoare a contului utilizatorului.</p>
      <p className="mb-2">Articolul 4.5. Utilizatorul garantează acuratețea și caracterul complet al datelor personale furnizate cu ocazia înregistrării. În cazul în care Datele personale se modifică în orice moment, Utilizatorul este obligat să informeze LICITAS AUCTIONS cu privire la aceste modificări prin e-mail.</p>
      <p className="mb-2">Articolul 4.6. LICITAS AUCTIONS își rezervă dreptul de a refuza înregistrarea în orice moment din motive proprii și/sau de a rezilia unilateral Acordul de utilizare și/sau de a adăuga condiții suplimentare la înregistrare.</p>
      <p className="mb-2">Articolul 4.7. Utilizatorului îi este interzisă duplicarea Platformei sau a oricărei părți a acestuia sau punerea la dispoziție a acestuia (prin deep linking sau în alt mod) fără permisiunea scrisă prealabilă a LICITAS AUCTIONS.</p>
      <p className="mb-2">Articolul 4.8. Toate drepturile de proprietate intelectuală privind Contul și Platforma web, inclusiv dezvoltarea ulterioară a acestora, precum și baza de date a Loturilor, aparțin exclusiv LICITAS AUCTIONS. Utilizatorul/Cumpărătorul are doar un drept limitat, neexclusiv, ne-(sub-)licențiat și netransferabil de a utiliza Contul și Platforma web. Dreptul de utilizare a Contului și a Platformei web de către Utilizator/Cumpărător poate fi reziliat de către LICITAS AUCTIONS în orice moment. LICITAS AUCTIONS poate, de asemenea, să stabilească condiții suplimentare pentru dreptul de utilizare al Utilizatorului/Cumpărătorului.</p>
      <p className="mb-2">Articolul 4.9. Platforma web și Contul și toate funcționalitățile aplicabile sunt oferite "așa cum sunt". LICITAS AUCTIONS nu oferă nicio garanție cu privire la păstrarea sau caracterul neschimbat al anumitor funcționalități ale Platformei web sau ale Contului.</p>
      <p className="mb-2">LICITAS AUCTIONS va rezolva orice defecte privind Platforma web și Contul în cel mai scurt timp posibil. LICITAS AUCTIONS are în orice moment dreptul, fără notificare prealabilă, de a pune (temporar) în afara utilizării accesul la Cont și la Platforma web sau de a limita utilizarea acestuia, în măsura în care acest lucru este necesar pentru întreținere sau pentru a putea efectua actualizări, versiuni, ajustări sau îmbunătățiri. Acest lucru nu implică niciodată un drept la despăgubiri (pentru daune) pentru LICITAS AUCTIONS și/sau Client față de Utilizator/Cumpărător.</p>
      <h2 className="font-bold mb-2">Articolul 5. Condiții de participare la licitație</h2>
      <p className="mb-2">Articolul 5.1. Utilizatorul trebuie să fie major și autorizat să îndeplinească acte juridice. Participarea la o Licitație nu este permisă în cazul în care Utilizatorul nu este autorizat să efectueze acte juridice sau dacă este în incapacitate.</p>
      <p className="mb-2">Articolul 5.2. Pentru a putea participa la o Licitație, pot fi stabilite condiții pe Platforma web, cum ar fi condiția ca mai întâi să aibă loc o Înscriere din partea Utilizatorului, cu plata sau nu a unei sume de abonament menționate pe Platforma. LICITAS AUCTIONS poate, dar nu este obligat să ofere Utilizatorului posibilitatea ca, după o Înscriere, să plaseze o Ofertă pentru Lot.</p>
      <p className="mb-2">Articolul 5.3. La prima solicitare și spre satisfacția LICITAS AUCTIONS, Utilizatorul trebuie să demonstreze că este solvabil, în caz contrar LICITAS AUCTIONS poate refuza participarea unui Utilizator la o Licitație.</p>
      <h2 className="font-bold mb-2">Articolul 6. Descrierea Loturilor</h2>
      <p className="mb-2">Articolul 6.1. Descrierea unui lot a fost făcută în cunoștință de cauză, fără ca LICITAS AUCTIONS și/sau vânzătorul să garanteze acuratețea sau caracterul complet al acesteia. În descrierea unui Lot pe Platforma, LICITAS AUCTIONS depinde de informațiile despre un Lot furnizate de Vânzător sau de terțe părți. LICITAS AUCTIONS nu are nicio cunoștință despre un Lot care este scos la licitație și nici despre orice drepturi (limitate) (ale unor terțe părți), taxe sau sarcini atașate Lotului. LICITAS AUCTIONS nu are posibilitatea de a verifica dacă informațiile furnizate către LICITAS AUCTIONS de către mandatar/vânzător cu privire la un lot în scopul unei licitații sunt corecte și/sau complete/vânzătorul/executor/lichidator este responsabil pentru descrierea unui lot, înțelegând că nu este întotdeauna posibil ca un/vânzător să furnizeze informații complete în scopul descrierii unui lot și a oricăror posibile particularități.</p>
      <p className="mb-2">Articolul 6.2. În cazul în care LICITAS AUCTIONS arată sau furnizează o mostră, un model sau un exemplu, acest lucru este întotdeauna doar cu titlu indicativ. Calitățile unui lot care urmează să fie livrat pot să difere de eșantionul, modelul sau exemplul prezentat. Utilizatorii și Cumpărătorii nu pot pretinde niciun fel de pretenții sau drepturi din descrierile, mostrele, modelele, exemplele și alte informații menționate.</p>
      <p className="mb-2">Articolul 6.3. LICITAS AUCTIONS și Vânzătorul depun eforturi pentru cea mai mare acuratețe și claritate posibilă a descrierii unui Lot pe Platforma, a Termenilor și Condițiilor specifice de licitație în cataloagele de licitație, alte broșuri de licitație, reclame și altele asemenea, fără a accepta nicio răspundere pentru daunele rezultate din descrieri incorecte sau incomplete sau alte daune.</p>
      <p className="mb-2">Articolul 6.4. LICITAS AUCTIONS are dreptul de a corecta inexactitățile din declarațiile verbale și scrise făcute de aceasta sau în numele său și erorile făcute (fie că sunt sau nu în timpul unei Licitații), fără ca Utilizatorul sau Cumpărătorul să poată beneficia de vreun drept din acestea. LICITAS AUCTIONS nu este răspunzător pentru inexactitățile sau caracterul incomplet al descrierii unui lot. Utilizatorul și/sau Cumpărătorul nu are dreptul de a retrage sau de a revoca o Ofertă sau de a dizolva Contractul de vânzare dacă se dovedește că un Lot nu corespunde descrierii sale.</p>
      <p className="mb-2">Articolul 6.5. Țevile, cablurile și/sau alte conexiuni pentru energie, control sau producție, care se află pe/într-un Lot, vor fi vândute, cu excepția cazului în care se prevede altfel în mod explicit în Termenii și Condițiile specifice ale Licitației, numai până la prima supapă, racord sau marcă aplicată. Racordurile subterane sau de zidărie nu aparțin niciodată unui Lot, cu excepția cazului în care se prevede în mod explicit altfel în Termenii și condițiile specifice ale licitației.</p>
      <h2 className="font-bold mb-2">Articolul 7. Obligația de investigație Utilizator/cumpărător</h2>
      <p className="mb-2">Articolul 7.1. Dacă a fost stabilită o zi de vizionare, aceasta este menționată pe Platforma web și în Termenii și condițiile specifice de licitație. Utilizatorul are atunci posibilitatea de a examina un lot înainte de a depune o ofertă în Ziua de vizionare a licitației respective.</p>
      <p className="mb-2">Articolul 7.2. Utilizatorul/Cumpărătorul este obligat să examineze în mod corespunzător Loturile (achiziționate). În acest sens, Utilizatorul/Cumpărătorul trebuie să verifice dacă un Lot este conform cu Contractul de achiziție. De asemenea, având în vedere faptul că Loturile (achiziționate) sunt Bunuri folosite și nu Bunuri noi, acestea sunt achiziționate în starea în care se află în momentul Acceptării, așa cum este descris în articolele 17 și 18 din Termenii și Condițiile generale, iar defectele sau lipsurile trebuie raportate către LICITAS AUCTIONS sau către Vânzător cel târziu la momentul Acceptării.</p>
      <p className="mb-2">Articolul 7.3. Utilizatorul care intră în clădiri și în incinta clădirilor într-o zi de vizionare o face pe riscul propriu. Utilizatorul este obligat să urmeze instrucțiunile lui LICITAS AUCTIONS, ale mandantului, ale vânzătorului și/sau ale terților angajați de LICITAS AUCTIONS sau de vânzător. LICITAS AUCTIONS, Administratorul principal și Vânzătorul nu sunt răspunzători pentru daunele suferite de Utilizator prin intrarea în clădiri și terenuri într-o zi de vizionare.</p>
      <h2 className="font-bold mb-2">Articolul 8. Licitația</h2>
      <p className="mb-2">Articolul 8.1. Durata unei Licitații este indicată pe Platforma. LICITAS AUCTIONS își rezervă dreptul de a anula o Licitație, de a o încheia mai devreme sau de a o prelungi. În cazul unei defecțiuni tehnice a Platformei web, din cauza căreia acesta nu este accesibil în totalitate și/sau nu este accesibil tuturor Utilizatorilor, LICITAS AUCTIONS are dreptul (dar nu și obligația) de a prelungi Licitația.</p>
      <p className="mb-2">Articolul 8.2. Utilizatorul este obligat să urmeze instrucțiunile și indicațiile date de către sau în numele LICITAS AUCTIONS în cadrul unei Licitații.</p>
      <p className="mb-2">Articolul 8.3. Prin înregistrarea sa, utilizatorul se declară familiarizat și de acord cu condițiile speciale ale unei licitații (online) și cu defecțiunile tehnice care pot apărea. Prin prezenta, LICITAS AUCTIONS exclude orice răspundere pentru orice fel de daune, directe și/sau indirecte, apărute în orice mod, inclusiv, dar fără a se limita la daunele care rezultă din utilizarea Platformei web, cu excepția cazului în care a existat o neglijență deliberată sau conștientă din partea LICITAS AUCTIONS. Mai precis, LICITAS AUCTIONS nu își asumă nicio răspundere pentru niciun fel de daune care rezultă din:</p>
      <ul className="list-disc ml-8 mb-2">
        <li>acțiunile întreprinse de utilizator care ar fi fost determinate de informațiile postate pe Platforma web;</li>
        <li>incapacitatea de a utiliza (pe deplin) Platforma web și/sau orice altă defecțiune a Platformei web;</li>
        <li>incapacitatea de a depune o ofertă la timp;</li>
        <li>nerespectarea de către un lot a specificațiilor menționate pe Platforma web;</li>
        <li>faptul că informațiile de pe Platforma web sunt incorecte, incomplete sau neactualizate;</li>
        <li>defecțiuni sau defecte ale Platformei web și/sau ale hardware-ului și/sau ale conexiunilor de rețea și/sau ale software-ului Platformei web și/sau ale sistemului subiacent;</li>
        <li>utilizarea ilegală a sistemelor, inclusiv a Platformei web al LICITAS AUCTIONS de către o terță parte;</li>
        <li>acte ale Vânzătorului după ce Utilizatorul a încheiat un Contract de cumpărare cu acesta.</li>
      </ul>
      <p className="mb-2">Articolul 8.4. LICITAS AUCTIONS își rezervă dreptul:</p>
      <ul className="list-disc ml-8 mb-2">
        <li>să nu recunoască sau să refuze o Ofertă ca atare;</li>
        <li>să excludă un Utilizator de la Licitație;</li>
        <li>să grupeze Bunurile în Loturi, să împartă Loturile, să facă modificări la (compoziția) Loturilor și să elimine Loturile sau Bunurile din Licitație.</li>
        <li>să înceteze, să reia, să prelungească sau să anuleze o Licitație;</li>
        <li>să schimbe modul în care se desfășoară Licitația;</li>
        <li>să corecteze greșelile făcute de LICITAS AUCTIONS sau de Vânzător în cadrul Licitației și/sau al Alocării, fără ca un Utilizator să poată face uz de această greșeală și/sau să poată deriva drepturi din aceasta;</li>
        <li>să ia alte măsuri, în opinia sa, necesare.</li>
      </ul>
      <p className="mb-2">Articolul 8.5. LICITAS AUCTIONS este autorizat, înainte și/sau în timpul participării la o Licitație și/sau a ofertei pentru un Lot, să solicite unui Utilizator să facă o plată în avans, sau o altă formă de garanție înainte de a proceda la Alocarea (în numele Vânzătorului) sau să coopereze (în numele Vânzătorului) la Achiziție.</p>
      <h2 className="font-bold mb-2">Articolul 9. Licitația publică</h2>
      <p className="mb-2">Articolul 9.1. Pentru anumite Licitații, LICITAS AUCTIONS oferă Utilizatorului posibilitatea, în contextul unei Licitații publice, de a face o ofertă în prezența unui licitator într-o sală fizică, pe lângă depunerea unei Oferte online prin intermediul Platformei web. Pe Platforma web și în Termenii și Condițiile specifice ale Licitației se indică, de asemenea, faptul că este vorba despre o Licitație publică, precum și locul în care Utilizatorul poate fi prezent în persoană sau modul în care Utilizatorul trebuie să se înregistreze.</p>
      <p className="mb-2">Articolul 9.2. O Ofertă făcută în timpul unei Licitații publice va fi considerată a fi o Ofertă fără taxe de licitație și TVA.</p>
      <p className="mb-2">Articolul 9.3. Pentru a putea licita în cadrul unei Licitații publice la fața locului, un Utilizator trebuie să fie prezent la o locație care va fi indicată de LICITAS AUCTIONS la ora indicată de LICITAS AUCTIONS și să urmeze instrucțiunile Vânzătorului de licitații sau ale LICITAS AUCTIONS.</p>
      <p className="mb-2">Articolul 9.4. Pe lângă posibilitatea de a plasa o Ofertă într-o locație fizică, Utilizatorul poate, de asemenea, să plaseze o Ofertă într-o Licitație publică prin intermediul Platformei web.</p>
      <h2 className="font-bold mb-2">Articolul 10. Vânzarea prin executare silită</h2>
      <p className="mb-2">Articolul 10.1. În cazul în care are loc o vânzare de executare silită (licitație de faliment) sau o vânzare prin executare imediată, acest lucru va fi menționat în Condițiile specifice ale licitației sau pe Platforma web.</p>
      <h2 className="font-bold mb-2">Articolul 11. Oferta</h2>
      <p className="mb-2">Articolul 11.1. Utilizatorul poate plasa o Ofertă pentru un Lot pe Platforma. Fiecare Ofertă este necondiționată, irevocabilă și fără nicio rezervare. LICITAS AUCTIONS și/sau Vânzătorul au dreptul de a nu accepta o Ofertă fără a oferi motive.</p>
      <p className="mb-2">Articolul 11.2. O Ofertă trebuie să fie făcută în modul indicat de LICITAS AUCTIONS pe Platforma web. Licitația are loc prin Ofertă statică, Ofertă automată. Sistemul de licitație asigură că, în cazul unei Licitații automate după o supraofertă din partea unui terț, se plasează automat oferta minimă (pas minim de ofertare) în numele utilizatorului, atât timp cât nu a fost atins prețul maxim indicat de utilizator. Această acțiune automata poate fi dezactivată de către Utilizator doar prin plasarea unei oferte statice mai mare decat oferta automata;</p>
      <p className="mb-2">Articolul 11.3. Licitația este întotdeauna o invitație (publică) a vânzătorului de a face o ofertă. În cazul în care un Utilizator face o Ofertă, această Ofertă este considerată a fi o ofertă către Vânzător.</p>
      <p className="mb-2">Articolul 11.4. Dacă un lot este scos la licitație împreună cu unul sau mai multe alte loturi ca o combinație, acest lucru va fi indicat pe pagina individuală a unui lot de pe Platforma web. În acest caz, licitația pentru Loturile individuale ale combinației și după închiderea tuturor Loturilor individuale, poate avea loc numai pentru Lotul combinat. Loturile individuale vor fi alocate sub rezerva atribuirii numai dacă suma totală a celor mai mari oferte alocabile pentru Loturile individuale depășește cea mai mare ofertă alocabilă pentru combinație. Un Lot combinat va fi atribuit numai dacă cea mai mare ofertă alocabilă pentru Lotul combinat depășește suma totală a celor mai mari oferte alocabile pentru Loturile individuale.</p>
      <h2 className="font-bold mb-2">Articolul 12. Alocarea și contractul de cumpărare</h2>
      <p className="mb-2">Articolul 12.1. Contractul de cumpărare este stabilit prin Atribuire. Prin Atribuire, Vânzătorul (prin LICITAS AUCTIONS în virtutea unei împuterniciri în numele Vânzătorului) acceptă Oferta Utilizatorului.</p>
      <p className="mb-2">Articolul 12.2. În termen de 48 de ore de la închiderea Licitației (fără a lua în considerare sâmbetele, duminicile și sărbătorile legale recunoscute în Romania) Cumpărătorul va primi o confirmare prin e-mail în numele Vânzătorului (Vânzătorilor) dacă Acordul de vânzare a fost încheiat, prin trimiterea unui mesaj din partea LICITAS AUCTIONS în sensul că Utilizatorul trebuie să plătească Prețul de achiziție. În cazul în care Utilizatorul nu primește un e-mail în termenele menționate mai sus, înseamnă că Oferta sa nu a fost alocată. LICITAS AUCTIONS poate adjudeca o Ofertă în numele Vânzătorului sub condiție(e) suspensivă(e) sau rezolutorie(e).</p>
      <p className="mb-2">Articolul 12.3. Orice utilizator care depune o ofertă se consideră că a depus oferta în nume propriu și este obligat personal să îndeplinească obligațiile prevăzute în Contractul de cumpărare.</p>
      <p className="mb-2">Articolul 12.4. În afara cazului în care LICITAS AUCTIONS nu este direct vanzatorul, între Vânzător și Cumpărător se încheie un Contract de vânzare. In acest caz LICITAS AUCTIONS nu este parte la un Acord de vânzare. LICITAS AUCTIONS doar mediază în realizarea unui Acord de vânzare.</p>
      <p className="mb-2">Articolul 12.5. În absența unei Licitații, Contractul de vânzare ia naștere în momentul în care o Ofertă din partea unui Utilizator este acceptată de LICITAS AUCTIONS în numele Vânzătorului. În cazul unei astfel de vânzări în afara unei Licitații, se aplică, de asemenea, ceea ce se stipulează în articolele 16 până la 20 din acești Termeni și Condiții Generale. Deoarece în acest caz nu se pune problema vânzării la distanță, Cumpărătorul - în cazul în care Cumpărătorul este un consumator - nu are dreptul legal de retragere.</p>
      <h2 className="font-bold mb-2">Articolul 13. Dreptul de retragere</h2>
      <p className="mb-2">Articolul 13.1. Nu se aplica.</p>
      <h2 className="font-bold mb-2">Articolul 14. Obligația de plată a cumpărătorului</h2>
      <p className="mb-2">Articolul 14.1. LICITAS AUCTIONS trimite o notificare către Cumpărător în care precizează că acesta trebuie să efectueze plata Prețului de achiziție. Cumpărătorul este obligat să efectueze plata Prețului de achiziție în termen de 48 de ore de la expedierea notificării menționate mai sus. Plata trebuie să se facă în euro sau într-o altă monedă indicată de LICITAS AUCTIONS. Cumpărătorul poate plăti numai în regim de liberare prin achitarea sumelor datorate Vânzătorului și LICITAS AUCTIONS. Ulterior, Cumpărătorul va primi o factură în care se va afișa Suprataxa datorată de Cumpărător către LICITAS AUCTIONS și sumele datorate de Cumpărător către Vânzător, toate acestea plus TVA.</p>
      <p className="mb-2">Articolul 14.2. Cumpărătorul trebuie să furnizeze dovada plății către LICITAS AUCTIONS (în numele Vânzătorului) la prima solicitare a LICITAS AUCTIONS.</p>
      <p className="mb-2">Articolul 14.3. În caz de întârziere la plată, Cumpărătorul este în întârziere din punct de vedere juridic față de LICITAS AUCTIONS și Vânzătorul, iar LICITAS AUCTIONS și Vânzătorul au dreptul fiecare la compensarea costurilor rezonabile pentru obținerea unei reglementări extrajudiciare (costuri de colectare), în conformitate cu reglementările legale care normează costurile de colectare.</p>
      <p className="mb-2">Articolul 14.4. La o Licitație, Cumpărătorul trebuie să țină cont de Condițiile specifice ale Licitației și de costurile și taxele pe care Cumpărătorul trebuie să le plătească în plus față de Ofertă, cum ar fi impozitul pe transfer, impozitul pe cifra de afaceri, impozitul pe proprietate, costurile pentru actul de transfer, costurile de finanțare, impozitul auto, costurile pentru eliminarea deșeurilor și costurile administrative. Aceste costuri nu fac parte din Ofertă, ci sunt plătibile în plus de către Cumpărător (către Vânzător).</p>
      <p className="mb-2">Articolul 14.5. Cumpărătorul renunță la orice drept de a-și suspenda obligația de plată și/sau renunță la orice drept de compensare.</p>
      <h2 className="font-bold mb-2">Articolul 15. Preluarea</h2>
      <p className="mb-2">Articolul 15.1. Imediat ce cumpărătorul și-a îndeplinit toate obligațiile de plată, cumpărătorul poate lua în primire Lotul sau Loturile achiziționate la momentul și locul stabilite de LICITAS AUCTIONS în acest scop, pe baza prezentării unui act de identitate corespunzător, adică preluarea. Cumpărătorul este obligat să preia Preluarea cel târziu la data menționată în Termenii și condițiile specifice ale licitației. LICITAS AUCTIONS poate stabili că (un anumit (anumite) Lot(uri) poate (pot) fi ridicat(e) numai după Acceptarea (altui) Lot(e).</p>
      <p className="mb-2">Articolul 15.2. Cumpărătorul însuși este responsabil pentru preluarea corectă și la timp a unui Lot achiziționat. LICITAS AUCTIONS și/sau Vânzătorul este responsabil pentru organizarea și coordonarea momentului de preluare a unui Lot vândut de către Vânzător.</p>
      <p className="mb-2">Articolul 15.3. Cumpărătorul - sau terțul angajat de acesta - este obligat să inspecteze amănunțit un Lot cumpărat la momentul acceptării. Cumpărătorul este obligat să informeze LICITAS AUCTIONS în scris cu privire la orice neajunsuri, defecte și abateri imediat după ce le descoperă. În cazul în care Cumpărătorul nu face acest lucru, Cumpărătorul va suporta consecințele acestora. La Acceptare, Cumpărătorul acceptă un Lot în starea în care se află.</p>
      <p className="mb-2">Articolul 15.4. În cazul în care Cumpărătorul refuză Acceptarea sau este neglijent în furnizarea informațiilor sau instrucțiunilor necesare pentru Acceptare, LICITAS AUCTIONS și/sau Vânzătorul are dreptul (dar nu este obligat) să depoziteze (a) Lotul sau Loturile achiziționate pe cheltuiala și riscul Cumpărătorului și să perceapă de la Cumpărător costurile suportate pentru aceasta, fără a aduce atingere oricăror alte drepturi ale LICITAS AUCTIONS și/sau ale Vânzătorului și fără a aduce atingere obligației Cumpărătorului de a Accepta. În cazul în care acceptarea unui Lot are loc în cele din urmă, Cumpărătorul trebuie să fi plătit în avans costurile administrative, de depozitare și de asigurare suportate, cu un minim de 350 EUR.</p>
      <p className="mb-2">Articolul 15.5. Este responsabilitatea Cumpărătorului să se asigure că sunt îndeplinite toate cerințele, indiferent de natura lor, în ceea ce privește exportul unui Lot într-o țară din UE sau într-o altă țară. Aceasta înseamnă, printre altele, că Cumpărătorul trebuie să respecte legile și reglementările țării respective în ceea ce privește remiterea taxelor și impozitelor și a documentației necesare și, dacă este necesar, să obțină aprobarea necesară. În cazul în care un lot urmează să fie transportat, Vânzătorul și Cumpărătorul sunt cei care trebuie să fie înregistrați pe documentele de transport sau pe documentele vamale de bază ca expeditor, respectiv destinatar al unui lot, și nu LICITAS AUCTIONS.</p>
      <p className="mb-2">Articolul 15.6. Livrarea efectivă, transferul de proprietate și transferul de risc al lotului (loturilor) are loc în momentul achiziției.</p>
      <p className="mb-2">Articolul 15.7. În cazul în care, în contextul Achiziției, este necesară demontarea Loturilor achiziționate, Cumpărătorul trebuie să se ocupe de acest lucru la prima cerere, pe cheltuiala și pe riscul său, într-un mod profesionist. Cumpărătorul nu are dreptul de a demonta Bunurile atașate la Loturi care nu au fost achiziționate de către Cumpărător, decât după obținerea unei permisiuni explicite, în scris, din partea LICITAS AUCTIONS și/sau a Vânzătorului. Cumpărătorul este răspunzător pentru daunele cauzate în timpul sau în contextul preluării Loturilor achiziționate și va despăgubi și compensa LICITAS AUCTIONS și/sau Vânzătorul, la prima solicitare, împotriva pretențiilor unor terțe părți în acest sens.</p>
      <p className="mb-2">Articolul 15.8. Cumpărătorul are nevoie de permisiunea explicită din partea LICITAS AUCTIONS și/sau a Vânzătorului pentru utilizarea propriilor unelte, cum ar fi un stivuitor, în scopul mutării și/sau încărcării unui Lot. Cumpărătorul este conștient de legile și reglementările relevante, cum ar fi Directiva 2006/42/CE privind mașinile, Decretul privind mașinile din Legea privind mărfurile, Decretul privind sănătatea și securitatea în muncă și standardele NEN EN, are o asigurare de răspundere civilă valabilă pentru stivuitor. Cumpărătorul este în orice moment responsabil și răspunzător pentru utilizarea în siguranță a unui stivuitor pe locul de licitație.</p>
      <p className="mb-2">Articolul 15.9. Dacă se constată că un Lot nu poate fi livrat din cauza pretențiilor unor terți sau din faptul că preluarea acestuia ar cauza daune inacceptabile clădirilor sau terenurilor unde se află Lotul, sau dacă preluarea se dovedește a fi foarte dificilă din orice alt motiv, LICITAS AUCTIONS are dreptul de a desface Contractul de Vânzare în numele Vânzătorului. Această desfacere se face prin email (sau un mesaj digital) și/sau prin scrisoare recomandată către Cumpărător, la adresa furnizată de acesta, după care LICITAS AUCTIONS și/sau Vânzătorul nu mai sunt obligați decât la restituirea sumelor deja plătite de Cumpărător către LICITAS AUCTIONS pentru Achiziționarea Lotului/Loturilor.</p>
      <p className="mb-2">Articolul 15.10. Cumpărătorul, căruia i-au fost alocate Lot(uri) care obstrucționează colectarea altor Loturi, este obligat, imediat după primirea email-ului și/sau notificării de la LICITAS AUCTIONS la adresa specificată de Cumpărător, să organizeze colectarea Lotului/Loturilor sale, în caz contrar LICITAS AUCTIONS are dreptul de a aranja această colectare și orice depozitare de către terți pe cheltuiala și riscul Cumpărătorului.</p>
      <p className="mb-2">Articolul 15.11. LICITAS AUCTIONS nu este responsabil pentru îndepărtarea/eliminarea deșeurilor de la locația de ridicare cauzată de Cumpărător. Cumpărătorul este responsabil pentru aceasta, cu excepția cazului în care pentru îndepărtare/eliminare se dă expres comandă la LICITAS AUCTIONS pe cheltuiala Cumpărătorului. Și LICITAS AUCTIONS acceptă această comandă.</p>
      <p className="mb-2">Articolul 15.12. Cumpărătorul care intră în clădiri și pe terenuri pentru Acceptare o face pe propriul său risc. Cumpărătorul este obligat să urmeze instrucțiunile LICITAS AUCTIONS, ale Vânzătorului și/sau ale terțelor părți angajate de LICITAS AUCTIONS sau Vânzător. LICITAS AUCTIONS și Vânzătorul nu sunt responsabili pentru daunele suferite de Cumpărător prin intrarea în clădiri și pe terenuri pentru Preluare.</p>
      <h2 className="font-bold mb-2">Articolul 16. Livrarea Loturilor</h2>
      <p className="mb-2">Articolul 16.1. Contrar prevederilor din Articolele de la 15.1 la 15.12 inclusiv, Achiziția unui Lot se va realiza prin livrarea Lotului de către Vânzător sau un transportator angajat în acest scop la adresa Cumpărătorului, dacă a fost convenit în scris în avans. În acest caz, riscul asociat Lotului/Loturilor trece la Cumpărător de la momentul livrării.</p>
      <p className="mb-2">Articolul 16.2. Un termen de livrare convenit nu este o dată limită, decât dacă se agreează expres altfel în scris.</p>
      <p className="mb-2">Articolul 16.3. Vânzătorul are permisiunea de a livra Lotul/Loturile pe părți.</p>
      <h2 className="font-bold mb-2">Articolul 17. Garanții - Reclamații - Despăgubiri</h2>
      <p className="mb-2">Articolul 17.1. LICITAS AUCTIONS și/sau Vânzătorul nu oferă nicio garanție de niciun fel cu privire la Loturi și la orice pretenții ale unor terțe părți asupra acestora. Cumpărătorul renunță la toate drepturile care nu îi revin în baza legii obligatorii.</p>
      <p className="mb-2">Articolul 17.2. Loturile vor fi vândute "așa cum sunt" în momentul Acceptării, cu toate plăcerile și poverile asociate. LICITAS AUCTIONS și/sau Vânzătorul nu oferă nicio despăgubire pentru defecte vizibile sau ascunse, nici garanții în legătură cu completitudinea, documentația necesară, numerele, funcționarea, utilizabilitatea, vandabilitatea, existența sau inexistența drepturilor sau pretențiilor terțelor părți și/sau posibilitatea de transfer către terți.</p>
      <p className="mb-2">Articolul 17.3. Defectele, de orice natură, așteptările neîmplinite ale Cumpărătorului și/sau ale terților achizitori nu conferă niciun drept la (compensații) și/sau suspendare și/sau compensare (decât dacă legea obligatorie prevede altfel). Se consideră că Cumpărătorul a examinat cu atenție Lotul achiziționat în avans.</p>
      <p className="mb-2">Articolul 17.4. Dacă apar pretenții ale unor terți bazate pe drepturi de proprietate (intelectuală sau alte tipuri), inclusiv retenția titlului, și/sau drepturi ale terților conform Articolelor 3:166-200, 3:201-226, 3:290-295, 6:52-57, 6:271 (după o apelare la desfacerea conform Articolului 6:265) și/sau 7:39-44 din Codul Civil Romanesc, sau alte pretenții ale terților care pot sau nu să fie echivalente cu acestea, Cumpărătorul este obligat să păstreze Loturile relevante, pentru acei terți, sub obligația de a le preda acestor terți la prima solicitare a LICITAS AUCTIONS, sau altfel să facă aranjamente adecvate cu orice părți cu drepturi. Dacă un Lot este returnat nevătămat la prima solicitare a LICITAS AUCTIONS, Cumpărătorul are dreptul, în raport cu Vânzătorul, la rambursarea prețului de achiziție plătit pentru Lotul respectiv, și Contractul de Vânzare privind Lotul este considerat desfăcut, fără ca LICITAS AUCTIONS și/sau Vânzătorul să fie răspunzători pentru orice compensație (pentru daune) față de Cumpărător.</p>
      <p className="mb-2">Articolul 17.5. La prima solicitare, Cumpărătorul desemnează LICITAS AUCTIONS și/sau Vânzătorul împotriva oricărei pretenții a unei terțe părți rezultată din Contractul de Cumpărare încheiat între Vânzător și Cumpărător.</p>
      <p className="mb-2">Articolul 17.6. Clientul garantează că respectă toate dispozițiile legale aplicabile activității sale comerciale, în special reglementările în materie de supraveghere, concurență și protecția datelor, și că încercările de vânzare sau cumpărare inițiate de acesta nu încalcă legi sau drepturi ale terților, în special drepturi de proprietate, drepturi de gaj sau alte drepturi reale, drepturi de brevet, drepturi asupra mărcilor, drepturi de autor sau alte drepturi de proprietate intelectuală. Clientul exonerează Licitas Auctions SRL de orice pretenții ale terților care sunt formulate în legătură cu o încălcare a obligațiilor menționate mai sus de către Client.</p>
      <h2 className="font-bold mb-2">Articolul 18. Dizolvarea contractului de vânzare-cumpărare</h2>
      <p className="mb-2">Articolul 18.1. Vânzătorul poate renunța la vânzarea unui lot și are dreptul de a rezilia sau de a dizolva total sau parțial contractul de cumpărare cu cumpărătorul prin intermediul unei declarații scrise a LICITAS AUCTIONS (pe baza unei împuterniciri din partea vânzătorului) sau a vânzătorului, fără a datora cumpărătorului vreo (compensație) în acest sens, precum și dreptul de a vinde fără întârziere un lot uneia sau mai multor terțe părți, fără a aduce atingere dreptului vânzătorului la o compensație (suplimentară) în acest caz:</p>
      <p className="mb-2">- dacă apare una sau mai multe dintre următoarele situații:</p>
      <ul className="list-disc ml-8 mb-2">
        <li>
          Cumpărătorul nu reușește să își îndeplinească toate obligațiile prevăzute în Contractul de vânzare-cumpărare, inclusiv (dar fără a se limita la) una sau mai multe dintre următoarele obligații și există o neîndeplinire sau îndeplinirea este imposibilă permanent sau temporar:
          <ul className="list-disc ml-8 mb-2">
            <li>plata la timp sau (integrală) a Prețului de achiziție;</li>
            <li>cumpărarea (la timp) a unui lot și/sau;</li>
            <li>furnizarea informațiilor sau a documentației necesare pentru Achiziție,</li>
            <li>obligația de a furniza o garanție (adecvată) pentru executare la prima solicitare;</li>
          </ul>
        </li>
        <li>circumstanțe care ajung la cunoștința Vânzătorului și/sau a LICITAS AUCTIONS și care dau motive întemeiate să se teamă că Cumpărătorul nu va fi capabil să își îndeplinească obligațiile,</li>
        <li>(cerere de) faliment, suspendare a plăților sau restructurare (legală) a datoriilor Cumpărătorului sau (cerere de) procedură WHOA și/sau orice altă procedură similară de insolvență,</li>
        <li>în cazul în care apar circumstanțe privind persoanele și/sau materialele pe care Vânzătorul sau LICITAS AUCTIONS, la instrucțiunile Vânzătorului, le utilizează sau tinde să le utilizeze în executarea Contractului de vânzare, care sunt de așa natură încât executarea Contractului de vânzare devine imposibilă sau atât de dificilă și/sau disproporționat de costisitoare încât îndeplinirea Contractului de vânzare nu mai poate fi solicitată în mod rezonabil,</li>
        <li>o terță parte are un interes în mutarea în condiții de precauție a întregului Lot sau a unei părți a acestuia, iar mutarea de către Cumpărător ar pune în pericol clădirea sau ar cauza daune permanente, în opinia LICITAS AUCTIONS sau a Vânzătorului, sau</li>
        <li>apar circumstanțe înainte de Retragere, în urma cărora Retragerea unui Lot nu mai este posibilă, sau</li>
        <li>în caz de forță majoră din partea Vânzătorului sau a LICITAS AUCTIONS, de exemplu (dar nu exclusiv) din cauza unui incendiu, furt sau daune cauzate de forțe naturale, grevă, război. pandemie, măsuri guvernamentale.</li>
      </ul>
      <p className="mb-2">Articolul 18.2. După transferul de proprietate, Cumpărătorul nu poate pretinde dizolvarea sau anularea Achiziției contractului de achiziție.</p>
      <p className="mb-2">Articolul 18.3. În cazul rezilierii sau al dizolvării menționate la articolul 18.1, Cumpărătorul îi datorează în continuare lui LICITAS AUCTIONS banii de achiziție și, în plus față de banii de achiziție, Cumpărătorul care nu și-a îndeplinit obligațiile datorează lui LICITAS AUCTIONS o sumă forfetară de 25 % din Oferta câștigătoare făcută de acesta pentru a acoperi costurile administrative, de depozitare, de asigurare, de transport, precum și alte costuri (rezonabile), fără a aduce atingere dreptului Vânzătorului de a solicita o despăgubire integrală. În cazul unui credit în care prețul de achiziție este mai mic de 150 EUR, nu se va face nicio rambursare a prețului de achiziție către cumpărător.</p>
      <h2 className="font-bold mb-2">Articolul 19. Răspunderea</h2>
      <p className="mb-2">Articolul 19.1. Fără a aduce atingere dispozițiilor articolului 6:89 și/sau articolului 7:23 din Codul civil, orice drept de revendicare al Cumpărătorului împotriva LICITAS AUCTIONS, a Clientului și/sau a Vânzătorului se prescrie după o perioadă de 4 săptămâni de la Achiziție sau după ce Lotul (Loturile) a (au) fost pus(e) la dispoziția Cumpărătorului.</p>
      <p className="mb-2">Articolul 19.2. Răspunderea LICITAS AUCTIONS și/sau a Vânzătorului, dacă și în măsura în care, după aplicarea prevederilor acestor Termeni și Condiții Generale și (dacă este cazul) a Termenilor și Condițiilor Specifice de Licitație, mai rămâne, va fi întotdeauna limitată la suma plătită de asigurarea de răspundere civilă a LICITAS AUCTIONS și/sau a Vânzătorului în cazul în cauză. În cazul în care asigurarea de răspundere civilă a LICITAS AUCTIONS și/sau a Vânzătorului nu plătește, răspunderea LICITAS AUCTIONS și/sau a Vânzătorului față de un Cumpărător și/sau Utilizator și/sau Vânzător este limitată la o sumă maximă de 5.000 € în total pentru toate pretențiile individuale și comune.</p>
      <p className="mb-2">Articolul 19.3. LICITAS AUCTIONS, Comitentului și Vânzătorului nu răspund în niciun caz pentru daune indirecte sau daune indirecte, cum ar fi - dar nu exclusiv - daunele sub formă de pierdere de profit sau de nevandabilitate a unui Lot și/sau daunele ca urmare a stagnării activității comerciale.</p>
      <p className="mb-2">Articolul 19.4. Orice răspundere a LICITAS AUCTIONS în legătură cu orice defect al (unui) Lot(e) sau în legătură cu o (presupusă) încălcare a drepturilor de (proprietate intelectuală a) terților este exclusă (cu excepția cazului în care legea imperativă prevede altfel).</p>
      <p className="mb-2">Articolul 19.5. Fără a aduce atingere celorlalte prevederi ale articolului 20, următoarele se aplică în ceea ce privește răspunderea LICITAS AUCTIONS, a Clientului și a Vânzătorului:</p>
      <ul className="list-disc ml-8 mb-2">
        <li>LICITAS AUCTIONS, Clientul și/sau Vânzătorul nu sunt răspunzători pentru pagubele produse sau cauzate persoanelor sau Loturilor înainte de încheierea Contractului de vânzare;</li>
        <li>LICITAS AUCTIONS, Clientul și/sau Vânzătorul nu sunt răspunzători pentru daunele cauzate de sau în legătură cu Lotul (Loturile) cumpărat(e), inclusiv pierderea celor cumpărate;</li>
        <li>LICITAS AUCTIONS, Clientul și/sau Vânzătorul nu sunt răspunzători pentru defectele vizibile și invizibile ale Lotului (Loturilor) cumpărat(e) (cu excepția cazului în care legislația obligatorie prevede altfel).</li>
        <li>LICITAS AUCTIONS, Clientul și/sau Vânzătorul nu sunt răspunzători pentru nerespectarea de către bunurile achiziționate a directivelor europene, a dispozițiilor legale sau a altor legi și reglementări, inclusiv a legilor și reglementărilor privind promovarea siguranței angajaților la locul de muncă;</li>
        <li>LICITAS AUCTIONS, Clientul și/sau Vânzătorul nu sunt răspunzători pentru daunele cauzate de și/sau care rezultă din substanțele dăunătoare mediului sau nocive din sau de pe bunurile achiziționate;</li>
        <li>LICITAS AUCTIONS, Comitent și/sau Vânzătorul nu sunt răspunzători pentru daunele cauzate în contextul unei licitații pe internet ca urmare a unor defecțiuni ale calculatorului, inclusiv defecte și/sau defecțiuni ale hardware-ului și/sau software-ului, viruși, programe sau fișiere ilegale, și nici pentru daune dacă, ca urmare a unei defecțiuni a calculatorului, Utilizatorul nu a putut plasa o Ofertă.</li>
        <li>LICITAS AUCTIONS, Clientul și/sau Vânzătorul nu sunt răspunzători pentru daunele rezultate din informații incorecte, neactualizate și/sau incomplete, indiferent dacă sunt sau nu menționate pe Platforma web și/sau pe site-urile web care pot fi vizitate făcând clic pe link-urile către alte site-uri web menționate pe Platforma;</li>
        <li>LICITAS AUCTIONS nu este răspunzător pentru daunele suferite și care vor fi suferite de către Cumpărător, inclusiv orice daune indirecte, în măsura în care ar putea fi vorba de lipsa puterii de a dispune din partea Cumpărătorului și/sau a Vânzătorului, de drepturi restricționate, de lipsa dreptului de înstrăinare, de alte restricții și/sau de drepturi de proprietate intelectuală ale unor terțe părți cu privire la Loturi.</li>
      </ul>
      <p className="mb-2">Articolul 19.6. Limitările răspunderii vânzătorului incluse în acești termeni și condiții nu se aplică dacă daunele se datorează intenției sau neglijenței conștiente a vânzătorului sau a managerilor și/sau subordonaților săi, sau a persoanelor auxiliare angajate de vânzător.</p>
      <p className="mb-2">Articolul 19.7. Limitarea răspunderii LICITAS AUCTIONS incluse în acești termeni și condiții nu se aplică dacă daunele se datorează intenției deliberate sau imprudenței conștiente a LICITAS AUCTIONS sau a managerilor săi, sau a persoanelor auxiliare angajate de LICITAS AUCTIONS.</p>
      <p className="mb-2">Articolul 19.8. Prin prezenta, cumpărătorul renunță la drepturile sale care decurg din titlul 1 al cărții 7 din Codul civil, în măsura în care aceste dispoziții pot fi derogate prin lege.</p>
      <p className="mb-2">Articolul 19.9. Licitas Auctions SRL răspunde în caz de intenție și neglijență gravă. În caz de neglijență simplă, Licitas Auctions SRL răspunde pentru prejudiciile care rezultă din vătămarea vieții, integrității corporale sau sănătății, precum și pentru prejudiciile care rezultă din încălcarea unor obligații contractuale esențiale (obligații cardinale). Obligațiile contractuale esențiale sunt acelea a căror îndeplinire este indispensabilă pentru executarea contractului și asupra cărora partenerul contractual se bazează în mod regulat. În caz de încălcare a obligațiilor contractuale esențiale, răspunderea Licitas Auctions SRL este limitată la despăgubirea pentru prejudicii previzibile și tipice.</p>
      <p className="mb-2">Articolul 19.10. Restricțiile menționate mai sus nu se aplică în cazul în care Licitas Auctions SRL a ascuns fraudulos un defect sau și-a asumat o garanție pentru o calitate specifică.</p>
      <p className="mb-2">Articolul 19.11. Dacă răspunderea Licitas Auctions SRL este limitată sau exclusă conform prevederilor de mai sus, această limitare sau excludere se aplică și organelor sale, angajaților, reprezentanților sau agenților săi.</p>
      <h2 className="font-bold mb-2">Articolul 20. Restricții privind comerțul exterior, controale la export</h2>
      <p className="mb-2">Articolul 20.1. Licitas Auctions SRL informează Clientul că livrarea/exportul de bunuri (produse, software, tehnologii), precum și furnizarea de servicii (asamblare, întreținere, reparații, instruire, etc.), care se desfășoară peste granițele naționale și care sunt legate de executarea obligațiilor contractuale, sunt supuse legislației germane și naționale privind comerțul exterior. Furnizările individuale, precum și serviciile tehnice pot fi supuse restricțiilor și interdicțiilor conform reglementărilor de control la export, inclusiv pentru produse de uz militar sau dublă utilizare.</p>
      <p className="mb-2">Articolul 20.2. Clientul are obligația de a respecta reglementările europene și germane privind controlul exporturilor și embargourile, în special în cazul în care este afectat de restricții asupra unei autorizații de export eliberate pentru Licitas Auctions SRL.</p>
      <p className="mb-2">Articolul 20.3. Dacă autorizațiile necesare pentru livrare/export nu sunt acordate de autoritățile competente sau apar alte impedimente legale, Licitas Auctions SRL are dreptul să rezilieze contractul sau să se retragă din obligațiile de livrare. Clientul nu are dreptul de a solicita despăgubiri în acest caz.</p>
      <p className="mb-2">Articolul 20.4. Respectarea termenelor de livrare poate depinde de eliberarea autorizațiilor necesare, iar întârzierile cauzate de procesele administrative nu sunt imputabile Licitas Auctions SRL.</p>
      <h2 className="font-bold mb-2">Articolul 21. Exonerare de răspundere pentru conținutul furnizat de Client</h2>
      <p className="mb-2">Articolul 21.1. Declarațiile de intenție și alte acte juridice publicate de Licitas Auctions SRL la solicitarea Clientului sunt atribuite exclusiv Clientului și sunt transmise de Licitas Auctions SRL doar în calitate de intermediar. Acestea nu generează obligații pentru Licitas Auctions SRL.</p>
      <p className="mb-2">Articolul 21.2. Licitas Auctions SRL nu garantează că persoana indicată ca furnizor sau destinatar există efectiv, iar Clientul acționează pe propriul risc. Licitas Auctions SRL nu poate preveni complet accesul neautorizat la datele de autentificare ale Clientului.</p>
      <h2 className="font-bold mb-2">Articolul 22. Subcontractanți</h2>
      <p className="mb-2">Licitas Auctions SRL are dreptul de a angaja subcontractanți pentru prestarea serviciilor online și offline; răspunderea Licitas Auctions SRL rămâne conform prevederilor secțiunii A.10.</p>
      <h2 className="font-bold mb-2">Articolul 23. Declarații</h2>
      <p className="mb-2">Toate declarațiile și notificările trebuie efectuate în scris pentru a fi valabile. Această cerință este considerată îndeplinită și în cazul utilizării platformei Licitas Auctions SRL sau a comunicărilor prin e-mail.</p>
      <h2 className="font-bold mb-2">Articolul 24. Cesionare</h2>
      <p className="mb-2">Cu excepția creanțelor de plată, Clientul nu poate cesiona drepturile din contract fără acordul prealabil al Licitas Auctions SRL.</p>
      <h2 className="font-bold mb-2">Articolul 25. Declarații vamale</h2>
      <p className="mb-2">Dacă Licitas Auctions SRL este exportatorul legal conform legislației vamale, toate declarațiile de export trebuie întocmite în numele Licitas Auctions SRL. Aceste declarații pot fi emise doar de Licitas Auctions SRL sau de un reprezentant autorizat desemnat de Licitas Auctions SRL. Orice emitere neautorizată este interzisă.</p>
      <h2 className="font-bold mb-2">Articolul 25. Declarații vamale</h2>
      <p className="mb-2">Articolul 26. Diverse</p>
      <p className="mb-2">Dacă vreo dispoziție contractuală devine nulă sau ineficace, validitatea celorlalte clauze rămâne neafectată. Dispoziția nulă va fi înlocuită cu o prevedere care reflectă cât mai fidel scopul economic al acesteia.</p>
      <p className="mb-2">Articolul 27. Condițiile și funcționarea modificărilor</p>
      <p className="mb-2">Articolul 27.1. Dacă și în măsura în care o dispoziție din Termenii și condițiile generale este nulă sau este anulată, celelalte dispoziții din Termenii și condițiile generale rămân în vigoare. În acest caz, dispoziția nulă, anulabilă sau în alt mod neaplicabilă din punct de vedere juridic se consideră a fi înlocuită cu o nouă dispoziție care, prin natura și scopul său, este cât mai apropiată de dispoziția nulă/anulată sau în alt mod neaplicabilă din punct de vedere juridic.</p>
      <p className="mb-2">Articolul 27.2. LICITAS AUCTIONS are dreptul de a modifica unilateral acești Termeni și condiții generale. Atunci când Termenii și Condițiile Generale sunt modificate, versiunea modificată intră în vigoare începând cu fiecare Licitație organizată și care urmează să fie organizată după momentul plasării Termenilor și Condițiilor Generale pe Platforma și/sau a Contractului de vânzare încheiat.</p>
      <p className="mb-2">Articolul 27.3. Abaterea de la acești Termeni și Condiții Generale este posibilă numai în măsura în care aceasta este înregistrată în scris de către un reprezentant autorizat al LICITAS AUCTIONS și confirmată Utilizatorului.</p>
      <p className="mb-2">Articolul 28. Legea aplicabilă</p>
      <p className="mb-2">Articolul 28.1. Acești Termeni și condiții generale, Contractul de utilizare, Contractul de cumpărare și alte raporturi juridice care apar în legătură cu o Licitație sunt guvernate de legislația olandeză, cu excepția Convenției de la Viena privind vânzările.</p>
      <p className="mb-2">Articolul 28.2. Textul românesc al Termenilor și condițiilor generale este textul original. În cazul în care Condițiile generale sunt utilizate în mai multe limbi, textul românesc prevalează, prin urmare, în caz de ambiguitate sau contradicție.</p>
      <p className="mb-2">Articolul 28.3. Tribunalul districtual din Amsterdam este autorizat (fără a aduce atingere posibilității de apel și fără a aduce atingere dispozițiilor legale contrare), cu excluderea tuturor celorlalte organisme, să se pronunțe în primă instanță asupra litigiilor care decurg din Termenii și condițiile generale, Contractul de utilizare, Contractul de cumpărare și alte raporturi juridice care apar în legătură cu Licitația.</p>
      <p className="mb-2">Articolul 28.4. Relațiile contractuale dintre Licitas Auctions SRL și Client sunt guvernate exclusiv de legea română, excluzând Convenția Națiunilor Unite privind Vânzarea Internațională de Mărfuri (CISG).</p>
      <p className="mb-2">Articolul 28.5. Jurisdicția exclusivă pentru toate litigiile este Tribunalul Suceava, însă Licitas Auctions SRL are dreptul de a introduce acțiuni și la sediul Clientului.</p>
      <h2 className="text-lg font-bold mt-8 mb-4">B. Dispoziții speciale suplimentare pentru contractele pe bază de comision</h2>
      <h2 className="font-bold mb-2">Articolul 29. Contract de comision de vânzare</h2>
      <p className="mb-2">Prin acceptarea unei comenzi din partea unui Client care dorește să angajeze Licitas Auctions SRL pentru vânzarea unui Obiect în nume propriu, dar pe seama Clientului (dacă este cazul, și prin intermediul unei licitații), se încheie un contract de comision pentru vânzare între Client, în calitate de Comitent, și Licitas Auctions SRL, în calitate de Contractor (comisionar), în conformitate cu termenii și condițiile de mai jos.</p>
      <h2 className="font-bold mb-2">Articolul 30. Proprietate, asigurare, confidențialitate</h2>
      <p className="mb-2">Până la vânzarea Obiectului, acesta rămâne proprietatea Comitentului. Comitentul are obligația de a asigura Obiectul împotriva incendiilor, altor daune și furtului. Dacă se convine în mod expres, Licitas Auctions SRL va trata identitatea Comitentului în mod confidențial față de potențialii cumpărători.</p>
      <h2 className="font-bold mb-2">Articolul 31. Inspecție</h2>
      <p className="mb-2">Comitentul permite Licitas Auctions SRL și potențialilor cumpărători desemnați de acesta să inspecteze Obiectul.</p>
      <h2 className="font-bold mb-2">Articolul 32. Interdicția de a oferi Obiectul în altă parte</h2>
      <p className="mb-2">Pe durata contractului, Comitentului îi este interzis să ofere Obiectul spre vânzare în altă parte. În cazul în care Comitentul încalcă această interdicție, acesta este obligat să plătească despăgubiri către Licitas Auctions SRL în conformitate cu prevederile legale aplicabile.</p>
      <h2 className="font-bold mb-2">Articolul 33. Dreptul de auto-intervenție</h2>
      <p className="mb-2">Licitas Auctions SRL are dreptul de a achiziționa Obiectul pentru sine (drept de auto-intervenție conform § 400 din Codul Comercial German – HGB).</p>
      <h2 className="font-bold mb-2">Articolul 34. Livrare</h2>
      <p className="mb-2">Comitentul are obligația de a demonta Obiectul și de a-l pune la dispoziție pentru ridicare, cu excepția cazului în care s-a convenit altfel în mod expres.</p>
      <h2 className="font-bold mb-2">Articolul 35. Cereri privind defectele</h2>
      <p className="mb-2">Licitas Auctions SRL nu acordă cumpărătorului niciun drept referitor la posibile defecte materiale sau de titlu ale Obiectului, care să depășească drepturile legale ale cumpărătorului, cu excepția cazului în care Comitentul a fost de acord cu o prevedere suplimentară într-un caz individual. Comitentul exonerează Licitas Auctions SRL, la prima solicitare, de orice pretenții ale cumpărătorului legate de posibile defecte materiale sau de titlu ale Obiectului.</p>
      <h2 className="font-bold mb-2">Articolul 36. Prețul de achiziție</h2>
      <p className="mb-2">Licitas Auctions SRL are dreptul de a conveni un termen de plată de până la trei luni. Licitas Auctions SRL nu poate accepta un preț mai mic decât prețul minim convenit fără acordul Comitentului. Licitas Auctions SRL nu își asumă nicio răspundere privind solvabilitatea cumpărătorului (fără garanție de plată).</p>
      <h2 className="font-bold mb-2">Articolul 37. Comision</h2>
      <p className="mb-2">Licitas Auctions SRL va primi un comision conform specificațiilor prevăzute în contract. Baza de calcul a comisionului este prețul net de achiziție, adică prețul de achiziție fără a include costurile de demontare, transport, asigurare și alte costuri, și fără TVA. TVA-ul va fi adăugat comisionului conform cotei legale aplicabile.</p>
      <h2 className="font-bold mb-2">Articolul 38. Încasare, compensare, raportare financiară</h2>
      <p className="mb-2">Articolul 38.1. Licitas Auctions SRL are dreptul de a încasa prețul de achiziție și de a-l compensa cu propriul său comision. Comitentul nu are dreptul de a încasa prețul de achiziție.</p>
      <p className="mb-2">Articolul 38.2. Licitas Auctions SRL va întocmi un raport financiar în termen de o lună de la încasarea prețului de achiziție și va transfera Comitentului suma rămasă după deducerea comisionului.</p>
      <h2 className="text-lg font-bold mt-8 mb-4">C. Dispoziții speciale suplimentare pentru contractele de vânzare cu LICITAS AUCTIONS în calitate de vânzător</h2>
      <h2 className="font-bold mb-2">Articolul 39. Ofertă și încheierea contractului, descrierea obiectului</h2>
      <p className="mb-2">Articolul 39.1. Dacă o comandă este considerată o ofertă în conformitate cu § 145 din Codul Civil German (BGB), Licitas Auctions SRL o poate accepta în termen de 2 săptămâni. Ofertele de cost sunt neobligatorii și constituie doar invitații pentru Client de a face oferte.</p>
      <p className="mb-2">Articolul 39.2. Tipul și volumul fiecărui obiect de achiziție sunt conforme cu descrierea sa textuală. Imaginile sunt neobligatorii și pot diferi de original, servind doar pentru reprezentarea generală a tipului de obiect. Accesoriile și uneltele fac parte din obiectul achiziției doar dacă sunt specificate în mod expres. Informații suplimentare care nu sunt conținute într-un catalog sau pe platforma Licitas Auctions SRL pot fi obținute, dacă este cazul, la locul de depozitare. Fiecare Client are obligația de a inspecta obiectele pe cât posibil. Programările pentru inspecție trebuie convenite cu Licitas Auctions SRL.</p>
      <p className="mb-2">Articolul 39.3. Ofertele emise de Licitas Auctions SRL sunt condiționate de respectarea legilor privind comerțul exterior, în special de inexistența unor reglementări specifice de embargou care să interzică tranzacția. În plus față de Secțiunea A, Punctul 11 (restricții în conformitate cu legislația comerțului exterior, controlul exportului), Licitas Auctions SRL își rezervă dreptul de a efectua verificări în acest sens.</p>
      <h2 className="font-bold mb-2">Articolul 40. Documente predate</h2>
      <p className="mb-2">Licitas Auctions SRL își rezervă drepturile de proprietate și drepturile de autor asupra tuturor documentelor predate Clientului în legătură cu emiterea comenzii, cum ar fi calcule, desene etc. Aceste documente nu pot fi accesibile terților fără acordul scris expres al Licitas Auctions SRL. Dacă oferta Clientului nu este acceptată în termenul prevăzut la punctul C.1.(1), documentele trebuie returnate imediat către Licitas Auctions SRL.</p>
      <h2 className="font-bold mb-2">Articolul 41. Livrarea/ridicarea obiectelor, costuri, transferul riscurilor</h2>
      <p className="mb-2">Articolul 41.1. Dacă nu se convine altfel, livrarea obiectului de achiziție are loc din fabrică/depozit (drop shipment). Prețurile indicate de Licitas Auctions SRL trebuie interpretate în consecință.</p>
      <p className="mb-2">Articolul 41.2. Datele sau termenele de livrare sunt obligatorii doar dacă sunt confirmate în scris de Licitas Auctions SRL. Respectarea termenelor necesită îndeplinirea tuturor condițiilor de livrare impuse Clientului. Tranzacțiile cu termene fixe necesită confirmare expresă.</p>
      <p className="mb-2">Articolul 41.3. Licitas Auctions SRL are dreptul de a efectua livrări parțiale, dacă acest lucru este rezonabil pentru Client.</p>
      <p className="mb-2">Articolul 41.4. Dacă nu s-au convenit obligații specifice de ridicare, Clientul trebuie să ridice obiectele achiziționate imediat după încheierea contractului, dar nu mai târziu de o săptămână de la semnarea contractului. Obiectele vor fi predate doar după efectuarea plății integrale. În cazul depășirii termenului de ridicare, Clientul va suporta costurile rezultate, în special pentru depozitare și întreținere. Depozitarea și transportul se vor face pe cheltuiala și riscul Clientului. Licitas Auctions SRL poate stabili un termen suplimentar rezonabil pentru ridicare; în cazul expirării acestuia fără rezultat, Licitas Auctions SRL poate decide depozitarea, valorificarea sau casarea obiectului pe cheltuiala Clientului.</p>
      <p className="mb-2">Articolul 41.5. Transportul obiectelor de la locul de depozitare se va face pe cheltuiala Clientului, inclusiv costurile de transport, asigurare, ambalare și manipulare, precum și toate taxele și impozitele de export.</p>
      <p className="mb-2">Articolul 41.6. Dacă s-a convenit expedierea obiectului, aceasta se va face pe riscul Clientului, cu excepția cazului în care s-a stabilit altfel. Riscul distrugerii sau deteriorării obiectului se transferă Clientului la momentul expedierii sau cel târziu la părăsirea fabricii/depozitului (drop shipment).</p>
      <h2 className="font-bold mb-2">Articolul 42. Plata prețului de achiziție</h2>
      <p className="mb-2">Prețul de achiziție trebuie plătit imediat după încheierea contractului. Dacă Clientul întârzie plata, creanța va fi purtătoare de dobândă de 9 puncte procentuale peste rata dobânzii de bază aplicabilă (§ 288 alin. 2 BGB). În caz de neplată, Licitas Auctions SRL are dreptul de a rezilia contractul sau de a solicita despăgubiri. Dacă Licitas Auctions SRL reziliază contractul, va putea valorifica din nou obiectul, iar Clientul nu va avea dreptul să participe la noua valorificare.</p>
      <h2 className="font-bold mb-2">Articolul 43. Rezervarea dreptului de proprietate</h2>
      <p className="mb-2">Obiectele de achiziție rămân proprietatea Licitas Auctions SRL până la plata integrală a prețului. Clientul trebuie să informeze imediat Licitas Auctions SRL despre orice intervenție a terților asupra obiectelor, cum ar fi măsuri de executare sau alte sechestrări. Dacă obiectul se află într-o țară unde rezervarea proprietății nu este valabilă, Clientul trebuie să ofere garanții echivalente.</p>
      <h2 className="font-bold mb-2">Articolul 44. Cereri privind defectele</h2>
      <p className="mb-2">Articolul 44.1. Obiectele vândute de Licitas Auctions SRL sunt second-hand și nu sunt fabricate recent, cu excepția cazurilor în care se precizează altfel în mod expres.</p>
      <p className="mb-2">Articolul 44.2. Obiectele second-hand sunt vândute fără garanție. Excepțiile se aplică doar în cazul daunelor cauzate de neglijență gravă sau intenționată, sau de vătămări corporale.</p>
      <h2 className="font-bold mb-2">Articolul 45. Licitație online</h2>
      <p className="mb-2">Articolul 45.1. Licitas Auctions SRL stabilește un preț de pornire și un termen limită pentru depunerea ofertelor (perioada licitației online). Declarațiile respective reprezintă o ofertă obligatorie pentru vânzarea obiectelor. Oferta se adresează participantului care depune cea mai mare ofertă până la finalizarea licitației.</p>
      <p className="mb-2">Articolul 45.2. Dacă Licitas Auctions SRL stabilește un preț minim și alte condiții, contractul se încheie cu ofertantul care oferă cel puțin prețul minim la sfârșitul licitației.</p>
      <h2 className="text-lg font-bold mt-8 mb-4">D. Dispoziții speciale suplimentare pentru contractele de vânzare cu LICITAS AUCTIONS în calitate de cumpărător</h2>
      <h2 className="font-bold mb-2">Articolul 46. Încheierea contractului</h2>
      <p className="mb-2">Prin acceptarea ofertei unui client care dorește să vândă un obiect către Licitas Auctions SRL, se încheie un contract de vânzare-cumpărare între client și Licitas Auctions SRL, în conformitate cu prevederile acestor termeni și condiții generale de afaceri Licitas Auctions SRL. Licitas Auctions SRL își rezervă un termen de o săptămână pentru acceptarea ofertei.</p>
      <h2 className="font-bold mb-2">Articolul 47. Reclamații privind defectele, răspunderea</h2>
      <p className="mb-2">Articolul 47.1. Reclamațiile privind defectele și răspunderea clientului vor fi reglementate conform prevederilor legale.</p>
      <p className="mb-2">Articolul 47.2. În domeniul de aplicare al § 377 din Codul Comercial German (HGB) – achiziție comercială – se vor aplica obligațiile de inspecție și reclamație prevăzute, cu următoarele dispoziții: Defectele obiectului achiziționat, care pot fi identificate în timpul unei inspecții realizate în cadrul unui proces de afaceri adecvat, trebuie raportate de Licitas Auctions SRL în termen de două săptămâni de la livrare; defectele care nu sunt vizibile în timpul unei astfel de inspecții trebuie raportate de Licitas Auctions SRL în termen de două săptămâni de la descoperirea lor.</p>
      <h2 className="font-bold mb-2">Articolul 48. Utilizarea obiectului achiziționat</h2>
      <p className="mb-2">Licitas Auctions SRL are dreptul să utilizeze obiectul achiziționat la discreția sa; în special, are permisiunea de a-l revinde.</p>
      <h2 className="text-lg font-bold mt-8 mb-4">E. Dispoziții speciale suplimentare pentru contractele de brokeraj; Prevederi speciale suplimentare pentru contractele de intermediere</h2>
      <h2 className="font-bold mb-2">Articolul 49. Remunerație, contracte ulterioare</h2>
      <p className="mb-2">Licitas Auctions SRL are dreptul la remunerație pentru intermedierea vânzării unui obiect („remunerația Licitas Auctions SRL”) conform condițiilor specifice ale contractului respectiv. Pentru fiecare contract de vânzare suplimentar încheiat între client și partenerul contractual intermediat de Licitas Auctions SRL (contracte ulterioare), Licitas Auctions SRL are, de asemenea, dreptul la o remunerație corespunzătoare.</p>
      <h2 className="font-bold mb-2">Articolul 50. Exclusivitate</h2>
      <p className="mb-2">Clientul este obligat să vândă și să cumpere obiectele care fac obiectul unui contract de intermediere cu Licitas Auctions SRL exclusiv prin intermediul Licitas Auctions SRL, în conformitate cu acești termeni și condiții. Pe durata contractului, clientul se obligă, de asemenea, să nu angajeze terți pentru intermedierea obiectului și să interzică astfel de activități terților. În cazul în care o parte interesată contactează direct clientul pentru negocierea vânzării sau achiziției unui obiect, clientul trebuie să informeze partea interesată că obiectul este intermediat de Licitas Auctions SRL și să informeze Licitas Auctions SRL despre acest contact.</p>
      <h2 className="font-bold mb-2">Articolul 51. Activitatea de intermediere dublă permisă</h2>
      <p className="mb-2">În mod obișnuit, Licitas Auctions SRL lucrează pentru ambele părți contractante ale unei tranzacții care urmează să fie intermediată. Serviciile Licitas Auctions SRL sunt, în general, supuse plății unei remunerații de către ambele părți contractante.</p>
      <h2 className="font-bold mb-2">Articolul 52. Rezilierea contractului</h2>
      <p className="mb-2">Licitas Auctions SRL și clientul pot rezilia contractul cu un preaviz de 14 zile, cu excepția cazului în care s-a convenit o durată minimă sau o perioadă de preaviz mai lungă într-un caz individual. Rezilierea trebuie să fie în formă scrisă. Orice drept existent la remunerația Licitas Auctions SRL rămâne neafectat.</p>
      <h2 className="font-bold mb-2">Articolul 53. Descrierea obiectelor, inspecția</h2>
      <p className="mb-2">Articolul 53.1. Obiectele care fac obiectul unui contract de intermediere cu Licitas Auctions SRL sunt second-hand și nu sunt fabricate noi, cu excepția cazului în care se precizează altfel în mod expres într-un caz individual.</p>
      <p className="mb-2">Articolul 53.2. Descrierile din catalog sau de pe platforma Licitas Auctions SRL, inclusiv informațiile privind originea, starea, vechimea și autenticitatea fiecărui obiect, sunt furnizate conform cunoștințelor și încrederii noastre cele mai bune, bazându-se pe declarațiile vânzătorului. Nu se acceptă nicio răspundere pentru corectitudinea acestora. Descrierile nu constituie declarații obligatorii din punct de vedere legal sau garanții. Tipul și domeniul obiectului achiziționat sunt conforme cu descrierea textuală. Imaginile fotografice nu sunt obligatorii și pot diferi de original, servind doar ca reprezentare generală a tipului de obiect. Accesoriile și uneltele de lucru fac parte din obiect doar dacă sunt menționate în mod expres ca atare. Informații suplimentare pot fi obținute la locația de depozitare, dacă este cazul.</p>
      <p className="mb-2">Articolul 53.3. Fiecare client este obligat să inspecteze obiectele pe cât posibil. Programările pentru inspecție trebuie stabilite cu Licitas Auctions SRL. Licitas Auctions SRL își rezervă dreptul de a exclude un obiect dacă consideră că declarațiile vânzătorului sunt incorecte, în special dacă nu corespund stării reale a obiectului. Totuși, Licitas Auctions SRL nu are obligația de a efectua inspecții.</p>
      <h2 className="font-bold mb-2">Articolul 54. Plata remunerației Licitas Auctions SRL</h2>
      <p className="mb-2">Remunerația Licitas Auctions SRL trebuie plătită imediat după încheierea contractului de vânzare. Aceasta trebuie plătită chiar și în cazul în care contractul de vânzare este încheiat cu un terț ca urmare a transmiterii informațiilor de către client. În caz de întârziere a plății, remunerația Licitas Auctions SRL va fi supusă unei dobânzi de 9 puncte procentuale peste rata de bază (§ 288 alin. 2 BGB).</p>
      <h2 className="font-bold mb-2">Articolul 55. Plata prețului de achiziție</h2>
      <p className="mb-2">Articolul 55.1. Prețul de achiziție este exigibil imediat după încheierea contractului de vânzare. În caz de întârziere la plată, se va percepe o dobândă de 9 puncte procentuale peste rata de bază (§ 288 alin. 2 BGB). În caz de neplată, vânzătorul are dreptul să rezilieze contractul sau să ceară despăgubiri în locul executării. În cazul rezilierii contractului, vânzătorul are dreptul de a dispune din nou de obiect. Cumpărătorul nu are dreptul să liciteze din nou. Dacă vânzătorul cere despăgubiri, cumpărătorul trebuie să suporte costurile unei noi valorificări a obiectului, precum și orice pierderi de venit.</p>
      <p className="mb-2">Articolul 55.2. Dreptul Licitas Auctions SRL la remunerație nu este afectat de rezilierea contractului sau cererile de despăgubiri ale vânzătorului.</p>
      <h2 className="font-bold mb-2">Articolul 56. Livrarea/ridicarea obiectelor, costuri, transferul riscului</h2>
      <p className="mb-2">Dacă nu se convine altfel, regulile privind livrarea/ridicarea, costurile și transferul riscului din secțiunea C.3 se aplică în mod corespunzător relației dintre cumpărător și vânzător.</p>
      <h2 className="font-bold mb-2">Articolul 57. Reclamații privind defectele</h2>
      <p className="mb-2">Articolul 57.1. Obiectele sunt second-hand și nu sunt fabricate noi, cu excepția cazului în care se precizează altfel în mod expres. Vânzătorul garantează că are dreptul de a dispune de obiect și că acesta nu este grevat de drepturi ale terților.</p>
      <p className="mb-2">Articolul 57.2. Obiectele second-hand și non-noi sunt vândute fără garanție, cu excepția cazului în care se convine altfel. Excepția se aplică în caz de încălcare intenționată sau grav neglijentă a obligațiilor, sau în caz de vătămare corporală, deces sau afectare a sănătății.</p>
      <p className="mt-4 mb-2">Aveți întrebări cu privire la Termenii și Condițiile Generale Utilizatori/Cumpărători de pe licitas.ro? Dacă da, vă rugăm să ne contactați la contact@licitas.ro</p>
    </div>
  </>;
};

export default TermsAndConditionsPage;
