const ConsentFormPage = () => {
  return <>
    <div className="flex flex-col mx-auto w-full">
      <h1 className="text-4xl font-bold mb-4">Formular de consimțământ</h1>
      <p className="text-lg">Acest Formular de consimțământ a fost actualizat ultima dată la 28 martie 2025.</p>
    </div>
  </>;
};

export default ConsentFormPage;
