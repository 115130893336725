import { Category } from "api/models/category";
import { Country } from "api/models/country";
import { LotStatus } from "api/models/lotStatus";
import { LotsSortByOptions } from "api/requests/getLotsQuery";
import Drawer from "components/drawer";
import { Accordion, Badge, Button, Dropdown, Label, ToggleSwitch } from "flowbite-react";
import useLotsSearchParams from "hooks/lotsSearchParams";
import { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { FiSearch } from "react-icons/fi";
import { HiX } from "react-icons/hi";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { LiaSortAlphaDownSolid, LiaSortAlphaUpSolid, LiaSortAmountDownAltSolid, LiaSortAmountUpAltSolid, LiaSortNumericDownSolid, LiaSortNumericUpSolid } from "react-icons/lia";
import { SortDirection, ViewBreakpoints } from "static-data/constants";
import AccordionFilterTitle from "./AccordionFilterTitle";
import CheckboxListFilter from "./CheckboxListFilter";
import SearchInputFilter from "./SearchInputFilter";
import SelectedFiltersBanner from "./SelectedFiltersBanner";

type StatusMap = Partial<Record<LotStatus, string>>;
type LotsSortByMap = Partial<Record<LotsSortByOptions, string>>;
type SortByDropdownItemConfig = {
  name: LotsSortByOptions,
  direction: SortDirection,
  icon: IconType,
  displayName: string
}

const LotsFilters = (props: {
  withCategories?: Category[],
  withCountries?: Country[],
  withStatuses?: LotStatus[],
  withIsReserved: boolean
}) => {
  const { withCategories: categories, withCountries: countries, withStatuses: statuses, withIsReserved } = props;
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [countrySearchText, setCountrySearchText] = useState("");
  const [filteredCountries, setFilteredCountries] = useState<Country[]>(countries);
  const { params: lotsSearchParams, setSearch, setCountry, setCategory, setStatus, setIsReserved, setPage, setSorting } = useLotsSearchParams();
  const { search, country: selectedCountries, category: selectedCategories, status: selectedStatuses, isReserved, sortBy, sortDirection } = lotsSearchParams;

  const statusMap: StatusMap = { "Inactive": "Inactiv", "Active": "Activ" }
  const sortByMap: LotsSortByMap = { "Name": "Nume", "FinalPrice": "Preț", "BidsCount": "Număr de oferte", "EndsAtUtc": "Timp până la închidere" }
  const sortByConfig: SortByDropdownItemConfig[] = [
    { name: "Name", direction: "asc", icon: LiaSortAlphaDownSolid, displayName: sortByMap["Name"] },
    { name: "Name", direction: "desc", icon: LiaSortAlphaUpSolid, displayName: sortByMap["Name"] },
    { name: "FinalPrice", direction: "asc", icon: LiaSortNumericDownSolid, displayName: sortByMap["FinalPrice"] },
    { name: "FinalPrice", direction: "desc", icon: LiaSortNumericUpSolid, displayName: sortByMap["FinalPrice"] },
    { name: "BidsCount", direction: "asc", icon: LiaSortNumericDownSolid, displayName: sortByMap["BidsCount"] },
    { name: "BidsCount", direction: "desc", icon: LiaSortNumericUpSolid, displayName: sortByMap["BidsCount"] },
    { name: "EndsAtUtc", direction: "asc", icon: LiaSortAmountDownAltSolid, displayName: sortByMap["EndsAtUtc"] },
    { name: "EndsAtUtc", direction: "desc", icon: LiaSortAmountUpAltSolid, displayName: sortByMap["EndsAtUtc"] },
  ]

  const defaultSortBy = sortBy ?? "EndsAtUtc";
  const defaulSortDirection = sortDirection ?? "asc";

  const activeSort = (sort: LotsSortByOptions, direction: SortDirection) => {
    return defaultSortBy === sort && defaulSortDirection === direction;
  }

  useEffect(() => {
    const handleResize = (() => window.innerWidth < ViewBreakpoints.xl ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleGlobalKeyDownSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.currentTarget.value) {
      setSearchText(event.currentTarget.value);
      onSubmitSearch();
    }
  }

  const onSubmitSearch = () => {
    if (searchText) {
      setSearch(searchText);
      setSearchText("");
    }
  };

  const clearSearchText = () => {
    setSearchText("");
    setSearch();
  }

  const onChangeCountrySearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setCountrySearchText(event.currentTarget.value);
    filterCountries(event.currentTarget.value);
  }

  const filterCountries = (text: string) => {
    if (!text)
      setFilteredCountries(countries);
    setFilteredCountries(countries.filter(c => c.name.toLowerCase().includes(text.toLowerCase())));
  }

  const handleCountryKeyDownSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      setCountrySearchText(event.currentTarget.value);
      filterCountries(event.currentTarget.value);
    } else if (event.key === 'Escape') {
      setCountrySearchText("");
      filterCountries("");
    }
  }

  const onSelectCountry = (country: string) => {
    let newCountries = [];
    if (selectedCountries.includes(country)) {
      newCountries = selectedCountries.filter(c => c !== country);
    }
    else {
      newCountries = [...selectedCountries, country];
    }
    setCountry(newCountries);
    setCountrySearchText("");
    filterCountries("");
  }

  const onSelectCategory = (category: Category) => {
    let newCategories = [];
    if (selectedCategories.includes(category.id)) {
      newCategories = selectedCategories.filter(c => c !== category.id);
    }
    else {
      newCategories = [...selectedCategories, category.id];
    }
    setCategory(newCategories);
  }

  const onSelectStatus = (status: LotStatus) => {
    let newStatuses = [];
    if (selectedStatuses.includes(status)) {
      newStatuses = selectedStatuses.filter(s => s !== status);
    }
    else {
      newStatuses = [...selectedStatuses, status];
    }
    setStatus(newStatuses);
  }

  const displaySelectedFilters = (selectedFilters: string[]) => {
    if (selectedFilters && selectedFilters.length > 0) {
      let displayed = selectedFilters[0] ?? "";
      if (selectedFilters.length > 1) {
        displayed += ` +${selectedFilters.length - 1}`;
      }
      return displayed;
    }
  }

  const displaySelectedMoreFilters = () => {
    if (!!isReserved) {
      return "Cu rezervare"
    }
  };

  const onResetMoreFilters = () => {
    setIsReserved();
  };

  const onResetAllFilters = () => {
    setSearch();
    setCategory();
    setCountry();
    setStatus();
    setIsReserved();
    setPage();
  }

  const displaySelectedCategories =
    displaySelectedFilters(selectedCategories.map(cat => categories.find(c => c.id === cat).name));

  const displayedSelectedCountries =
    displaySelectedFilters(selectedCountries.map(country => countries.find(c => c.code === country).name));

  const displaySelectedStatuses =
    displaySelectedFilters(selectedStatuses.map(status => statusMap[status]));


  const buildAccordionTitle = (triggerLabel: string, displayedItems: string, onResetClick: () => void) =>
    <div className="w-full flex justify-between text-black font-semibold pr-2">
      <p>{triggerLabel}</p>
      <div className="flex justify-between items-center gap-2">
        <p>{displayedItems}</p>
        {displayedItems && <Badge color="brand" size="sm" icon={HiX} onClick={() => onResetClick()} />}
      </div>
    </div>

  const categoriesFilterContent =
    categories && <CheckboxListFilter label="categories" items={categories} onSelectItem={onSelectCategory} isChecked={(item) => selectedCategories.includes(item.id)} onResetClick={setCategory} />;

  const countriesFilterContent =
    countries && <>
      <SearchInputFilter label="countries" searchValue={countrySearchText} searchPlaceholder="Caută țară..." onChangeSearch={onChangeCountrySearchText} onKeyDownSearch={handleCountryKeyDownSearch} />
      <CheckboxListFilter label="countries" items={filteredCountries.map((c) => ({ id: c.code, name: c.name }))} onSelectItem={(item) => onSelectCountry(item.id)} isChecked={(item) => selectedCountries.includes(item.id)} onResetClick={setCountry} />
    </>;

  const statusesFilterContent =
    statuses && <CheckboxListFilter label="status" items={statuses.map((s) => ({ id: s, name: statusMap[s] }))} onSelectItem={(item) => onSelectStatus(item.id)} isChecked={(item) => selectedStatuses.includes(item.id)} onResetClick={setStatus} />

  const moreFiltersContent =
    <>
      <ul className="max-h-60 overflow-y-auto">
        <li role="menuitem" key="list-item-more-isReserved" onClick={() => setIsReserved(!isReserved || undefined)}>
          <Label value="Rezervare preț" className="m-2 uppercase" />
          <div className="py-2 px-4 text-sm text-gray-700 cursor-pointer w-full hover:bg-gray-100 focus:bg-gray-100 focus:outline-none">
            <ToggleSwitch checked={!!isReserved} color="brand" label="Cu rezervare" onChange={() => setIsReserved(!isReserved || undefined)} />
          </div>
        </li>
      </ul>
      <div className="my-1 h-px bg-gray-100"></div>
      <div className="flex justify-end mx-2">
        <Button color="brand" size="xs" onClick={onResetMoreFilters} className="m-2 text-sm font-semibold">Șterge</Button>
      </div>
    </>

  return (
    <>
      <Drawer isOpen={showFiltersDrawer && isSmallScreen} onClose={() => setShowFiltersDrawer(false)}>
        <div className="mt-2 space-y-4">
          <Accordion collapseAll flush>
            {categories ? <Accordion.Panel>
              <Accordion.Title>
                <AccordionFilterTitle titleLabel="Categorie" selectedItems={displaySelectedCategories} onClearFilter={setCategory} />
              </Accordion.Title>
              <Accordion.Content> {categoriesFilterContent}</Accordion.Content>
            </Accordion.Panel> : <></>}

            {countries ? <Accordion.Panel>
              <Accordion.Title>
                <AccordionFilterTitle titleLabel="Locație" selectedItems={displayedSelectedCountries} onClearFilter={setCountry} />
              </Accordion.Title>
              <Accordion.Content> {countriesFilterContent}</Accordion.Content>
            </Accordion.Panel> : <></>}

            {statuses ? <Accordion.Panel>
              <Accordion.Title>
                <AccordionFilterTitle titleLabel="Status" selectedItems={displaySelectedStatuses} onClearFilter={setStatus} />
              </Accordion.Title>
              <Accordion.Content>{statusesFilterContent}</Accordion.Content>
            </Accordion.Panel> : <></>}

            {withIsReserved ? <Accordion.Panel>
              <Accordion.Title>
                {buildAccordionTitle("Mai multe", displaySelectedMoreFilters(), onResetMoreFilters)}
              </Accordion.Title>
              <Accordion.Content>{moreFiltersContent}</Accordion.Content>
            </Accordion.Panel> : <></>}
          </Accordion>

          <div className="space-y-2">
            <Button outline color="light"
              onClick={onResetAllFilters}
              className="w-full text-white rounded-lg">
              Șterge filtre
            </Button>
            <Button color="brand"
              onClick={() => setShowFiltersDrawer(false)}
              className="w-full text-white rounded-lg">
              Vezi loturi
            </Button>
          </div>
        </div>
      </Drawer>

      {isSmallScreen &&
        <Button size="sm" outline color="light"
          onClick={() => setShowFiltersDrawer(true)}
          className="w-full text-white rounded-lg">
          <HiOutlineAdjustmentsHorizontal className="mr-2 h-5 w-5" />Filtre
        </Button>}

      <div className="flex flex-row items-center justify-start gap-5">
        <div className="basis-full xl:basis-2/6">
          <div className="relative w-full">
            <input
              type="search"
              id="lots-search"
              autoComplete="off"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              placeholder="Caută în listă..."
              onKeyDown={handleGlobalKeyDownSearch}
              className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-1 focus:ring-brand-300 focus:border-brand-300"
            />
            <button
              type="submit"
              onClick={onSubmitSearch}
              className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-brand-500 rounded-e-lg border border-brand-500 hover:bg-brand-700 focus:ring-1 focus:outline-none focus:ring-brand-300">
              <FiSearch className="h-4 w-4" />
              <span className="sr-only">Caută</span>
            </button>
          </div>
        </div>

        {!isSmallScreen &&
          <div className="basis-4/6 flex justify-start items-center gap-4">
            {categories &&
              <Dropdown label="Categorie" color="light" dismissOnClick={false} className="!min-w-44">
                {categoriesFilterContent}
              </Dropdown>}

            {countries &&
              <Dropdown label="Locație" color="light" dismissOnClick={false} className="!min-w-44">
                {countriesFilterContent}
              </Dropdown>}

            {statuses &&
              <Dropdown label="Status" color="light" dismissOnClick={false} className="!min-w-44">
                {statusesFilterContent}
              </Dropdown>}

            {withIsReserved &&
              <Dropdown label="Mai multe" color="light" dismissOnClick={false} className="!min-w-44">
                {moreFiltersContent}
              </Dropdown>}

            <span onClick={onResetAllFilters}
              className="cursor-pointer text-sm text-brand-600 font-semibold hover:text-brand-800">
              Șterge filtre
            </span>
          </div>}
      </div>

      <div className="flex flex-row gap-4">
        {search &&
          <SelectedFiltersBanner order={1} displayedItems={`Căutare: ${search}`} onResetClick={clearSearchText} />
        }

        {!isSmallScreen && <>
          {selectedCategories.length > 0 &&
            <SelectedFiltersBanner order={2} displayedItems={`Categorie: ${displaySelectedCategories}`} onResetClick={setCategory} />
          }

          {selectedCountries.length > 0 &&
            <SelectedFiltersBanner order={3} displayedItems={`Locație: ${displayedSelectedCountries}`} onResetClick={setCountry} />
          }

          {selectedStatuses.length > 0 &&
            <SelectedFiltersBanner order={4} displayedItems={`Status: ${displaySelectedStatuses}`} onResetClick={setStatus} />
          }

          {!!isReserved &&
            <SelectedFiltersBanner order={5} displayedItems={displaySelectedMoreFilters()} onResetClick={onResetMoreFilters} />
          }
        </>}
      </div>
      <div className="h-px bg-gray-200"></div>
      <div className="flex flex-row text-sm py-1">
        <div className="flex gap-1 items-center justify-between">
          <p>Sortare: </p>
          <Dropdown label={sortByMap[defaultSortBy]} inline dismissOnClick={false}>
            {sortByConfig.map((item) => (
              <Dropdown.Item key={`sortBy-${item.name}-${item.direction}`}
                className={activeSort(item.name, item.direction) && 'font-semibold text-brand-500'}
                icon={item.icon}
                onClick={() => setSorting(item.name, item.direction)}>
                {item.displayName}
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default LotsFilters;
