import { Country } from "api/models/country";
import { User } from "api/models/user";
import { UpdateUserCommand } from "api/requests/updateUserCommand";
import { useUserService } from "api/services/usersService";
import CountrySelector from "components/countrySelector";
import Spinner from "components/spinner";
import { Button, Label, TextInput } from "flowbite-react";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import regexHelpers from "static-data/regex-helper.json";
import { useAppDispatch } from "store";
import { setUser } from "store/features/userSlice";
import { isRomania } from "utils/countryHelper";
import companyFiscalCodeValidator from "utils/validators/companyFiscalCodeValidator";
import romanianCompanyRegistrationNumberValidator from "utils/validators/romanianCompanyRegistrationNumberValidator";

export type CompanyDataForm = {
  companyName: string;
  companyCountryCode: string;
  companyAddress: string;
  companyPostalOrZipCode: string;
  companyVatNumber: string;
  companyRegistrationNumber: string;
};

type Errors = Partial<Record<keyof CompanyDataForm, string>>;

export default function CompanyData({ user, countries }: { user: User, countries: Country[] }) {
  const { successToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { updateUser } = useUserService();
  const dispatch = useAppDispatch();
  const [fields, setFields] = useState<CompanyDataForm>({
    companyName: user.company.name,
    companyCountryCode: user.company.countryCode,
    companyAddress: user.company.address,
    companyPostalOrZipCode: user.company.postalOrZipCode,
    companyVatNumber: user.company.vatNumber,
    companyRegistrationNumber: user.company.registrationNumber
  });
  const [errors, setErrors] = useState<Errors>({});

  const handleValidation = (): boolean => {
    const formErrors: Errors = {
      companyName: validateCompanyName(fields.companyName),
      companyCountryCode: validateCompanyCountry(fields.companyCountryCode),
      companyAddress: validateCompanyAddress(fields.companyAddress),
      companyPostalOrZipCode: validateCompanyPostalOrZipCodes(fields.companyPostalOrZipCode),
      companyVatNumber: validateCompanyVatNumber(fields.companyVatNumber),
      companyRegistrationNumber: validateCompanyRegistrationNumber(fields.companyRegistrationNumber),
    };

    setErrors(formErrors);
    return Object.values(formErrors).every(err => !err);
  };

  const validateField = (fieldName: keyof CompanyDataForm, value: any) => {
    switch (fieldName) {
      case "companyName":
        return validateCompanyName(value);
      case "companyCountryCode":
        return validateCompanyCountry(value);
      case "companyAddress":
        return validateCompanyAddress(value);
      case "companyPostalOrZipCode":
        return validateCompanyPostalOrZipCodes(value);
      case "companyVatNumber":
        return validateCompanyVatNumber(value);
      case "companyRegistrationNumber":
        return validateCompanyRegistrationNumber(value);
    }
  };

  const validateCompanyName = (companyName: string): string => {
    if (!companyName) {
      return "Numele companiei trebuie completat";
    }
  };

  const validateCompanyCountry = (companyCountry: string): string => {
    if (!companyCountry) {
      return "Țara companiei trebuie completată";
    }
  };

  const validateCompanyAddress = (companyAddress: string): string => {
    if (!companyAddress) {
      return "Adresa companiei trebuie completată";
    }
  };

  const validateCompanyPostalOrZipCodes = (companyPostalOrZipCode: string): string => {
    if (!companyPostalOrZipCode) {
      return "Codul poștal trebuie completat";
    } else if (
      isRomania(fields.companyCountryCode) &&
      !new RegExp(
        // eslint-disable-next-line no-useless-escape
        /^\d{6}$/
      ).test(companyPostalOrZipCode)
    ) {
      return "Codul poștal este invalid";
    }

  };

  const validateCompanyVatNumber = (companyVatNumber: string): string => {
    if (!companyVatNumber) {
      return "CIF-ul companiei trebuie completat";
    } else if ((isRomania(fields.companyCountryCode) &&
      !companyFiscalCodeValidator(companyVatNumber)) ||
      !new RegExp(regexHelpers.europeanVATNumbersRegex).test(companyVatNumber)) {
      return "CIF-ul este invalid";
    }
  };

  const validateCompanyRegistrationNumber = (companyRegistrationNumber: string): string => {
    if (!isRomania(fields.companyCountryCode)) {
      return;
    }
    if (!companyRegistrationNumber) {
      return "Numărul de înregistrare trebuie completat";
    } else if (!romanianCompanyRegistrationNumberValidator(companyRegistrationNumber)) {
      return "Numărul de înregistrare este invalid";
    }
  };

  const handleChange = (field: keyof CompanyDataForm, value: string | boolean) => {
    setFields({ ...fields, [field]: value });
    setErrors({ ...errors, [field]: validateField(field, value) });
  };

  const submitForm = async () => {
    if (!handleValidation()) {
      return;
    }

    const updatedUser: UpdateUserCommand = {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      companyCountryCode: fields.companyCountryCode,
      companyPostalOrZipCode: fields.companyPostalOrZipCode,
      companyAddress: fields.companyAddress
    };

    setIsLoading(true);
    await updateUser(user.id, updatedUser);
    setIsLoading(false);

    const newUser: User = {
      ...user,
      company: {
        ...user.company,
        countryCode: fields.companyCountryCode,
        address: fields.companyAddress,
        postalOrZipCode: fields.companyPostalOrZipCode
      }
    };
    dispatch(setUser(newUser));

    successToast("Datele companiei au fost salvate cu succes!");
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="mx-2 md:mx-5 space-y-4 md:space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="companyName" value="Nume companie" />
          </div>
          <TextInput
            id="companyName"
            disabled
            type="text"
            placeholder="Licitas Auctions SRL"
            value={fields.companyName}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <CountrySelector
              label="Țară"
              countries={countries}
              defaultCountry={fields.companyCountryCode}
              onCountryChange={(countryCode) => handleChange("companyCountryCode", countryCode)} />
          </div>
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="companyAddress" value="Adresă companie" />
          </div>
          <TextInput
            id="companyAddress"
            type="text"
            color={errors.companyAddress ? "failure" : undefined}
            onChange={(e) => handleChange("companyAddress", e.target.value)}
            value={fields.companyAddress}
            helperText={errors.companyAddress}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="companyPostalOrZipCode" value="Cod poștal" />
          </div>
          <TextInput
            id="companyPostalOrZipCode"
            type="text"
            placeholder="123456"
            color={errors.companyPostalOrZipCode ? "failure" : undefined}
            onChange={(e) => handleChange("companyPostalOrZipCode", e.target.value)}
            value={fields.companyPostalOrZipCode}
            helperText={errors.companyPostalOrZipCode}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="companyVatNumber" value="CIF (Cod de identificare fiscală) *" />
          </div>
          <TextInput
            id="companyVatNumber"
            type="text"
            disabled
            placeholder="RO123456"
            value={fields.companyVatNumber}
          />
        </div>

        {isRomania(fields.companyCountryCode) &&
          <div>
            <div className="mb-2 block">
              <Label htmlFor="companyRegistrationNumber" value="Nr. înregistrare ONRC *" />
            </div>
            <TextInput
              id="companyRegistrationNumber"
              type="text"
              placeholder="J40/123/2021"
              disabled
              value={fields.companyRegistrationNumber}
            />
          </div>}
      </div>

      <div className="mt-3 w-full md:w-[75%] flex justify-center items-center md:justify-start md:items-start">
        <Button
          className="w-[75%] md:w-[50%]"
          color="brand"
          size="lg"
          onClick={() => submitForm()}>
          Salvează modificările
        </Button>
      </div>
    </div>
  );
}