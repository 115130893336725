import { Country } from "api/models/country";
import { useApi } from "api/useApi";

const useCountriesService = () => {
    const { get } = useApi();

    const getCountries = async () => {
        return await get<Country[]>("countries");
    };

    return { getCountries };
};

export { useCountriesService };
