import { Lot } from "api/models/lot";
import { LotSummary } from "api/models/lotSummary";
import { RegisterBidCommand } from "api/requests/registerBidCommand";
import { useAuctionsService } from "api/services/auctionsService";
import { useLotsService } from "api/services/lotsService";
import { useApi } from "api/useApi";
import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import { neatDateWithWeekday } from "formatters/date";
import { useToast } from "hooks/useToast";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextTransition from "react-text-transition";
import LotEndDate from "./LotEndDate";
import LotPriceBreakdown from "./LotPriceBreakdown";
import LotReservedPrice from "./LotReservedPrice";

function LotBidModal({
  show,
  lot,
  bidAmount,
  auctionTermsAndConditionsUrl,
  userAcceptedTermsAndConditions,
  onAcceptedTermsAndConditions = () => { },
  onCloseModal = () => { }
}: Readonly<{
  show: boolean,
  lot: LotSummary | Lot,
  bidAmount?: number,
  auctionTermsAndConditionsUrl: string,
  userAcceptedTermsAndConditions: boolean,
  onAcceptedTermsAndConditions: () => void,
  onCloseModal: () => void
}>) {
  const { registerBid } = useLotsService();
  const { signAuctionTermsAndConditions } = useAuctionsService();
  const { isError } = useApi();
  const { successToast, errorToast } = useToast();
  const [finalBid, setFinalBid] = useState<number>(bidAmount);
  const [bidAmountError, setBidAmountError] = useState<string>("");
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState<boolean>(false);
  const [acceptTermsAndConditionsError, setAcceptTermsAndConditionsError] = useState<string>("");
  const [isLoadingBidRegistration, setIsLoadingBidRegistration] = useState<boolean>(false);

  useEffect(() => {
    setIsCurrentBidSurpassed(isBidSurpassed(lot));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lot.priceDetails.currentPrice])

  const getMinimumBidAmount = (lot: Lot | LotSummary): number => {
    if (lot.bidsCount === 0) {
      return lot.priceDetails.currentPrice;
    }
    return lot.priceDetails.currentPrice + lot.priceDetails.incrementStep;
  }

  const isBidSurpassed = (lot: Lot | LotSummary): boolean => {
    return getMinimumBidAmount(lot) > finalBid;
  };

  const [isCurrentBidSurpassed, setIsCurrentBidSurpassed] = useState<boolean>(isBidSurpassed(lot));
  const [customBidAmount, setCustomBidAmount] = useState<number>(getMinimumBidAmount(lot));

  const validateBidAmount = (lot: Lot | LotSummary, bidAmount: number): boolean => {
    const minBidAmount = getMinimumBidAmount(lot);
    setBidAmountError("");
    if (bidAmount < minBidAmount) {
      setBidAmountError(`Oferta minimă care poate fi licitată este ${minBidAmount} ${lot.auction.currency}`);
      return false;
    }

    return true;
  }

  const validateAcceptTermsAndConditions = (acceptedTerms: boolean): boolean => {
    if (acceptedTerms) {
      setAcceptTermsAndConditionsError("");
      return true;
    }
    setAcceptTermsAndConditionsError("Trebuie să accepți termenii și condițiile pentru a putea licita");
    return false;
  }

  const onChangeAcceptTermsAndConditions = (checked: boolean): void => {
    validateAcceptTermsAndConditions(checked);
    setAcceptedTermsAndConditions(checked);
  }

  const onChangeBidAmount = (lot: Lot | LotSummary, bidAmount: number, e: React.ChangeEvent<HTMLInputElement>): void => {
    const nextMinimumBid = getMinimumBidAmount(lot);

    // the event emitted by the input element is of type InputEvent when the user types the numbers by hand
    // we can check that the user changed the value without typing (thus by clicking the arrows), by checking that the nativeEvent is not an InputEvent
    const isManuallyTypedNumber = e.nativeEvent instanceof InputEvent;
    const isArrowChangedNumber = !isManuallyTypedNumber;
    if (isArrowChangedNumber) {
      const previousBidAmount = customBidAmount;
      const isUpArrowClicked = previousBidAmount < bidAmount;
      if (isUpArrowClicked && bidAmount < nextMinimumBid) {
        bidAmount = nextMinimumBid;
      }
    }

    setCustomBidAmount(bidAmount);
    validateBidAmount(lot, bidAmount);
  };

  const onConfirmBid = () => {
    if (validateBidAmount(lot, customBidAmount)) {
      setFinalBid(customBidAmount);
    }
  }

  const onClickBid = async (lot: Lot | LotSummary, bidAmount: number) => {
    if (!userAcceptedTermsAndConditions) {
      if (!validateAcceptTermsAndConditions(acceptedTermsAndConditions) || bidAmountError !== "") {
        return;
      }
      const signResponse = await signAuctionTermsAndConditions(lot.auction.id);
      if (isError(signResponse)) {
        errorToast("A apărut o eroare în procesul de licitare. Te rugăm să încerci mai târziu.")
        return;
      }
      onAcceptedTermsAndConditions();
    }

    const currentDate = new Date();
    if (moment(currentDate).isAfter(moment(lot.endsAtUtc))) {
      errorToast("Licitația s-a încheiat.");
      onCloseModal();
      return;
    }

    setIsLoadingBidRegistration(true);
    await placeBid(lot.id, bidAmount);
    setIsLoadingBidRegistration(false);
    onCloseModal();
  }

  const placeBid = async (lotId: number, bidAmount: number) => {
    const command: RegisterBidCommand = {
      amount: bidAmount
    };
    const bidResponse = await registerBid(lotId, command);
    if (isError(bidResponse)) {
      errorToast("A apărut o eroare în procesul de licitare. Te rugăm să încerci mai târziu.");
      return;
    }
    successToast("Oferta ta a fost înregistrată!");
  }

  return (
    <Modal dismissible show={show} onClose={onCloseModal}>
      <Modal.Header>Licitează</Modal.Header>
      <Modal.Body>
        <div className="space-y-4 overflow-hidden">
          <div className="flex flex-row gap-4 h-24 lg:h-28 xl:h-32">
            <img src={lot.images[0]?.url}
              alt={lot.images[0]?.description}
              className="shrink-0 object-scale-down h-full w-28 sm:w-36 lg:w-60 rounded-lg" />
            <div className="flex flex-col justify-center gap-2">
              <p className="font-semibold text-lg lg:text-xl leading-relaxed line-clamp-2 lg:line-clamp-3 text-wrap">{lot.name}</p>
              <LotEndDate endDate={lot.endsAtUtc} className="font-semibold text-red-500" />
            </div>
          </div>
          <hr className="h-px my-8 bg-brand-200 border-0"></hr>
          <div className="space-y-2">
            <div className="flex flex-row justify-between">
              <p>Data închiderii</p>
              <p className="text-end">{neatDateWithWeekday(lot.endsAtUtc)}</p>
            </div>
            {lot.bidsCount > 0 &&
              <div className="flex flex-row justify-between">
                <p>Licitări</p>
                <p className="text-end">{lot.bidsCount}</p>
              </div>}
            <div className="flex flex-row justify-between">
              <p>{lot.bidsCount > 0 ? 'Ultima ofertă' : 'Preț pornire'}</p>
              <div>
                <TextTransition inline className="text-end">{lot.priceDetails.currentPrice}</TextTransition> {lot.auction.currency}
              </div>
            </div>
            {(lot.latestBid && lot.latestBid.isCurrentUserBid) && <p className="text-cyan-500">Ultima ofertă este trimisă de tine!</p>}
          </div>
          <hr className="h-px my-8 bg-brand-200 border-0"></hr>
          <div className="space-y-2">
            <div className="flex flex-row justify-between font-medium">
              <p>Status</p>
              <LotReservedPrice showLabel isReserved={lot.isReserved} iconClass="text-lg" />
            </div>
            {finalBid !== undefined
              ? <>
                <div className="flex flex-row justify-between font-medium">
                  <p>Oferta ta</p>
                  <p className="text-end">{finalBid} {lot.auction.currency}</p>
                </div>
              </>
              : <div className="flex flex-row w-full items-start justify-stretch gap-2 lg:gap-4 py-2">
                <div className="w-full">
                  <TextInput
                    id="bidAmount"
                    type="number"
                    sizing="lg"
                    placeholder={getMinimumBidAmount(lot).toString()}
                    addon={lot.auction.currency}
                    color={bidAmountError ? "failure" : undefined}
                    onChange={(e) => onChangeBidAmount(lot, +e.target.value, e)}
                    value={Number(customBidAmount).toString()}
                    helperText={bidAmountError}
                  />
                </div>
                <Button className="grow h-[58px] w-[30%]" size="lg" color="brand" onClick={onConfirmBid} disabled={bidAmountError !== ""}>Confirmă</Button>
              </div>}
            {isCurrentBidSurpassed && <p className="text-red-500">Oferta curentă a fost depășită. Anuleaz-o și încearcă din nou!</p>}
            <LotPriceBreakdown lotPriceDetails={lot.priceDetails} currency={lot.auction.currency} bidAmount={customBidAmount} />
            {!userAcceptedTermsAndConditions &&
              <div >
                <hr className="h-px my-4 bg-brand-200 border-0"></hr>
                <div className="flex items-center gap-2">
                  <Checkbox id="acceptGeneralConditions"
                    color={acceptTermsAndConditionsError !== "" ? "red" : "brand"}
                    onChange={(e) => onChangeAcceptTermsAndConditions(e.target.checked)} />
                  <Label htmlFor="acceptGeneralConditions" className="text-base">
                    Sunt de acord cu&nbsp;
                    <Link
                      className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                      to={auctionTermsAndConditionsUrl}
                      target="_blank">
                      termenii și condițiile licitației
                    </Link>
                  </Label>
                </div>
                {acceptTermsAndConditionsError && <p className="mt-2 text-sm text-red-600">{acceptTermsAndConditionsError}</p>}
              </div>}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-end items-end space-x-6">
        <Button color="gray" outline size="lg" onClick={() => { onCloseModal() }}>Anulează</Button>
        <Button color="brand" size="lg" disabled={finalBid === undefined || isCurrentBidSurpassed || isLoadingBidRegistration} onClick={() => onClickBid(lot, finalBid)}>Licitează</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LotBidModal;
