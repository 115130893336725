import { BsFacebook, BsInstagram, BsTiktok } from "react-icons/bs";
import { Link } from "react-router-dom";

const ContactPage = () => {
  return <>
    <div className="flex flex-col mx-auto w-full lg:w-1/2">
      <h1 className="text-4xl font-bold mb-4">Contact</h1>
      <p className="text-lg mb-8">Datele noastre de contact sunt:</p>
      <p>LICITAS AUCTIONS S.R.L.</p>
      <p>Reg. com.: J2024047118003</p>
      <p>CUI: 50995017</p>
      <p>Capital social: 200 RON</p>
      <p>Adresa: str. Traian Vuia, nr. 15, Suceava, jud. Suceava, CP 720021</p>
      <span>
        Email:
        <a className="ml-2 text-brand-600 hover:underline" href="mailto:contact@licitas.ro">contact@licitas.ro</a>
      </span>
      <span>
        Telefon:
        <a className="ml-2 text-brand-600 hover:underline" href="tel:+40746011105">+40 746 011 105</a>
      </span>
      <span className="flex">
        Rețele sociale:
        <span className="flex pt-1">
          <Link className="ml-2 text-brand-600" to="https://www.facebook.com/licitasauctions" target="_blank">
            <BsFacebook />
          </Link>
          <Link className="ml-2 text-brand-600" to="https://www.instagram.com/licitas.ro" target="_blank">
            <BsInstagram />
          </Link>
          <Link className="ml-2 text-brand-600" to="https://www.tiktok.com/@licitas5" target="_blank">
            <BsTiktok />
          </Link>
        </span>
      </span>
      <p className="text-lg mt-8">Contactează-ne în legătură cu compania sau serviciile noastre. Vom face tot posibilul să răspundem cât mai repede.</p>
    </div>
  </>;
};

export default ContactPage;
