import { useRef, useState } from "react";

export default function PinCodeInput({
  digits,
  onComplete,
  onReset,
}: Readonly<{
  digits: number,
  onComplete: (pinCode: string) => void;
  onReset: () => void;
}>) {
  const [pin, setPin] = useState(new Array(digits).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (index: number, value: string) => {
    if (!/^[0-9]?$/.test(value)) return;
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (value && index < digits-1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newPin.every((digit) => digit !== "")) {
      onComplete?.(newPin.join(""));
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").trim().replace(/\D/g, "");
    const validData = pastedData.slice(0, digits).split("");
    const newPin = new Array(digits).fill("");
    validData.forEach((digit: string, i: number) => {
      newPin[i] = digit;
    });
    setPin(newPin);
    newPin.forEach((digit, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].value = digit;
      }
    });
    onComplete?.(newPin.join(""));
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const newPin = [...pin];
      newPin[index] = "";
      setPin(newPin);
      onReset();
      inputRefs.current[index - 1]?.focus();
    }
    else if (pin[index]){
      inputRefs.current[index + 1]?.focus();
    }
  };

  return (
    <div className="flex space-x-2" onPaste={handlePaste}>
      {new Array(digits).fill("").map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          value={pin[index]}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={(el) => (inputRefs.current[index] = el)}          
          className="w-12 h-12 font-extrabold text-center text-lg border rounded-lg focus:ring-brand-500 focus:border-brand-500"
        />
      ))}
    </div>
  );
}