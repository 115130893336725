import { Banner } from "flowbite-react";
import { HiX } from "react-icons/hi";

function SelectedFiltersBanner(props: {
  displayedItems: string,
  onResetClick: () => void,
  order: number
}) {
  const { displayedItems, order, onResetClick } = props;

  return (
    <Banner className={`order-${order} flex h-fit items-center justify-between gap-1 font-medium bg-gray-300 text-gray-800 text-sm rounded-md p-3`}>
      {displayedItems}
      <button onClick={() => onResetClick()}
        className="text-gray-500 bg-transparent hover:bg-transparent hover:text-gray-900 text-lg ">
        <HiX className="h-4 w-4" /></button>
    </Banner>
  );
}

export default SelectedFiltersBanner;