import { useAuth0 } from '@auth0/auth0-react';

const useAuth = () => {
  const { isAuthenticated, isLoading, user, loginWithRedirect, getAccessTokenSilently, logout: authLogout } = useAuth0();

  const login = (redirectPath?: string, defaultLanguage?: string) => {
    loginWithRedirect({
      appState: {
        returnTo: redirectPath ?? window.location.pathname,
      },
      authorizationParams: {
        ui_locales: defaultLanguage ?? 'ro'
      }
    });
  }

  const logout = (returnTo?: string) => {
    authLogout({
      logoutParams: { returnTo: returnTo ?? window.location.origin }
    });
  }

  return { isAuthenticated, isLoading, user, getAccessTokenSilently, login, logout };
};

export { useAuth };
