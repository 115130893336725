import { Category } from "api/models/category";
import { Page } from "api/models/page";
import { GetCategoriesQuery } from "api/requests/getCategoriesQuery";
import { useApi } from "api/useApi";

const useCategoriesService = () => {
  const { get } = useApi();

  const getCategories = async (query: GetCategoriesQuery) => {
    return await get<Page<Category>>("categories", { params: query });
  };

  return { getCategories };
};

export { useCategoriesService };
