import PinCodeInput from "components/input/PinCodeInput";
import { Button } from "flowbite-react";
import { useState } from "react";
import 'react-international-phone/style.css';

export default function PrivateIndividualPhoneNumberVerificationForm({
  onSubmit,
}: Readonly<{
  onSubmit: (verificationCode: string) => void;
}>) {
  const [verificationCode, setVerificationCode] = useState<string>(null);

  const handleCodeComplete = (code: string) => {
    setVerificationCode(code);
  };

  const handleCodeReset = () => {
    setVerificationCode("");
  };

  const submitForm = () => {
    onSubmit(verificationCode);
  };

  return (
    <div className="w-full md:w-[50%]">
      <p className="text-sm md:text-base pt-1 md:pt-2 text-gray-600 flex justify-center items-center md:justify-start md:items-start">
        Introdu codul primit prin SMS pentru a trece mai departe.
      </p>
      <div className="mt-4 flex justify-center items-center md:justify-start md:items-start">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
          <PinCodeInput digits={6} onComplete={handleCodeComplete} onReset={handleCodeReset} />
        </div>

      </div>

      <div className="flex justify-center items-center md:justify-start md:items-start">
        <Button
          className="mt-8"
          disabled={!verificationCode || verificationCode.length < 6}
          color="brand"
          size="xl"
          onClick={() => submitForm()}>
          Trimite
        </Button>
      </div>
    </div>
  );
}
