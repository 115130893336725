import Drawer from "components/drawer";
import { Button, Sidebar } from "flowbite-react";
import { useEffect, useState } from "react";
import { HiChevronDoubleRight } from "react-icons/hi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { MdOutlineAccountCircle, MdOutlineManageAccounts } from "react-icons/md";
import { RiAuctionFill } from "react-icons/ri";
import { Outlet } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { ViewBreakpoints } from "static-data/constants";

export default function UserAccountLayout() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showUserAccountDrawer, setShowUserAccountDrawer] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = (() => window.innerWidth < ViewBreakpoints.lg ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const isActiveRoute = (routeName: string) => {
    return location.pathname === routeName;
  };

  const isActiveRouteContains = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const userAccountSidebar =
    <Sidebar aria-label="Cont utilizator" className="w-full lg:w-96 border-2 rounded-lg">
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          <Sidebar.Item icon={MdOutlineAccountCircle} as={Link} to="/my-account" active={isActiveRoute("/my-account")} onClick={() => setShowUserAccountDrawer(false)}>
            Contul meu
          </Sidebar.Item>
          <Sidebar.Item icon={MdOutlineManageAccounts} as={Link} to="/my-account/profile" active={isActiveRoute("/my-account/profile")} onClick={() => setShowUserAccountDrawer(false)}>
            Profil
          </Sidebar.Item>
          <Sidebar.Item icon={RiAuctionFill} as={Link} to="/my-account/bids" active={isActiveRouteContains("/my-account/bids")} onClick={() => setShowUserAccountDrawer(false)}>
            Licitări
          </Sidebar.Item>
          <Sidebar.Item icon={LiaFileInvoiceDollarSolid} as={Link} to="/my-account/invoices" active={isActiveRouteContains("/my-account/invoices")} onClick={() => setShowUserAccountDrawer(false)}>
            Facturi
          </Sidebar.Item>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>;

  return (
    <div className="w-full flex flex-col lg:flex-row gap-4">

      <Drawer isOpen={showUserAccountDrawer && isSmallScreen} onClose={() => setShowUserAccountDrawer(false)}>
        {userAccountSidebar}
      </Drawer>

      {!isSmallScreen && userAccountSidebar}

      {isSmallScreen &&
        <Button size="sm" outline color="light"
          onClick={() => setShowUserAccountDrawer(true)}
          className="text-white rounded-lg w-full">
          <HiChevronDoubleRight className="mr-2 h-5 w-5" />Meniu cont
        </Button>
      }

      <div className="h-full w-full bg-lightPrimary">
        <Outlet />
      </div>
    </div>
  );
}