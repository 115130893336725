// Taken from: https://gist.github.com/brokenthorn/dc4cadd2ecfcbb57bf6bdb7721a83239
// Might need a revisit in the future

function validateRomanianCIF(v: string): boolean {
    let cif = v.toUpperCase();
    // remove RO from cif:
    cif = (cif.indexOf('RO') > -1) ? cif.substring(2) : cif;
    // remove spaces:
    cif = cif.replace(/\s/g, '');
    // validate character length:
    if (cif.length < 2 || cif.length > 10) {
        return false;
    }
    // validate that so far the resulting CIF looks like an integer value:
    if (Number.isNaN(parseInt(cif))) {
        return false;
    }
    // begin testing:
    const testKey = '753217532'
    const controlNumber = parseInt(cif.substring(cif.length - 1));
    // remove control number:
    cif = cif.substring(0, cif.length - 1);
    // pad left with zeros to reach testKey length:
    while (cif.length !== testKey.length) {
        cif = '0' + cif;
    }
    let sum = 0;
    let i = cif.length;

    while (i--) {
        sum = sum + (parseInt(cif.charAt(i)) * parseInt(testKey.charAt(i)));
    }

    let calculatedControlNumber = sum * 10 % 11;

    if (calculatedControlNumber === 10) {
        calculatedControlNumber = 0;
    }

    return controlNumber === calculatedControlNumber;
}

export default validateRomanianCIF;
