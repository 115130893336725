import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserStatus } from 'api/models/userStatus';
import { User } from 'api/models/user';

export const currentUserSlice = createSlice({
  name: 'current-user',
  initialState: null as User,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      return action.payload;
    },
    setOnBoardingStatus: (state, action: PayloadAction<UserStatus>) => {
      state.status = action.payload;
    },
  }
});

export const { setUser, setOnBoardingStatus } = currentUserSlice.actions;
export const currentUserReducer = currentUserSlice.reducer;
