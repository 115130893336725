import anpcSalLogo from "assets/img/anpc-sal.png";
import anpcSolLogo from "assets/img/anpc-sol.png";
import licitasLogo from "assets/img/logo.png";
import { Footer } from "flowbite-react";
import { BsFacebook, BsInstagram, BsTiktok } from "react-icons/bs";

const FooterContent = () => {
  return (
    <Footer container className="!justify-center">
      <div className="w-full max-w-full xl:max-w-screen-2xl">
        <div className="grid w-full justify-between md:flex md:justify-between items-center gap-2">
          <div className="px-4">
            <Footer.Brand
              href="https://licitas.ro"
              src={licitasLogo}
              alt="Licitas Logo"
            />
          </div>
          <div className="grid grid-cols-1 mb-4 md:mb-0">
            <Footer.LinkGroup col className="mb-2 sm:mr-2">
              <Footer.Link href="https://anpc.ro/ce-este-sal/" target="_blank">
                <img src={anpcSalLogo} alt="Soluționarea alternativă a litigiilor" width={250} />
              </Footer.Link>
            </Footer.LinkGroup>
            <Footer.LinkGroup >
              <Footer.Link href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO" target="_blank">
                <img src={anpcSolLogo} alt="Soluționarea online a litigiilor" width={250} />
              </Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div className="flex ml-auto space-x-6">

            <div className="grid grid-cols-2 gap-2">
              <div className="grid grid-cols-1 sm:space-x-5 sm:flex">
                <div>
                  <Footer.Title title="Urmărește-ne" />
                  <Footer.LinkGroup col>
                    <Footer.Link href="https://www.facebook.com/licitasauctions" target="_blank">Facebook</Footer.Link>
                    <Footer.Link href="https://www.instagram.com/licitas.ro" target="_blank">Instagram</Footer.Link>
                    <Footer.Link href="https://www.tiktok.com/@licitas5" target="_blank">TikTok</Footer.Link>
                  </Footer.LinkGroup>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:space-x-5 sm:flex">
                <div>
                  <Footer.Title title="Utile" />
                  <Footer.LinkGroup col>
                    <Footer.Link href='/privacy-policy'>Politica de confidențialitate</Footer.Link>
                    <Footer.Link href='/general-conditions'>Condiții generale</Footer.Link>
                    {/* TODO Use as={Link} feature for Footer.Links after Flowbite fix for this issue is merged - check here https://github.com/themesberg/flowbite-react/pull/1244 */}
                    <Footer.Link href="/contact">Contact</Footer.Link>
                  </Footer.LinkGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full flex items-center justify-between">
          <Footer.Copyright href="/" by="Licitas" year={2025} />
          <div className="mb:mt-4 flex space-x-6">
            <Footer.Icon href="https://www.facebook.com/licitasauctions" target="_blank" icon={BsFacebook} />
            <Footer.Icon href="https://www.instagram.com/licitas.ro" target="_blank" icon={BsInstagram} />
            <Footer.Icon href="https://www.tiktok.com/@licitas5" target="_blank" icon={BsTiktok} />
          </div>
        </div>
      </div>
    </Footer>
  );
};

export default FooterContent;
