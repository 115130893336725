import { Lot } from "api/models/lot";
import { LotSummary } from "api/models/lotSummary";
import { Page } from "api/models/page";
import { GetLotsQuery } from "api/requests/getLotsQuery";
import { RegisterBidCommand } from "api/requests/registerBidCommand";
import { useApi } from "api/useApi";

const useLotsService = () => {
  const { get, post } = useApi();

  const getLots = async (query: GetLotsQuery) => {
    return await get<Page<LotSummary>>("lots", {
      params: query
    });
  };

  const getLotById = async (id: number) => {
    return await get<Lot | null>(`lots/${id}`);
  };

  const registerBid = async (lotId: number, command: RegisterBidCommand) => {
    return await post<number>(`lots/${lotId}/bids`, command);
  }

  return { getLots, getLotById, registerBid };
};

export { useLotsService };
