import { GenericError } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { authorized } from './axios';
import { useAuth } from './useAuth';

const useApi = () => {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth();

  const getAccessTokenSilentlyWrapper = async (): Promise<string | null> => {
    if (!isAuthenticated) {
      return null;
    }

    return await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH_AUDIENCE,
        scope: process.env.REACT_APP_AUTH_SCOPE,
      },
    });
  }

  const authorizedAxiosInstance = async () => {
    const accessToken = await getAccessTokenSilentlyWrapper();
    return authorized(accessToken);
  }

  const get = async <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> => {
    try {
      return (await authorizedAxiosInstance()).get(url, config);
    }
    catch (e) {
      handleRefreshTokenException(e);
    }
  }

  const _delete = async <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> => {
    try {
      return (await authorizedAxiosInstance()).delete(url, config);
    }
    catch (e) {
      handleRefreshTokenException(e);
    }
  }

  const post = async <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> => {
    try {
      return (await authorizedAxiosInstance()).post(url, data, config);
    }
    catch (e) {
      handleRefreshTokenException(e);
    }
  }

  const put = async <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> => {
    try {
      return (await authorizedAxiosInstance()).put(url, data, config);
    }
    catch (e) {
      handleRefreshTokenException(e);
    }
  }

  const patch = async <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> => {
    try {
      return (await authorizedAxiosInstance()).patch(url, data, config);
    }
    catch (e) {
      handleRefreshTokenException(e);
    }
  }

  const isError = <T = any>(response: AxiosResponse<T>): boolean => {
    return axios.isAxiosError(response);
  }

  const isRefreshTokenExpired = (exception: unknown): boolean => {
    return exception instanceof GenericError && (exception.error === 'login_required' || exception.error === 'invalid_grant');
  }

  const handleRefreshTokenException = (exception: unknown) => {
    if (isRefreshTokenExpired(exception)) {
      logout();
    }
  }

  return { get, _delete, put, post, patch, isError };
};

export { useApi };
