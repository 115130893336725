import { Category } from "api/models/category";
import Agriculture from "assets/img/categories/agriculture.jpg";
import Diverse from "assets/img/categories/diverse.jpg";
import Horeca from "assets/img/categories/horeca.jpg";
import RealEstate from "assets/img/categories/imobiliare.jpg";
import Industrial from "assets/img/categories/industrial.jpg";
import Logistics from "assets/img/categories/logistica.jpg";
import Metal from "assets/img/categories/metal.jpg";
import Plastics from "assets/img/categories/plastic.jpg";
import Stocks from "assets/img/categories/stocuri.jpg";
import ImageFallback from "assets/img/image_fallback.svg";
import { Button } from "flowbite-react";
import { useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LotsQueryParams } from "static-data/constants";
import { useAppSelector } from "store";
import { useSlug } from "utils/slugHelper";

const Homepage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const categories = useAppSelector((state) => state.staticData.categories);
  const { slugify } = useSlug();

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.currentTarget.value) {
      const searchTerm = event.currentTarget.value;
      setSearchParams({ [LotsQueryParams.Search]: searchTerm });
      navigate(`/lots?${LotsQueryParams.Search}=${searchTerm}`);
      event.currentTarget.value = "";
    }
  }

  const onSubmitSearch = () => {
    setSearchParams({ [LotsQueryParams.Search]: searchText });
    navigate(`/lots?${LotsQueryParams.Search}=${searchText}`);
    setSearchText("");
  }

  const getCategoryImage = (category: Category): string => {
    switch (category.name) {
      case "Agricultură":
        return Agriculture;
      case "Prelucrări metal":
        return Metal;
      case "Diverse":
        return Diverse;
      case "Categorii industriale":
        return Industrial;
      case "Horeca":
        return Horeca;
      case "Imobiliare":
        return RealEstate;
      case "Logistică și transport":
        return Logistics;
      case "Prelucrări plastic":
        return Plastics;
      case "Stocuri magazine":
        return Stocks;
      default:
        return ImageFallback;
    }
  }

  return (
    <div className="mt-8 mx-auto">
      <div className="w-full md:w-[75%] lg:w-[50%] mx-auto">
        <h1 className="mb-4 text-3xl text-center font-extrabold !leading-tight tracking-tight text-gray-900 md:text-4xl lg:text-5xl">Sursa ta de inovație în licitații industriale</h1>
        <h3 className="text-xl md:text-2xl text-center">Cumpără cu încredere. Vinde cu succes.</h3>
        <h3 className="text-xl md:text-2xl text-center">Descoperă instrumentele și utilajele de care afacerea ta are nevoie!</h3>
      </div>
      <div className="mt-10 w-full md:w-[75%] lg:w-[50%] mx-auto relative">
        <input
          type="text"
          id="homepage-search"
          placeholder="Caută..."
          autoComplete="off"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          onKeyDown={handleKeyDown}
          className="block p-2.5 z-20 text-sm text-gray-900 bg-gray-50 rounded-lg w-full min-h-10 md:min-h-14 border border-gray-300 focus:ring-brand-500 focus:border-brand-500" />
        <Button
          type="submit"
          color="brand"
          size="sm"
          onClick={onSubmitSearch}
          className="absolute top-0 end-0 h-full text-white rounded-l hover:bg-brand-600 focus:ring-4 focus:outline-none focus:ring-brand-300">
          <HiOutlineSearch className="text-xl" />
        </Button>
      </div>

      <div className="grid grid-cols-1 justify-center">
        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-3 mx-auto">
          {categories && categories.map((category) => (
            <Link key={category.id}
              className="relative block bg-gray-900 group rounded-xl w-80 h-60 transform transition duration-500 hover:scale-105 "
              to={`/lots/${slugify(category.name, category.id)}`}>
              <img
                alt={category.name}
                className="absolute rounded-xl inset-0 h-full w-full object-cover"
                src={getCategoryImage(category)} />
              <div className="absolute inset-0 bg-gray-700 opacity-60 rounded-xl"></div>
              <div className="absolute inset-0 flex items-center justify-center">
                <h2 className="text-white text-2xl ">{category.name}</h2>
              </div>
            </Link>
          ))}
        </div>

      </div>
    </div>
  );
};

export default Homepage;
