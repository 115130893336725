import { useEffect, useState } from "react";
import 'react-international-phone/style.css';
import { OnboardForm } from "../OnboardingForm";
import PrivateIndividualDataCollectionForm, { PrivateIndividualData } from './PrivateIndividualDataCollectionForm';
import PrivateIndividualPhoneNumberVerificationForm from './PrivateIndividualPhoneNumberVerificationForm';

type PrivateIndividualOnboardingState = 'DataCollection' | 'PhoneNumberVerification';

export default function PrivateIndividualOnboardingForm({
  onSubmit,
}: Readonly<{
  onSubmit: (form: OnboardForm) => void;
}>) {
  const [onboardingState, setOnBoardingState] = useState<PrivateIndividualOnboardingState>('DataCollection');

  useEffect(() => {

    // check user onboarding state

    // if data is collected but phone is not verified then try to verify again
    //  setOnBoardingState('PhoneNumberVerification');

  }, []);

  const onVerifyPhoneNumber = (verificationCode: string) => {
    // send verification code, check validity, check remaining attempts
  }

  const onDataCollected = (fields: PrivateIndividualData) => {
    const privateIndividualOnboardingForm: OnboardForm = {
      type: 'PrivateIndividual',
      firstName: fields.firstName,
      lastName: fields.lastName,
      phoneNumber: fields.phoneNumber,
      personalCountryCode: 'RO',
      personalIdentificationNumber: fields.identificationNumber,
      personalAcceptConsentForm: fields.acceptConsentForm,
      acceptPrivacyPolicy: fields.acceptPrivacyPolicy,
      acceptGeneralConditions: fields.acceptGeneralConditions,
    };
    onSubmit(privateIndividualOnboardingForm);
    setOnBoardingState('PhoneNumberVerification');
  }

  return (
    <div>
      {onboardingState === 'DataCollection' &&
        <PrivateIndividualDataCollectionForm onSubmit={onDataCollected} />}
      {onboardingState === 'PhoneNumberVerification' &&
        <PrivateIndividualPhoneNumberVerificationForm onSubmit={onVerifyPhoneNumber} />}
    </div>
  );
}
