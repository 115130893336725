import { Button, Checkbox, HelperText, Label, TextInput } from "flowbite-react";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useState } from "react";
import { PhoneInput } from 'react-international-phone';
import { Link } from "react-router-dom";
import { CNP } from "romanian-personal-identity-code-validator";

export type PrivateIndividualData = {
  firstName: string,
  lastName: string,
  phoneNumber: string,
  identificationNumber: string,
  acceptConsentForm: boolean,
  acceptPrivacyPolicy: boolean,
  acceptGeneralConditions: boolean,
}
type PrivateIndividualDataErrors = Partial<Record<keyof PrivateIndividualData, string>>;

export default function PrivateIndividualDataCollectionForm({
  onSubmit,
}: Readonly<{
  onSubmit: (form: PrivateIndividualData) => void;
}>) {
  const [fields, setFields] = useState<PrivateIndividualData>({
    firstName: "",
    lastName: "",
    identificationNumber: "",
    phoneNumber: "",
    acceptConsentForm: false,
    acceptPrivacyPolicy: false,
    acceptGeneralConditions: false,
  });
  const [errors, setErrors] = useState<PrivateIndividualDataErrors>({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const phoneUtil = PhoneNumberUtil.getInstance();
  let identificationNumber = new CNP('');

  const handleValidation = (): boolean => {
    const formErrors: PrivateIndividualDataErrors = {
      firstName: validateFirstName(fields.firstName),
      lastName: validateLastName(fields.lastName),
      phoneNumber: validatePhoneNumber(fields.phoneNumber),
      identificationNumber: validateIdentificationNumber(fields.identificationNumber),
      acceptConsentForm: validateAcceptConsentForm(fields.acceptConsentForm),
      acceptPrivacyPolicy: validateAcceptPrivacyPolicy(fields.acceptPrivacyPolicy),
      acceptGeneralConditions: validateAcceptGeneralConditions(fields.acceptGeneralConditions)
    };

    setErrors(formErrors);
    return Object.values(formErrors).every(err => !err);
  };

  const validateField = (fieldName: keyof PrivateIndividualData, value: any) => {
    switch (fieldName) {
      case "firstName":
        return validateFirstName(value);
      case "lastName":
        return validateLastName(value);
      case "phoneNumber":
        return validatePhoneNumber(value);
      case "identificationNumber":
        return validateIdentificationNumber(value);
      case "acceptConsentForm":
        return validateAcceptConsentForm(value);
      case "acceptPrivacyPolicy":
        return validateAcceptPrivacyPolicy(value);
      case "acceptGeneralConditions":
        return validateAcceptGeneralConditions(value);
    }
  };

  const validateFirstName = (firstName: string): string => {
    if (!firstName) {
      return "Prenumele trebuie completat";
    }
  };

  const validateLastName = (lastName: string): string => {
    if (!lastName) {
      return "Numele trebuie completat";
    }
  };

  const validatePhoneNumber = (phoneNumber: string): string => {
    const invalidPhoneError = "Numărul de telefon este invalid";
    if (!phoneNumber) {
      return "Numărul de telefon trebuie completat";
    }
    try {
      return !phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber)) ? invalidPhoneError : "";
    } catch (error) {
      return invalidPhoneError;
    }
  };

  const validateIdentificationNumber = (idNumber: string): string => {
    if (!idNumber) {
      return "CNP-ul trebuie completat";
    }
    identificationNumber.cnp = idNumber;
    if (!identificationNumber.isValid()) {
      return "CNP-ul este invalid";
    }
  };

  const validateAcceptConsentForm = (acceptConsentForm: boolean): string => {
    if (!acceptConsentForm) {
      return "Trebuie să accepți formularul de consimțământ";
    }
  };

  const validateAcceptGeneralConditions = (acceptGeneralConditions: boolean): string => {
    if (!acceptGeneralConditions) {
      return "Trebuie să accepți condițiile generale";
    }
  };

  const validateAcceptPrivacyPolicy = (acceptPrivacyPolicy: boolean): string => {
    if (!acceptPrivacyPolicy) {
      return "Trebuie să accepți politica de confidențialitate";
    }
  };

  const handleChange = (field: keyof PrivateIndividualData, value: string | boolean) => {
    setFields({ ...fields, [field]: value });
    if (isFormSubmitted) {
      setErrors({ ...errors, [field]: validateField(field, value) });
    }
  };

  const submitForm = () => {
    setIsFormSubmitted(true);

    if (!handleValidation()) {
      return;
    }

    onSubmit(fields);
  };


  return (
    <div className="w-full md:w-[75%]">
      <div className="mt-3 md:mt-6 space-y-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-5">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="lastName" value="Nume *" />
            </div>
            <TextInput
              id="lastName"
              type="text"
              placeholder="Popescu"
              color={errors.lastName ? "failure" : undefined}
              onChange={(e) => handleChange("lastName", e.target.value)}
              value={fields.lastName}
              helperText={errors.lastName}
            />
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="firstName" value="Prenume *" />
            </div>
            <TextInput
              id="firstName"
              type="text"
              placeholder="Daniel"
              color={errors.firstName ? "failure" : undefined}
              onChange={(e) => handleChange("firstName", e.target.value)}
              value={fields.firstName}
              helperText={errors.firstName}
            />
          </div>

          <div>
            <label htmlFor='phone-number' className="text-sm text-navy-900 font-medium">Telefon *</label>
            <div className={`mt-2 h-[42px] rounded-lg bg-gray-50 border ${errors.phoneNumber ? 'border-red-500' : ' border-gray-300'}`}>
              <PhoneInput
                style={{ height: '100%', backgroundColor: 'gray-50' }}
                inputProps={{ id: 'phone-number', className: `border-none bg-gray-50 text-gray-900 text-sm rounded-lg focus:ring-2 ${errors.phoneNumber ? 'placeholder-red-700 focus:border-red-500 focus:ring-red-500' : 'focus:border-brand-500 focus:ring-brand-500 '}` }}
                inputStyle={{ height: '100%', width: '100%', }}
                countrySelectorStyleProps={{ buttonClassName: `h-full pl-2.5 pr-1 !rounded-l-lg border-solid border-0 border-r ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'}` }}
                forceDialCode
                defaultCountry="ro"
                value={fields.phoneNumber}
                onChange={(phone) => handleChange("phoneNumber", phone)}
              />
            </div>
            {errors.phoneNumber && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.phoneNumber}</p>}
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="identificationNumber" value="CNP *" />
            </div>
            <TextInput
              id="identificationNumber"
              type="text"
              placeholder="1000000000001"
              color={errors.identificationNumber ? "failure" : undefined}
              onChange={(e) => handleChange("identificationNumber", e.target.value)}
              value={fields.identificationNumber}
              helperText={errors.identificationNumber}
            />
          </div>
        </div>
        <HelperText className="text-base text-gray-400">La pasul următor vei primi un cod prin SMS pentru verificarea identității.</HelperText>
      </div>

      <div className="mt-2 md:mt-4 grid grid-cols-1 gap-3 lg:gap-5">
        <div className="col-start-1 mt-2">
          <div className="flex items-center gap-2">
            <Checkbox id="acceptConsentForm"
              color={errors.acceptConsentForm !== undefined ? "red" : "brand"}
              onChange={(e) =>
                handleChange("acceptConsentForm", e.target.checked)} />
            <Label htmlFor="acceptConsentForm" className="flex">
              Sunt de acord cu &nbsp;
              <Link
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                to={process.env.REACT_APP_ASSETS_LOCATION + 'PrivacyPolicy.pdf'}
                target="_blank">
                formularul de consimțământ.
              </Link>
            </Label>
          </div>
          {errors.acceptConsentForm && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.acceptConsentForm}</p>}
        </div>

        <div className="col-start-1">
          <div className="flex items-center gap-2">
            <Checkbox id="acceptPrivacyPolicy"
              color={errors.acceptPrivacyPolicy !== undefined ? "red" : "brand"}
              onChange={(e) =>
                handleChange("acceptPrivacyPolicy", e.target.checked)} />
            <Label htmlFor="acceptPrivacyPolicy" className="flex">
              Sunt de acord cu &nbsp;
              <Link
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                to={process.env.REACT_APP_ASSETS_LOCATION + 'PrivacyPolicy.pdf'}
                target="_blank">
                politica de confidențialitate
              </Link>
            </Label>
          </div>
          {errors.acceptPrivacyPolicy && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.acceptPrivacyPolicy}</p>}
        </div>

        <div className="col-start-1">
          <div className="flex items-center gap-2">
            <Checkbox id="acceptGeneralConditions"
              color={errors.acceptGeneralConditions !== undefined ? "red" : "brand"}
              onChange={(e) =>
                handleChange("acceptGeneralConditions", e.target.checked)} />
            <Label htmlFor="acceptGeneralConditions" className="flex">
              Sunt de acord cu &nbsp;
              <Link
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                to={process.env.REACT_APP_ASSETS_LOCATION + 'GeneralConditions.pdf'}
                target="_blank">
                condițiile generale ale site-ului
              </Link>
            </Label>
          </div>
          {errors.acceptGeneralConditions && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.acceptGeneralConditions}</p>}
        </div>
      </div>

      <div className="flex justify-center items-center md:justify-start md:items-start">
        <Button
          className="mt-8 mb-2"
          color="brand"
          size="xl"
          onClick={() => submitForm()}>
          Continuă
        </Button>
      </div>
    </div>
  );
}
